import React, { Component } from "react";
import U from "../utils";
import { floorsData } from "../data/floorsData";

class SchedulePopup extends Component {
  constructor() {
    super();
    this.OutroAnim = this.OutroAnim.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.IntroAnim();
    }, 100);

    document.addEventListener("popupWillClose", this.OutroAnim);
  }

  componentWillUnmount() {
    document.removeEventListener("popupWillClose", this.OutroAnim);
  }

  IntroAnim() {
    if (this.popupDiv) this.popupDiv.classList.remove("hidden");
    setTimeout(() => {
      this.popupDiv.classList.remove("fade");
      this.popupDiv.classList.remove("anim-slide-up");
    }, 10);
  }

  OutroAnim() {
    this.popupDiv.classList.add("fade");
    setTimeout(() => {
      if (!this.popupDiv) return;
      this.popupDiv.classList.add("hidden");
    }, 400);
  }

  CloseHandler() {
    this.OutroAnim();

    setTimeout(() => {
      this.props.ShowPopup(false);
    }, 200);
  }

  render() {
    const currentClass =
      this.props.currentState.name === "GROUND"
        ? "schedule-ground-img"
        : this.props.currentState.name === "FIRST"
        ? "schedule-first-img"
        : this.props.currentState.name === "SECOND"
        ? "schedule-second-img"
        : "schedule-all-img";
    return (
      <div
        ref={(ref) => (this.popupDiv = ref)}
        className="popup-overlay clickable green-overlay anim-all-200 fade anim-slide-up"
      >
        <div className="popup-overlay-container">
          <div className={`schedule-diagram ${currentClass}`}></div>
          <ul role="list" className="schedule-list w-list-unstyled">
            <li
              className="schedule-item"
              onClick={() => this.props.GoToState("SECOND")}
            >
              <div className="schedule-title">Second Floor</div>
              <ul role="list" className="schedule-sub-list w-list-unstyled">
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Let to Keyence
                  </div>
                </li>
              </ul>
            </li>
            <li
              className="schedule-item"
              onClick={() => this.props.GoToState("FIRST")}
            >
              <div className="schedule-title">First Floor</div>
              <div className="schedule-area">
                {U.FormatNumber(floorsData[1].sqft)} sq ft
              </div>
              <div className="schedule-area sqm">
                ({U.FormatNumber(floorsData[1].sqm)} sq m)
              </div>
              <ul role="list" className="schedule-sub-list w-list-unstyled">
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Part let to Teledyne
                  </div>
                </li>
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Space available from 7,900 – 17,075 sq ft
                  </div>
                </li>
              </ul>
            </li>
            <li
              className="schedule-item"
              onClick={() => this.props.GoToState("GROUND")}
            >
              <div className="schedule-title">Ground Floor</div>
              <ul role="list" className="schedule-sub-list w-list-unstyled">
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Let to Heidelberg Graphic Equipment Limited
                  </div>
                </li>
              </ul>
            </li>
            <li className="schedule-item total-item hidden">
              <div className="schedule-title">
                <strong>Total</strong>
              </div>
              <div className="schedule-area">48,589 sq ft</div>
              <div className="schedule-area sqm">(4,514 sq m)</div>
            </li>
          </ul>
          <div className="notice">
            All areas approximate. Measurements taken on an IPMS3 basis in
            accordance with the RICS International Property Measurement
            Standards (Jan 2016)
          </div>
        </div>
        <div
          className="popup-close green-close"
          onClick={() => this.CloseHandler()}
        ></div>
      </div>
    );
  }
}

export default SchedulePopup;
