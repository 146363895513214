export const floorsData = [
  {
    name: "Second",
    state: "SECOND",
    sqft: 24249,
    sqm: 2253,
    elevImg: "elevation-second.svg",
    floorPlan: {
      img: "secondFloorPlan.png",
      dl: "USP_SecondFloor_Plan.pdf",
    },
    floorSplit: {
      img: "secondFloorPlan-split.png",
      dl: "USP_SecondFloor_GA_Plan.pdf",
      sizes: [
        { name: "Office 5", sqft: 14240, sqm: 1323 },
        { name: "Office 6", sqft: 8227, sqm: 764.3 },
      ],
    },
    spacePlan: {
      img: "busIcon.png",
      specs: [
        "Test specification",
        "Test specification",
        "Test specification",
        "Test specification",
        "Test specification",
      ],
    },
  },
  {
    name: "First",
    state: "FIRST",
    sqft: 17075,
    sqm: 1586,
    elevImg: "elevation-first.svg",
    floorPlan: {
      img: "firstFloorPlan-split.png",
      dl: "USP_FirstFloor_Plan.pdf",
    },
    // floorSplit: {
    //   img: "firstFloorPlan-split.png",
    //   dl: "USP_FirstFloor_GA_Plan.pdf",
    //   sizes: [
    //     { name: "Office 3", sqft: 17075, sqm: 1586 },
    //     { name: "Office 4", sqft: 7900, sqm: 736 },
    //   ],
    // },
    spacePlan: {
      img: "second-floor-space.png",
      specs: [
        "Test specification",
        "Test specification",
        "Test specification",
        "Test specification",
        "Test specification",
      ],
    },
  },
  {
    name: "Ground",
    state: "GROUND",
    sqft: 0,
    sqm: 0,
    spaces: {
      office: { sqft: 0, sqm: 0 },
      // coworking: { sqft: 6490, sqm: 603 }, //not used anymore
      reception: { sqft: 0, sqm: 0 },
    },
    elevImg: "elevation-ground.svg",
    floorPlan: {
      img: "groundFloorPlan.png",
      dl: "USP_GroundFloor_Plan.pdf",
    },
    // floorSplit: {
    // 	img: "groundFloorPlan-split.png",
    // 	dl: "USP_GroundFloor_GA_Plan.pdf",
    // 	sizes: [
    // 		{ name: "Office 1", sqft: 0, sqm: 0 },
    // 		{ name: "Office 2", sqft: 0, sqm: 0 },
    // 	],
    // },
    spacePlan: {
      img: "second-floor-space.png",
      specs: [
        "Test specification",
        "Test specification",
        "Test specification",
        "Test specification",
        "Test specification",
      ],
    },
  },
];

floorsData.map((fl, i) => (fl.index = i));

export function GetFloor(state) {
  const result = floorsData.find(
    (fl) => fl.state.toLowerCase() === state.toLowerCase()
  );
  if (!result) {
    console.error("NO FLOOR FOUND ASSOCIATED WITH STATE " + state);
    return null;
  }
  return result;
}

export function GetFloorByName(name) {
  const result = floorsData.find(
    (f) => f.name.toLowerCase() === name.toLowerCase()
  );
  if (!result || result === -1) {
    console.error("UNABLE TO LOAD FLOOR DATA " + name);
  }
  return result;
}
