import { G } from "../globals";
import { STATE } from "./states";

export const virtualViewerData = [
	{
		name: "exterior",
		title: "Entrance",
		northAngle: 180,
		floor: "ground",
		state: "VIRTUAL_OUTSIDE",
		mapPos: { x: 123, y: 203 }, //position for icon on minimap, in px
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/exterior",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: 0,
					yaw: 0,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_RECEPTION"].options.virtualScene);
						else G.GoToState("VIRTUAL_RECEPTION");
					},
					text: "Reception",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to reception",
				},
				{
					type: "custom",
					pitch: 0,
					yaw: 30,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_TERRACE"].options.virtualScene);
						else G.GoToState("VIRTUAL_TERRACE");
					},
					text: "Terrace",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to terrace",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 0,
		},
	},
	{
		name: "reception",
		title: "Reception",
		northAngle: 270,
		floor: "ground",
		mapPos: { x: 123, y: 174 },
		state: "VIRTUAL_RECEPTION",
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/reception",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: -3,
					yaw: 115,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_BREAKOUT"].options.virtualScene);
						else G.GoToState("VIRTUAL_BREAKOUT");
					},
					text: "Coffee bar",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to coffee bar",
				},
				{
					type: "custom",
					pitch: 0,
					yaw: 180,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_TERRACE"].options.virtualScene);
						else G.GoToState("VIRTUAL_TERRACE");
					},
					text: "Terrace",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to terrace",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 0,
		},
	},
	{
		name: "terrace",
		title: "Terrace",
		northAngle: 0,
		floor: "ground",
		state: "VIRTUAL_TERRACE",
		mapPos: { x: 174, y: 159 },
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/terrace",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: 0,
					yaw: 40,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_OUTSIDE"].options.virtualScene);
						else G.GoToState("VIRTUAL_OUTSIDE");
					},
					text: "Entrance",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to entrance",
				},
				{
					type: "custom",
					pitch: 0,
					yaw: 70,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_RECEPTION"].options.virtualScene);
						else G.GoToState("VIRTUAL_RECEPTION");
					},
					text: "Reception",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to reception",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 0,
		},
	},
	{
		name: "breakout",
		title: "Breakout",
		northAngle: 270,
		floor: "ground",
		state: "VIRTUAL_BREAKOUT",
		mapPos: { x: 135, y: 148 },
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/breakout",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: 0,
					yaw: 55,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_ATRIUM"].options.virtualScene);
						else G.GoToState("VIRTUAL_ATRIUM");
					},
					text: "Atrium",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to atrium",
				},
				{
					type: "custom",
					pitch: 0,
					yaw: 260,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_RECEPTION"].options.virtualScene);
						else G.GoToState("VIRTUAL_RECEPTION");
					},
					text: "Reception",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to reception",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 30,
		},
	},
	{
		name: "atrium",
		title: "Atrium",
		northAngle: 180,
		floor: "ground",
		state: "VIRTUAL_ATRIUM",
		mapPos: { x: 114, y: 111 },
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/atrium",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: 0,
					yaw: 130,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_BREAKOUT"].options.virtualScene);
						else G.GoToState("VIRTUAL_BREAKOUT");
					},
					text: "Coffee bar",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to Coffee bar",
				},
				{
					type: "custom",
					pitch: 0,
					yaw: 0,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_GROUNDOFFICE"].options.virtualScene);
						else G.GoToState("VIRTUAL_GROUNDOFFICE");
					},
					text: "Ground office",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to ground floor office",
				},
				{
					type: "custom",
					pitch: 0,
					yaw: 270,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_SHOWERS"].options.virtualScene);
						else G.GoToState("VIRTUAL_SHOWERS");
					},
					text: "Showers",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to showers",
				},
				{
					type: "custom",
					pitch: 20,
					yaw: 30,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_FIRSTFLOOR"].options.virtualScene);
						else G.GoToState("VIRTUAL_FIRSTFLOOR");
					},
					text: "First floor",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to first floor",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 0,
		},
	},
	{
		name: "office",
		title: "Ground Floor Office",
		northAngle: 90,
		floor: "ground",
		state: "VIRTUAL_GROUNDOFFICE",
		mapPos: { x: 178, y: 70 },
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/office",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: 0,
					yaw: 175,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_ATRIUM"].options.virtualScene);
						else G.GoToState("VIRTUAL_ATRIUM");
					},
					text: "Atrium",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to atrium",
				},
				{
					type: "custom",
					pitch: 0,
					yaw: 150,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_BREAKOUT"].options.virtualScene);
						else G.GoToState("VIRTUAL_BREAKOUT");
					},
					text: "Coffee bar",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to Coffee bar",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 170,
		},
	},
	{
		name: "showers",
		title: "Showers",
		northAngle: 180,
		floor: "ground",
		state: "VIRTUAL_SHOWERS",
		mapPos: { x: 34, y: 116 },
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/showers",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: 0,
					yaw: 180,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_ATRIUM"].options.virtualScene);
						else G.GoToState("VIRTUAL_ATRIUM");
					},
					text: "Atrium",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to atrium",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 0,
		},
	},
	{
		name: "firstfloor",
		title: "First Floor",
		northAngle: 0,
		floor: "first",
		state: "VIRTUAL_FIRSTFLOOR",
		mapPos: { x: 107, y: 75 },
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/first",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: -20,
					yaw: 0,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_ATRIUM"].options.virtualScene);
						else G.GoToState("VIRTUAL_ATRIUM");
					},
					text: "Atrium",
					cssClass: "custom-hotspot-down",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to atrium",
				},
				{
					type: "custom",
					pitch: 10,
					yaw: 0,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_SECONDFLOOR"].options.virtualScene);
						else G.GoToState("VIRTUAL_SECONDFLOOR");
					},
					text: "Second floor",
					cssClass: "custom-hotspot",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to second floor",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 0,
		},
	},
	{
		name: "secondfloor",
		title: "Second Floor",
		northAngle: 330,
		floor: "second",
		state: "VIRTUAL_SECONDFLOOR",
		mapPos: { x: 120, y: 102 },
		sceneData: {
			type: "multires",
			multiRes: {
				basePath: "./images/360/second_atrium",
				path: "/%l/%s%y_%x",
				fallBackPath: "/fallback/%s",
				extension: "jpg",
				tileResolution: 512,
				maxLevel: 3,
				cubeResolution: 1272,
			},
			hotSpots: [
				{
					type: "custom",
					pitch: -45,
					yaw: -20,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_ATRIUM"].options.virtualScene);
						else G.GoToState("VIRTUAL_ATRIUM");
					},
					text: "Atrium",
					cssClass: "custom-hotspot-down",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to atrium",
				},
				{
					type: "custom",
					pitch: -45,
					yaw: 90,
					clickHandlerFunc: () => {
						if (G.currentState.options && G.currentState.options.virtualScene)
							G.VirtualLoadScene(STATE["VIRTUAL_FIRSTFLOOR"].options.virtualScene);
						else G.GoToState("VIRTUAL_FIRSTFLOOR");
					},
					text: "First floor",
					cssClass: "custom-hotspot-down",
					createTooltipFunc: G.RenderHotspot,
					createTooltipArgs: "Go to first floor",
				},
			],
			hfov: 100,
			pitch: 0,
			yaw: 0,
		},
	},
];

export function GetScene(scene) {
	const result = virtualViewerData.find((v) => v.name.toLowerCase() === scene.toLowerCase());
	if (!result || result === -1) {
		console.error("UNABLE TO LOAD VIRTUAL SCENE " + scene);
	}
	return result;
}
