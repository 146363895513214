import React, { Component } from "react";
import * as THREE from "three";
import U from "../utils";

class Loading extends Component {
	constructor() {
		super();

		this.SetProgress = this.SetProgress.bind(this);
		this.OutroAnim = this.OutroAnim.bind(this);
		this.FireEvent = this.FireEvent.bind(this);

		THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
			this.SetProgress((itemsLoaded / itemsTotal) * 100);
		};

		THREE.DefaultLoadingManager.onLoad = () => {
			setTimeout(() => {
				this.OutroAnim();
				this.FireEvent();
			}, 1000);
		};
	}

	componentDidMount() {
		this.IntroAnim();
	}

	SetProgress(percentage) {
		this.progressDiv.style.width = `${U.Clamp(percentage, 0, 100)}%`;
	}

	IntroAnim() {
		this.logoContainer.classList.remove("fade");
	}

	OutroAnim() {
		[...document.querySelectorAll(".letters-to-fade")].map((l) => l.classList.remove("fade"));

		setTimeout(() => {
			this.container.classList.add("fade");
			setTimeout(() => {
				this.container.classList.add("hidden");
			}, 400);
		}, 1200);
	}

	FireEvent() {}

	render() {
		return (
			<div ref={(ref) => (this.container = ref)} className="loader">
				<div ref={(ref) => (this.loaderBg = ref)} className="loader-bg" />
				<div className="loader-container">
					<div ref={(ref) => (this.logoContainer = ref)} className="logo-container fade">
						<div className="loading-progress-wrapper">
							<div
								ref={(ref) => (this.progressDiv = ref)}
								className="loading-progress"
							/>
						</div>
						<div className="loading-mask">
							<svg
								width="470px"
								height="101px"
								viewBox="0 0 470 101"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								xmlnsXlink="http://www.w3.org/1999/xlink"
							>
								<g
									id="UPDATE"
									stroke="none"
									strokeWidth="1"
									fill="none"
									fillRule="evenodd"
								>
									<g id="loader-svg" transform="translate(0.000000, 0.506975)">
										<path
											id="Combined-Shape"
											d="M470,0 L470,100 L0,100 L0,0 L470,0 Z M328.5,68.3 L159,68.3 L159,73.9 L328.5,73.9 L328.5,68.3 Z M435,68.3 L345,68.3 L345,73.9 L435,73.9 L435,68.3 Z M144.5,68.3 L35,68.3 L35,73.9 L144.5,73.9 L144.5,68.3 Z"
											fill="#8AD5A7"
											fillRule="nonzero"
										></path>
										<path
											id="u"
											className="words"
											d="M52.4358455,44.3706439 C52.4358455,49.0998955 50.4963467,51.0455222 46.6342012,51.0455222 L46.1669443,51.0455222 C42.2879469,51.0455222 40.3653,49.1075555 40.3653,44.3706439 L40.3653,25.6558599 L34.5636557,25.6558599 L34.5636557,45.2132381 C34.5636557,52.0888071 38.2113228,56.1807511 46.4044027,56.1807511 C54.5821628,56.1807511 58.2451497,52.102595 58.2451497,45.2132381 L58.2451497,25.6558599 L52.4435054,25.6558599 L52.4435054,44.3706439 L52.4358455,44.3706439 Z"
											fill="#003E33"
										></path>
										<path
											id="n"
											className="words union-letters letters-to-fade fade"
											d="M137.338705,33.8173809 C134.548952,33.8173809 132.134536,35.2360032 130.830813,37.3961084 L130.830813,34.2539979 L125.243647,34.2539979 L125.260499,55.7600668 L130.847665,55.7600668 L130.847665,43.8044206 C130.847665,40.7925292 132.923511,38.3536018 135.498785,38.3536018 L135.851143,38.3536018 C137.9898,38.3536018 139.247564,39.8564836 139.247564,42.6538964 L139.247564,55.7600668 L144.834729,55.7600668 L144.834729,42.0028009 C144.825537,37.2582293 142.504573,33.8173809 137.338705,33.8173809"
											fill="#003E33"
										></path>
										<path
											id="i"
											className="words union-letters letters-to-fade fade"
											d="M94.7187562,34.2543043 L94.7187562,55.7603732 L89.1315904,55.7603732 L89.1315904,34.2543043 L94.7187562,34.2543043 Z M94.9332347,25.6629071 L94.9332347,31.4492315 L88.9171119,31.4492315 L88.9171119,25.6629071 L94.9332347,25.6629071 Z"
											fill="#003E33"
										></path>
										<path
											id="o"
											className="words union-letters letters-to-fade fade"
											d="M110.169024,51.6589308 L109.816666,51.6589308 C106.804775,51.6589308 104.866808,49.2000876 104.866808,44.9917124 C104.866808,40.7756773 106.804775,38.3229621 109.816666,38.3229621 L110.169024,38.3229621 C113.180915,38.3229621 115.120414,40.7833372 115.120414,44.9917124 C115.120414,49.2000876 113.173255,51.6589308 110.169024,51.6589308 M109.985185,33.8327008 C103.532445,33.8327008 99.2321505,38.5619524 99.2321505,45.0223522 C99.2321505,51.4750921 103.547765,56.1890238 109.985185,56.1890238 C116.437925,56.1890238 120.738219,51.4597722 120.738219,45.0223522 C120.738219,38.5849323 116.437925,33.8327008 109.985185,33.8327008"
											fill="#003E33"
										></path>
										<path
											id="n"
											className="words union-letters letters-to-fade fade"
											d="M76.0949724,33.8173809 C73.3052195,33.8173809 70.890804,35.2360032 69.5886129,37.3961084 L69.5886129,34.2539979 L64.0014471,34.2539979 L64.0014471,55.7600668 L64.016767,55.7600668 L69.6039328,55.7600668 L69.6039328,43.8044206 C69.6039328,40.7925292 71.6813106,38.3536018 74.255053,38.3536018 L74.6089426,38.3536018 C76.7690478,38.3536018 78.0038313,39.8564836 78.0038313,42.6538964 L78.0038313,55.7600668 L83.5909971,55.7600668 L83.5909971,42.0028009 C83.5833372,37.2582293 81.2608411,33.8173809 76.0949724,33.8173809"
											fill="#003E33"
										></path>
										<path
											id="s"
											className="words"
											d="M171.282537,30.3763792 L171.719154,30.3763792 C174.815305,30.3763792 177.099501,31.9635204 177.30632,35.1040989 L183.108007,35.1040989 C183.123284,29.386714 179.03134,25.2028506 171.719154,25.2028506 C164.330368,25.2028506 160.315024,29.7176237 160.315024,34.2308649 C160.315024,38.6920183 162.67582,41.7881691 168.056167,42.9065215 L173.444174,44.0264058 C176.102176,44.5779221 176.839063,45.612015 176.839063,47.3370352 C176.839063,49.2750019 175.167662,50.9847022 171.941292,50.9847022 L171.504675,50.9847022 C166.989902,50.9847022 164.829797,48.6085865 164.630638,45.604355 L158.828994,45.604355 C159.012833,51.8747881 162.806039,56.1888706 171.068058,56.1888706 C178.508931,56.1888706 182.640707,52.1122465 182.640707,46.9463779 C182.640707,42.0486074 179.467957,39.3890736 174.294428,38.3320008 L169.781187,37.428127 C167.190593,36.9057186 166.116668,36.0095047 166.116668,34.0163863 C166.116668,32.0768876 168.056167,30.3763792 171.282537,30.3763792"
											fill="#003E33"
										></path>
										<path
											id="t"
											className="words stockley-letters letters-to-fade fade"
											d="M195.569933,48.6471926 L195.569933,38.7536043 L200.383444,38.7536043 L200.383444,34.2388312 L195.569933,34.2388312 L195.569933,27.2805348 L189.982767,27.2805348 L189.982767,34.2541511 L185.552253,34.2541511 L185.552253,38.7689242 L189.982767,38.7689242 L189.982767,50.1715222 C189.967447,53.8207212 191.905414,55.76022 195.569933,55.76022 L201.019219,55.76022 L201.019219,51.0233084 L197.923069,51.0233084 C195.99123,51.0233084 195.569933,50.5866914 195.569933,48.6471926"
											fill="#003E33"
										></path>
										<path
											id="o"
											className="words stockley-letters letters-to-fade fade"
											d="M214.668174,51.6589308 L214.315816,51.6589308 C211.303925,51.6589308 209.364426,49.2000876 209.364426,44.9917124 C209.364426,40.7756773 211.303925,38.3229621 214.315816,38.3229621 L214.668174,38.3229621 C217.680065,38.3229621 219.619564,40.7833372 219.619564,44.9917124 C219.619564,49.2000876 217.680065,51.6589308 214.668174,51.6589308 M214.484335,33.8327008 C208.031595,33.8327008 203.7313,38.5619524 203.7313,45.0223522 C203.7313,51.4750921 208.046915,56.1890238 214.484335,56.1890238 C220.937075,56.1890238 225.237369,51.4597722 225.237369,45.0223522 C225.237369,38.5849323 220.937075,33.8327008 214.484335,33.8327008"
											fill="#003E33"
										></path>
										<path
											id="c"
											className="words stockley-letters letters-to-fade fade"
											d="M238.710298,38.3316944 L239.062656,38.3316944 C241.392812,38.3316944 242.641383,39.6721852 242.978421,41.7725429 L248.567118,41.7725429 C248.128969,36.3921957 244.482834,33.8169213 238.886477,33.8169213 C233.084832,33.8169213 228.571591,38.1172159 228.571591,45.0065727 C228.571591,51.8959296 233.084832,56.1962242 238.886477,56.1962242 C244.465983,56.1962242 248.128969,53.6209498 248.567118,48.2329427 L242.978421,48.2329427 C242.627595,50.3409602 241.392812,51.6753231 239.062656,51.6753231 L238.710298,51.6753231 C235.537548,51.6753231 234.149565,48.6634317 234.149565,45.0065727 C234.149565,41.3435858 235.514568,38.3316944 238.710298,38.3316944"
											fill="#003E33"
										></path>
										<polygon
											id="k"
											className="words stockley-letters letters-to-fade fade"
											fill="#003E33"
											points="252.942634 25.6554003 252.942634 55.7513344 258.520608 55.7513344 258.520608 49.3522142 261.013155 46.8244315 266.998638 55.7513344 273.573937 55.7513344 264.899812 42.8152149 273.152639 34.2529255 266.31537 34.2529255 258.520608 42.2483788 258.520608 25.6554003"
										></polygon>
										<polygon
											id="l"
											className="words stockley-letters letters-to-fade fade"
											fill="#003E33"
											points="276.631788 55.7511812 282.220485 55.7511812 282.220485 25.6552471 276.631788 25.6552471"
										></polygon>
										<path
											id="e"
											className="words stockley-letters letters-to-fade fade"
											d="M292.19818,42.4623977 C292.328399,39.8871234 294.304666,38.332154 296.628694,38.332154 L296.979519,38.332154 C299.302015,38.332154 301.279814,39.8718035 301.410033,42.4623977 L292.19818,42.4623977 Z M306.81336,44.7542541 C306.81336,38.5159927 303.449111,33.8327008 297.017819,33.8327008 C290.566611,33.8327008 286.787193,38.5619524 286.787193,45.0223522 C286.787193,52.0955478 291.654324,56.1890238 297.033139,56.1890238 C302.413486,56.1890238 305.708796,53.1771324 306.74442,48.9687572 L301.195554,48.9687572 C300.812557,50.4256793 299.524154,51.6834427 297.416136,51.6834427 L297.065311,51.6834427 C294.741283,51.6834427 292.420318,49.7424119 292.19818,46.7841402 L306.727569,46.7841402 C306.75974,46.0870849 306.81336,45.4053496 306.81336,44.7542541 L306.81336,44.7542541 Z"
											fill="#003E33"
										></path>
										<path
											id="y"
											className="words stockley-letters letters-to-fade fade"
											d="M326.104019,34.2541511 L320.516853,47.3296816 L314.713677,34.2541511 L308.446308,34.2541511 L317.81136,54.0811595 C317.81136,54.0811595 316.469337,58.9620781 315.288173,59.4461867 C314.110073,59.9287634 311.02005,59.6300255 311.02005,59.7679045 C311.02005,59.9134436 310.937323,64.4649844 310.937323,64.4649844 C312.431012,64.5048161 313.817463,64.5431159 314.28472,64.5339239 C318.064138,64.4971562 318.822473,63.0172543 319.351009,62.2665795 C320.064916,61.2478064 320.72214,59.6453454 322.202042,55.9900184 L332.29632,34.2541511 L326.104019,34.2541511 Z"
											fill="#003E33"
										></path>
										<path
											id="p"
											className="words"
											d="M358.191999,39.4508127 L351.088164,39.4508127 L351.088164,30.8057959 L358.191999,30.8057959 C361.205422,30.8057959 362.706772,32.5292841 362.706772,35.1045585 C362.706772,37.6798329 361.205422,39.4508127 358.191999,39.4508127 M358.804795,25.6629071 L345.263539,25.6629071 L345.263539,55.7603732 L351.065184,55.7603732 L351.065184,44.6228094 L358.804795,44.6228094 C364.606439,44.6228094 368.485448,41.1436612 368.485448,35.1275383 C368.493096,29.1037555 364.614099,25.6629071 358.804795,25.6629071"
											fill="#003E33"
										></path>
										<path
											id="a"
											className="words park-letters letters-to-fade fade"
											d="M384.243327,46.9768645 C384.243327,49.7666174 381.951471,51.6586244 379.460456,51.6586244 L379.106567,51.6586244 C377.597557,51.6586244 376.448565,50.8083702 376.448565,49.4663474 C376.448565,47.6968995 378.172053,46.9768645 379.590675,46.8389854 L384.235668,46.3701966 L384.235668,46.9768645 L384.243327,46.9768645 Z M389.660442,41.5582175 C389.660442,36.522568 386.020435,33.8170745 380.63396,33.8170745 C375.245953,33.8170745 371.820424,36.8749256 371.389935,40.907122 L376.969441,40.907122 C377.10732,39.6493586 378.340572,38.3318476 380.448589,38.3318476 L380.802479,38.3318476 C382.908965,38.3318476 384.243327,39.8331973 384.243327,41.7726961 L384.243327,42.2935725 L378.824681,42.7623613 C374.555026,43.1147189 370.945658,45.2212045 370.945658,49.8508768 C370.945658,53.7283423 374.126069,56.1733975 378.005066,56.1733975 C380.793287,56.1733975 383.069824,54.9692538 384.373547,53.1155465 C384.402654,54.1036797 384.503766,55.0918129 384.641645,55.7444405 L389.891773,55.7444405 C389.652782,54.2568786 389.652782,52.3189119 389.652782,51.0305087 L389.652782,41.5582175 L389.660442,41.5582175 Z"
											fill="#003E33"
										></path>
										<path
											id="r"
											className="words park-letters letters-to-fade fade"
											d="M400.82742,38.5464793 L400.82742,34.2461847 L395.241787,34.2461847 L395.241787,55.7522536 L400.82742,55.7522536 L400.82742,44.585582 C400.82742,40.937915 402.552441,39.2052348 406.21696,39.2052348 L408.154926,39.2052348 L408.154926,34.0393408 L407.065682,34.0393408 C403.839312,34.0317062 401.679207,35.7567264 400.82742,38.5464793"
											fill="#003E33"
										></path>
										<polygon
											id="k"
											className="words park-letters letters-to-fade fade"
											fill="#003E33"
											points="411.932353 25.6554003 411.932353 55.7513344 417.511859 55.7513344 417.511859 49.3522142 420.002874 46.8244315 425.988357 55.7513344 432.563656 55.7513344 423.880339 42.8152149 432.143891 34.2529255 425.306621 34.2529255 417.511859 42.2483788 417.511859 25.6554003"
										></polygon>
									</g>
								</g>
							</svg>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Loading;
