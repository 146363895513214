import React, { Component } from "react";
import pannellum from "pannellum";
import Loop from "../viz/Loop";
import { virtualViewerData, GetScene } from "../data/virtualViewerData";
import { floorsData, GetFloorByName } from "../data/floorsData";
import { G } from "../globals";

class VirtualViewer extends Component {
	constructor() {
		super();

		this.IntroAnim = this.IntroAnim.bind(this);
		this.OutroAnim = this.OutroAnim.bind(this);
		this.RenderHotspot = this.RenderHotspot.bind(this);
		this.LoadScene = this.LoadScene.bind(this);

		G.RenderHotspot = this.RenderHotspot;
		G.VirtualLoadScene = this.LoadScene;

		this.pannellum = window.pannellum;
		this.fakePannellum = pannellum;
		this.originState = null;

		this.refNorthAngle = 0;
		this.currentScene = null;
		this.loop = null;
		this.miniMapActive = true;
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.OutroAnim);
		document.addEventListener("camDone", this.IntroAnim);

		this.gyroSupport = false;
		if (typeof DeviceMotionEvent === "function") {
			this.gyroSupport = true;
			if (DeviceMotionEvent.requestPermission) DeviceMotionEvent.requestPermission();
		}

		this.Init();

		this.loop = new Loop(() => {
			if (this.viewer) {
				this.compassIcon.style.transform = `rotate(${
					this.refNorthAngle - this.viewer.getYaw()
				}deg)`;
			}
		});
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.OutroAnim);
		document.removeEventListener("camDone", this.IntroAnim);
	}

	SetNav(currentScene) {
		const allHotspotsOnFloor = virtualViewerData.filter(
			(v) => v.floor.toLowerCase() === currentScene.floor.toLowerCase()
		);
		const floorImage = GetFloorByName(currentScene.floor).floorPlan.img;

		this.miniMap.style.backgroundImage = `url(./images/${floorImage})`;
		this.miniMap.innerHTML = "";
		allHotspotsOnFloor.map((h) => {
			const div = document.createElement("div");
			div.classList.add("virtual-icon");
			if (h.name === currentScene.name) div.classList.add("virtual-icon-active");
			div.style.left = `${h.mapPos ? h.mapPos.x : 0}px`;
			div.style.top = `${h.mapPos ? h.mapPos.y : 0}px`;
			div.onclick = () => {
				this.props.GoToState(h.state);
			};
			this.miniMap.appendChild(div);
		});
	}

	ToggleMiniMap(forceBool = null) {
		if (this.miniMapActive || forceBool === false) {
			this.miniMap.classList.add("hidden");
			this.miniMapActive = false;
		} else if (!this.miniMapActive || forceBool === true) {
			this.miniMap.classList.remove("hidden");
			this.miniMapActive = true;
		}
	}

	IntroAnim(e) {
		if (this.viewerDiv && this.props.currentState.options.virtualScene) {
			//if the previous state was defined as an origin point - set that state as a flag in this viewer
			//this way by pressing close you'll go back to origin, rather than default state (which is BUILDING)
			if (
				this.props.previousState &&
				this.props.previousState.options &&
				this.props.previousState.options.virtualSceneOrigin
			)
				this.originState = this.props.previousState;

			this.viewerDiv.classList.remove("hidden");
			setTimeout(() => {
				this.overlayBg.classList.remove("fade");
				setTimeout(() => {
					this.galleryDiv.classList.remove("fade");
					this.isActive = true;
					this.loop.start();
				}, 400);
			}, 100);
			this.currentScene = GetScene(this.props.currentState.options.virtualScene);
			
			//set which way is north in reference to scene's default yaw angle so the compass will point this way
			this.refNorthAngle = this.currentScene.northAngle ? this.currentScene.northAngle : 0;
			this.SetNav(this.currentScene);
			this.LoadScene(this.currentScene.name);
		}
	}

	OutroAnim() {
		this.galleryDiv.classList.add("fade");
		this.overlayBg.classList.add("fade");
		setTimeout(() => {
			this.viewerDiv.classList.add("hidden");
		}, 100);
		this.loop.stop();
		// this.ToggleMiniMap(false);
	}

	RenderHotspot(div, text) {
		// div.classList.add("custom-tooltip");
		const wrapperDiv = document.createElement("div");
		const labelDiv = document.createElement("p");
		wrapperDiv.classList.add("custom-label");
		labelDiv.innerHTML = text;
		wrapperDiv.appendChild(labelDiv);
		div.appendChild(wrapperDiv);
		const wrapperWidth = 300;
		wrapperDiv.style.width = wrapperWidth + "px";
		wrapperDiv.style.marginLeft = -(wrapperWidth - div.offsetWidth) / 2 + "px";
		wrapperDiv.style.marginTop = -wrapperDiv.scrollHeight - 6 + "px";
		div.classList.add("custom-tooltip");
	}

	LoadScene(scene) {
		this.viewer.loadScene(scene);
		this.currentScene = GetScene(scene);
		console.log("new scene!");
		console.log(this.currentScene);
		this.galleryCaption.innerHTML = this.currentScene.title;
		window.location.hash = this.currentScene.state.toLowerCase();
		this.SetNav(this.currentScene);
	}

	CloseHandler() {
		if (this.originState) {
			this.props.GoToState(this.originState.name);
			this.originState = null;
		} else this.props.GoToState("BUILDING");
	}

	Init() {
		this.viewer = this.pannellum.viewer("pano", {
			mouseZoom: false,
			keyboardZoom: false,
			showControls: false,
			showZoomCtrl: false,
			orientationOnByDefault: this.gyroSupport ? true : false,
			isOrientationSupported: this.gyroSupport ? true : false,
			scenes: {},
		});
		virtualViewerData.map((d) => {
			this.viewer.addScene(d.name, d.sceneData);
		});
	}

	render() {
		return (
			<div
				ref={(ref) => (this.viewerDiv = ref)}
				className={`content-overlay clickable hidden`}
			>
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="content-overlay-bg anim-all-200 fade"
				></div>
				<div className="content-overlay-container">
					<div
						className="overlay-close-icon close-button-bg"
						onClick={() => {
							this.CloseHandler();
						}}
					></div>
					<div className="virtual-view">
						<div
							ref={(ref) => (this.galleryDiv = ref)}
							id="pano"
							className="gallery-image anim-all-400 fade"
						></div>
						<div className="gallery-controls">
							<div
								ref={(ref) => (this.galleryCaption = ref)}
								className="gallery-title"
							>
								
							</div>
						</div>
						<div className="virtual-viewer-nav clickable">
						
							<div
								ref={(ref) => (this.miniMap = ref)}
								className="virtual-plan-wrapper"
							>
								<div className="virtual-icon"></div>
								<div className="virtual-icon virtual-icon-active"></div>
							</div>
							<div className="virtual-compass-container">
							
							<div className="virtual-compass" onClick={() => this.ToggleMiniMap()}>
								<div
									ref={(ref) => (this.compassIcon = ref)}
									className="virtual-compass-icon"
								></div>
								</div> <div className="virtual-plan-note">Use the atrium stairs <br/> to switch between floors</div>
							</div>
							
						</div>
						<div className="virtual-instruction-wrapper">
							<div className="virtual-instruction">
								<ul className="intructions-list w-list-unstyled">
									<li className="instruction">
										<div className="instruction-icon inst-left-click"></div>
										<div className="instruction-divider"></div>
										<div className="instruction-text">
											<strong>
												360 image – click and drag to look around
											</strong>
										</div>
										
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default VirtualViewer;
