export const G = {
	app: null,
	debug: {},
	objects: {},
	raycastLayer: [],
	markers: {},
	overlays: {},
	//Placeholder function
	ToggleMarkers: () => {}
};
