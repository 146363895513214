import React, { Component } from "react";

class ImageViewer extends Component {
	componentDidMount() {
		setTimeout(() => {
			this.IntroAnim();
		}, 100);
	}

	IntroAnim() {
		this.overlayBg.classList.remove("fade");
		setTimeout(() => {
			this.galleryDiv.classList.remove("fade");
		}, 400);
	}

	OutroAnim() {
		this.galleryDiv.classList.add("fade");
		this.overlayBg.classList.add("fade");
	}

	CloseHandler() {
		this.OutroAnim();

		setTimeout(() => {
			this.props.ShowImageViewer(false);
		}, 1000);
	}

	render() {
		return (
			<div className="content-overlay clickable">
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="content-overlay-bg anim-all-200 fade"
				></div>
				<div className="content-overlay-container">
					<div
						className="overlay-close-icon close-button-bg"
						onClick={() => this.CloseHandler()}
					></div>
					<div
						ref={(ref) => (this.galleryDiv = ref)}
						className="gallery anim-all-400 fade"
					>
						<div
							ref={(ref) => (this.galleryImage = ref)}
							className="gallery-image"
							style={{ backgroundImage: `url(./images/${this.props.content})` }}
						></div>
					</div>
				</div>
			</div>
		);
	}
}

export default ImageViewer;
