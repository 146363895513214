import React, { Component } from 'react';
import anime from 'animejs';

class Home extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
		this.IntroAnim = this.IntroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener('stateWillChange', this.OutroAnim);
		document.addEventListener('loadDone', this.IntroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener('stateWillChange', this.OutroAnim);
		document.removeEventListener('loadDone', this.IntroAnim);
	}

	OutroAnim(e) {
		if (e.detail.newState.name === 'HOME') return;

		const timeline = new anime.timeline();
		timeline.add({
			targets: this.content,
			opacity: [ 1, 0 ],
			easing: 'easeInOutQuad',
			duration: 800
		});
	}

	render() {
		return (
			<div className="slide">
				<div ref={(ref) => (this.content = ref)} className="content">
					<div className="content-side">
						<div className="section-text-wrapper">
							<div className="section-text">The Feeling</div>
						</div>
						<div className="section-graphic feeling-graphic" />
					</div>
					<div className="content-main">
						<div className="section-header-wrapper">
							<div className="section-header fulfillment-at-work" />
							<div className="copy clickable">
								Mauris nec venenatis turpis. Mauris sagittis mauris purus, id finibus neque malesuada
								eget.
							</div>
						</div>
						<div className="section-bottom-wrapper">
							<ul className="section-nav-list w-list-unstyled">
								<li className="section-nav-item">Explore floors</li>
								<li className="section-nav-item">View gallery</li>
								<li className="section-nav-item">View specification</li>
							</ul>
							<h2 className="section-subhead clickable">
								75,000 sq ft of newly refurbished office space
							</h2>
						</div>
					</div>
				</div>
				<div className="content-fade" />
				<div className="content-top-fade" />
			</div>
		);
	}
}

export default Home;
