import React, { Component } from "react";
import anime from "animejs";

class Contact extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
		this.IntroAnim = this.IntroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.OutroAnim);
		// document.addEventListener('camDone', this.IntroAnim);
		this.IntroAnim();
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.OutroAnim);
		// document.removeEventListener('camDone', this.IntroAnim);
	}

	OutroAnim() {
		const timeline = new anime.timeline();

		timeline
			.add({
				targets: document.querySelectorAll(".fade"),
				opacity: [1, 0],
				easing: "easeInOutQuad",
				duration: 400,
			})
			.add({
				targets: this.bg,
				opacity: [1, 0],
				duration: 400,
				easing: "easeInOutQuad",
			});
	}

	IntroAnim() {
		const timeline = new anime.timeline();

		timeline
			.add({
				targets: this.bg,
				opacity: [0, 1],
				duration: 600,
				easing: "easeInOutQuad",
			})
			.add(
				{
					targets: document.querySelectorAll(".fade"),
					opacity: [0, 1],
					easing: "easeInOutQuad",
					duration: 600,
				},
				"-=200"
			);
	}

	render() {
		return (
			<div className="slide clickable contact-slide">
				<div className="content full-width-content fade">
					<div className="content-side">
						<div className="section-graphic-text contactus" />
						<div className="section-graphic detail-graphic contactus-graphic" />
					</div>
					<div className="content-main contact-content">
						<div className="contact-logo-wrapper">
							<div className="contact-logo" />
							<div className="usp-address">
								1 Furzeground Way, Stockley Park, UB11 1BD
							</div>
						</div>
						<div className="contact-names-container">
							<div className="category-heading contact-heading">
								For more information, please get in touch:
							</div>
							<div className="contact-name">
								<div className="knightfrank-logo"></div>
								<div style={{marginBottom:20}}></div>
								Jack Riley
								<br />
								+44 20 7861 5375
								<br />
								<a
									href="mailto:jack.riley@knightfrank.com"
									className="contact-email-link"
								>
								jack.riley@knightfrank.com
								</a>
							{/* </div> */}
							{/* <div className="contact-name"> */}
								<div style={{marginBottom:16}}></div>
								Tom Slater
								<br />
								+44 20 3995 0775
								<br />
								<a
									href="mailto:tom.slater@knightfrank.com"
									className="contact-email-link"
								>
								tom.slater@knightfrank.com
								</a>
							</div>
							<div className="contact-name">
								<div className="cushmanwakefield-logo"></div>
								<div style={{marginBottom:20}}></div>
								Henry Harrison
								<br />
								+44 7785 222 430
								<br />
								<a
									href="mailto:henry.harrison@eur.cushwake.com"
									className="contact-email-link"
								>
								henry.harrison@eur.cushwake.com
								</a>
							{/* </div> */}
							{/* <div className="contact-name Mr0"> */}
								<div style={{marginBottom:16}}></div>
								James Goodwin
								<br />
								+44 7717 652 803
								<br />
								<a
									href="mailto:james.goodwin@cushwake.com"
									className="contact-email-link"
								>
								james.goodwin@cushwake.com
								</a>
							</div>
							<div className="contact-logo-misrep">
								<div>
								{/* <div className="knightfrank-logo"></div> */}
								{/* <div>&nbsp;</div> */}
								{/* <div className="knightfrank-logo"></div> */}
								</div>
								<div className="misrep">
									<strong>IMPORTANT NOTICE</strong> (1) Particulars: These
									particulars are not an offer, an invitation or a contract, nor
									part of one. Any information about price or value contained in
									the particulars is provided purely as guidance, it does not
									constitute a formal valuation and should not be relied upon for
									any purpose. You should not rely on statements by Knight Frank
									in the particulars or by word of mouth or in writing
									(“information”) as being factually accurate about the property,
									its condition or its value. Knight Frank has no authority to
									make any representations about the property. No responsibility
									or liability is or will be accepted by Knight Frank LLP,
									seller(s) or lessor(s) in relation to the adequacy, accuracy,
									completeness or reasonableness of the information, notice or
									document supplied or otherwise made available to any interested
									party or its advisers in connection with the Proposed
									Transaction. All and any such responsibility and liability is
									expressly disclaimed. (2) Images: Photographs show only certain
									parts of the property as they appeared at the time they were
									taken. Areas, measurements and distances given are approximate
									only. (3) Regulations: Any reference to alterations to, or use
									of, any part of the property does not mean that any necessary
									planning, building regulations or other consent has been
									obtained. A buyer or lessee must find out by inspection or in
									other ways that these matters have been properly dealt with and
									that all information is correct. (4) VAT: The VAT position
									relating to the property may change without notice. (5)
									Financial Crime: In accordance with the Money Laundering,
									Terrorist Financing and Transfer of Funds (Information on the
									Payer) Regulations 2017 and Proceeds of Crime Act 2002 Knight
									Frank LLP may be required to establish the identity and source
									of funds of all parties to property transactions.
								</div>
							</div>
							<div className="contact-development-line">
								<div className="contact-development-wrapper">
									<div className="misrep">A development by</div>
									<a href="http://www.marickrealestate.com" target="_blank">
										<div className="marick-logo"></div>
									</a>
									<div className="vert-divider"></div>
									<a href="https://www.tritax.co.uk" target="_blank">
										<div className="tritax-logo"></div>
									</a>
								</div>
							</div>
						</div>
						<div className="contact-downloads-container">
							<div className="popup-overlay clickable anim-all-200 fade">
								<div className="popup-overlay-container">
									<h3 className="popup-title">
										<strong>Downloads</strong>
									</h3>

									<ul className="downloads-list">
										<a href="./dl/USP_GAPack.pdf" target="_blank">
											<li className="downloads-item">
												<div className="downloads-icon"></div>
												<p>
													<strong>Download GA Pack</strong> <br /> 0.9mb,
													PDF
												</p>
											</li>
										</a>
										<a href="./dl/USP_FullSpecification.pdf" target="_blank">
											<li className="downloads-item">
												<div className="downloads-icon"></div>
												<p>
													<strong>Download Full Specification</strong> <br /> 0.1mb,
													PDF
												</p>
											</li>
										</a>
										<a href="./dl/USP_Plans.zip" target="_blank">
											<li className="downloads-item no-border">
												<div className="downloads-icon"></div>
												<p>
													<strong>Download floor plans</strong> <br />{" "}
													13.0mb, ZIP
												</p>
											</li>
										</a>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div ref={(ref) => (this.bg = ref)} className="content-bg" />
			</div>
		);
	}
}

export default Contact;
