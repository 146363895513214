import React, { Component } from "react";
import anime from "animejs";
import U from "../utils";

class FloorPlanViewer extends Component {
	constructor() {
		super();

		this.MouseDown = this.MouseDown.bind(this);
		this.MouseMove = this.MouseMove.bind(this);
		this.MouseUp = this.MouseUp.bind(this);
		this.ZoomIn = this.ZoomIn.bind(this);

		this.state = {
			showFloorSplit: false,
		};

		this.params = {
			isBusy: false,
			zoom: {
				current: 1,
				max: 2,
				min: 0.6,
				default: 1,
				rate: 0.4,
			},
			delta: {
				x: 0,
				y: 0,
			},
			drag: {
				elem: null,
				x: 0,
				y: 0,
				state: false,
			},
		};
	}

	componentDidMount() {
		this.params.drag.elem = this.planImage;
	}

	componentDidUpdate(prevProps) {
		if (this.props.isActive) {
			if (!prevProps.isActive) {
				console.log("HELLO I GET HERE");
				setTimeout(() => {
					this.IntroAnim();
				}, 50);
			}

			//bind event listeners
			if (U.GetResponsiveMode().isTouch) {
				this.planImage.addEventListener("touchmove", this.MouseMove);
				this.planImage.addEventListener("touchstart", this.MouseDown);
				document.addEventListener("touchend", this.MouseUp);
			} else {
				this.planImage.addEventListener("mousemove", this.MouseMove);
				this.planImage.addEventListener("mousedown", this.MouseDown);
				document.addEventListener("mouseup", this.MouseUp);
			}
		} else {
			this.ZoomReset();
			//clean up listener
			if (U.GetResponsiveMode().isTouch) {
				this.planImage.removeEventListener("touchmove", this.MouseMove);
				this.planImage.removeEventListener("touchstart", this.MouseDown);
				document.removeEventListener("touchend", this.MouseUp);
			} else {
				this.planImage.removeEventListener("mousemove", this.MouseMove);
				this.planImage.removeEventListener("mousedown", this.MouseDown);
				document.removeEventListener("mouseup", this.MouseUp);
			}
		}
	}

	MouseMove(e) {
		if (this.params.drag.state) {
			const pos = {
				x: U.GetResponsiveMode().isTouch ? e.touches[0].pageX : e.pageX,
				y: U.GetResponsiveMode().isTouch ? e.touches[0].pageY : e.pageY,
			};
			this.params.delta.x = pos.x - this.params.drag.x;
			this.params.delta.y = pos.y - this.params.drag.y;

			const imagePanStyle = {
				left: this.params.drag.elem.style.left.substring(
					0,
					this.params.drag.elem.style.left.length - 2
				),
				top: this.params.drag.elem.style.top.substring(
					0,
					this.params.drag.elem.style.top.length - 2
				),
			};

			this.params.drag.elem.style.left = `${
				parseInt(imagePanStyle.left) + this.params.delta.x
			}px`;
			this.params.drag.elem.style.top = `${
				parseInt(imagePanStyle.top) + this.params.delta.y
			}px`;

			this.params.drag.x = pos.x;
			this.params.drag.y = pos.y;

			this.params.drag.elem.style.cursor = "grabbing";
		}
	}

	MouseDown(e) {
		const primaryButton = U.GetResponsiveMode().isTouch ? 0 : 1;
		if (!this.params.drag.state && e.which === primaryButton) {
			if (e.target.classList[0] === this.planImage.classList[0])
				this.params.drag.elem = this.planImage;
			else return;

			this.params.drag.x = U.GetResponsiveMode().isTouch ? e.touches[0].pageX : e.pageX;
			this.params.drag.y = U.GetResponsiveMode().isTouch ? e.touches[0].pageY : e.pageY;
			this.params.drag.state = true;
		}
		return false;
	}

	MouseUp(e) {
		if (this.params.drag.state) {
			this.params.drag.state = false;
			this.params.drag.elem.style.cursor = "grab";
		}
	}

	ZoomIn() {
		if (this.params.isBusy) return;
		if (this.params.zoom.current + this.params.zoom.rate > this.params.zoom.max) return;
		this.params.isBusy = true;
		this.params.zoom.current = this.params.zoom.current + this.params.zoom.rate;
		this.ZoomAnim();
	}

	ZoomOut() {
		if (this.params.isBusy) return;
		if (this.params.zoom.current - this.params.zoom.rate < this.params.zoom.min) return;

		this.params.isBusy = true;
		this.params.zoom.current = this.params.zoom.current - this.params.zoom.rate;

		this.ZoomAnim();
	}

	ZoomReset() {
		if (this.params.isBusy) return;
		if (this.planImage === null) return;

		this.params.isBusy = true;
		this.params.zoom.current = this.params.zoom.default;

		anime({
			targets: this.planImage,
			top: "0px",
			left: "0px",
			easing: "easeInOutQuad",
			duration: 200,
			complete: () => {
				if (this.planImage) {
					this.planImage.style.top = "0px";
					this.planImage.style.left = "0px";
				}
			},
		});

		this.ZoomAnim();
	}

	ZoomAnim() {
		anime({
			targets: this.planImage,
			scale: this.params.zoom.current,
			duration: 200,
			easing: "easeOutQuad",
			complete: () => {
				this.params.isBusy = false;
			},
		});
	}

	ToggleFloorSplit(bool = false) {
		this.setState({ showFloorSplit: bool });
	}

	IntroAnim() {
		this.overlayBg.classList.remove("fade");
		this.contentDiv.classList.remove("fade");
		setTimeout(() => {
			this.viewerDiv.classList.remove("fade");
		}, 200);
	}

	OutroAnim() {
		this.viewerDiv.classList.add("fade");
		this.contentDiv.classList.add("fade");
		setTimeout(() => {
			this.overlayBg.classList.add("fade");
		}, 400);
	}

	CloseHandler() {
		this.OutroAnim();

		setTimeout(() => {
			this.props.CloseFunc(false);
		}, 1000);
	}

	render() {
		return (
			<div className={`content-overlay ${this.props.isActive ? "" : "hidden"}`}>
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="content-overlay-bg clickable anim-all-200 fade"
				></div>
				<div className="content-overlay-container">
					<div className="content-side plan-viewer-info"></div>
					<div
						ref={(ref) => (this.contentDiv = ref)}
						className="content-main plan-viewer-info anim-all-400 fade"
					>
						<div className="section-header-wrapper"></div>
						<div className="section-mid-wrapper">
							<h2 className="floor-view-title floor-plan-title clickable">
								{this.props.currentFloor.name} Floor
								<br />
								<span className="bold">
									{U.FormatNumber(this.props.currentFloor.sqft)} sq ft
								</span>
							</h2>

							<div className="plan-viewer-detail">
								<div className="plan-view-download clickable">
									<a
										href={`./dl/plans/${this.props.currentFloor.floorPlan.dl}`}
										target="_blank"
									>
										Download floor plan
									</a>
								</div>
								<div
									className={`plan-view-download clickable ${
										this.state.showFloorSplit ? "" : "hidden"
									}`}
								>
									<a
										href={this.props.currentFloor.floorSplit && this.props.currentFloor.floorSplit.dl ? `./dl/plans/${this.props.currentFloor.floorSplit.dl}` : `#`}
										target="_blank"
									>
										Download split floor plan
									</a>
								</div>

								<div
									className={`check-component plan-view-checks clickable ${this.props.currentFloor.floorSplit ? '' : 'hidden'}`}
									onClick={() =>
										this.ToggleFloorSplit(
											this.state.showFloorSplit ? false : true
										)
									}
								>
									<div
										className={`check ${
											this.state.showFloorSplit ? " active" : ""
										}`}
									></div>
									<div className="check-text">Show floor split</div>
								</div>
								<ul
									className={`schedule-sub-list ground-stats-list w-list-unstyled hidden ${
										this.state.showFloorSplit ? "" : "hidden"
									}`}
								>
									{this.props.currentFloor.floorSplit ? this.props.currentFloor.floorSplit.sizes.map((s, i) => {
										return (
											<li key={i} className="schedule-sub-item ">
												<div className="schedule-sub-text sub-label">
													{s.name}
												</div>
												<div className="schedule-sub-text">
													{U.FormatNumber(s.sqft)} sq ft
												</div>
												<div className="schedule-area sqm">
													({U.FormatNumber(s.sqm)} sq m)
												</div>
											</li>
										);
									}):  null}
								</ul>
							</div>
						</div>
						<div className="section-bottom-wrapper">
							<div
								className="plan-view-elevation"
								style={{
									backgroundImage: `url(./images/${this.props.currentFloor.elevImg})`,
								}}
							></div>
						</div>
					</div>
					<div
						ref={(ref) => (this.viewerDiv = ref)}
						className="plan-viewer anim-all-400 fade"
					>
						<div
							ref={(ref) => (this.planImage = ref)}
							className="plan-image clickable"
							style={{
								backgroundImage: `url(./images/${
									this.state.showFloorSplit
										? this.props.currentFloor.floorSplit.img
										: this.props.currentFloor.floorPlan.img
								})`,
								left: "0px",
								top: "0px",
							}}
						></div>
						<div className="plan-direction"></div>
						<ul className="plan-controls w-list-unstyled clickable">
							<li
								className="control-button zoom-in"
								onClick={() => this.ZoomIn()}
							></li>
							<li
								className="control-button zoom-out"
								onClick={() => this.ZoomOut()}
							></li>
							<li
								className="control-button zoom-reset"
								onClick={() => this.ZoomReset()}
							></li>
						</ul>
					</div>
					<div
						className="overlay-close-icon clickable"
						onClick={() => this.CloseHandler()}
					></div>
				</div>
			</div>
		);
	}
}

export default FloorPlanViewer;
