import * as THREE from "three";
import { CONFIG } from "../../config";
import Entity from "../Entity";
import U from "../../utils";

class GroundMarker extends Entity {
	constructor(params) {
		super();

		this.marker = new THREE.Group();
		this.markerContent = new THREE.Group();

		const imageMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(
				params.markerData.imageSize.width,
				params.markerData.imageSize.height
			),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#ffffff"),
				map: params.image,
				transparent: true,
			})
		);

		imageMesh.material.map.encoding = THREE.sRGBEncoding;
		imageMesh.material.map.anisotropy = CONFIG.r_anisotropy;
		imageMesh.material.map.magFilter = THREE.LinearFilter;
		imageMesh.material.map.minFilter = THREE.LinearMipMapLinearFilter;

		this.markerContent.add(imageMesh);
		// imageMesh.position.set(params.markerData.imageSize.width / 2, params.markerData.imageSize.height / 2, 0);
		this.markerContent.rotation.set(
			-Math.PI / 2,
			0,
			U.convertDegreesToRadians(params.markerData.rotation)
		);
		this.marker.add(this.markerContent);

		setTimeout(() => {
			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height ? params.markerData.height : 5,
				params.markerData.location.z
			);
		}, 300);
	}
}

export default GroundMarker;
