import * as THREE from "three";
import Entity from "../Entity";
import Loop from "../Loop";
import { G } from "../../globals";
import { CONFIG } from "../../config";
import U from "../../utils";

class IconMarker extends Entity {
	constructor(params) {
		super();

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();

		this.isActive = false;
		this.params = params;

		this.icon = new THREE.Mesh(
			new THREE.PlaneGeometry(params.iconSize.width, params.iconSize.height),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#ffffff"),
				map: params.icon,
				transparent: true,
				depthTest: false,
			})
		);

		this.icon.renderOrder = 4;

		this.icon.material.map.encoding = THREE.sRGBEncoding;
		this.icon.material.map.anisotropy = CONFIG.r_anisotropy;
		this.icon.material.map.magFilter = THREE.LinearFilter;
		this.icon.material.map.minFilter = THREE.LinearMipMapLinearFilter;

		if (params.markerData.data) this.enableInteraction(this.icon);

		this.markerLabelGroup.add(this.icon);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);
		this.marker.add(this.markerLookGroup);

		this.loop = new Loop(() => {
			// this.ScaleWithZoom();
			if (this.marker.visible) this.RotateToFaceCamera();
		}).start();

		setTimeout(() => {
			this.markerLabelGroup.position.set(0, this.markerLabelGroup.position.y + 1, 0);
			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height * params.forceScale,
				params.markerData.location.z
			);

			this.marker.scale.set(
				this.marker.scale.x * params.forceScale,
				this.marker.scale.y * params.forceScale,
				this.marker.scale.z * params.forceScale
			);
		}, 300);
	}

	onClick() {
		if (this.params.markerData.data && this.isVisible && G.ShowAmenityPopup) {
			if (!this.isActive) {
				// G.ShowAmenityPopup(true, this.params.markerData.data);
				// EventMarker.ResetAllMarkers();
				// this.SetActiveAppearance();
			} else {
				// G.ShowAmenityPopup(false);
				// this.ResetMarkerAppearance();
			}
		}
	}

	onMouseEnter() {
		// if (this.isVisible) document.body.style.cursor = "pointer";
	}

	onMouseLeave() {
		// document.body.style.cursor = "auto";
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}
}

export default IconMarker;
