import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { G } from "../globals";
import Loop from "./Loop";
import Entity from "./Entity";
import U from "../utils";

class Building extends Entity {
  constructor() {
    super();

    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("models/draco/");
    loader.setDRACOLoader(dracoLoader);

    this.obj = null;

    loader.load("models/buildingFinal_v6.glb", (data) => {
      this.obj = data.scene;
      this.obj.position.set(-6.4, 0, 7.75);
      this.obj.rotation.set(0, 0.13, 0);
      this.obj.scale.set(0.25, 0.25, 0.25);

      //GET FLOORS
      this.floors = {};
      this.floorArray = ["GROUND", "SECOND", "FIRST", "ROOF"];
      this.floorArray.map((f) => {
        this.floors[f] = this.obj.children.find(
          (fl) => fl.name.toLowerCase() === f.toLowerCase()
        );
        return null;
      });

      // if (window.safari) {
      const allMats = [];
      this.obj.traverse((o) => {
        if (o.material) allMats.push(o);
      });

      allMats.map((o, i) => {
        o.castShadow = false;
        o.material.minFilter = THREE.LinearFilter;
        if (o.material.alphaTest > 0) o.material.alphaTest = 0;
      });
      // }

      this.obj.traverse((o) => {
        if (o.isMesh && o.material && o.material.map) {
          // o.material.map.anisotropy =
          //   G.renderer.capabilities.getMaxAnisotropy();
          // o.material.map.magFilter = THREE.NearestFilter;
          // o.material.map.minFilter = THREE.NearestFilter;
          // o.material.color = new THREE.Color("#010810");
          // o.material.map.encoding = THREE.sRGBEncoding;
          // // o.material.map.magFilter = THREE.LinearFilter;
          // // o.material.map.minFilter = THREE.LinearMipMapLinearFilter;
          // o.material.toneMapped = true;
          // o.material.transparent = true;
          // o.material.needsUpdate = true;
        }
      });

      this.obj.renderOrder = 3;

      this.Instantiate(this.obj, "building");
    });

    // loader.load("models/building.glb", (data) => {
    // 	this.obj = data.scene;
    // 	this.obj.position.set(0.5, 0, -1.5);
    // 	this.obj.rotation.set(0, 0.13, 0);

    // 	//GET FLOORS
    // 	this.floors = {};
    // 	this.floorArray = ["GROUND", "SECOND", "FIRST", "ROOF"];
    // 	this.floorArray.map((f) => {
    // 		this.floors[f] = this.obj.children.find((fl) => fl.name === f.toLowerCase());
    // 		return null;
    // 	});

    // 	this.Instantiate(this.obj, "buildingOld");
    // });

    // const debugLoop = new Loop(() => {
    // 	G.debug.TrackVar(
    // 		'buildingPos',
    // 		`x: ${U.RoundNum(this.obj.position.x, 3)}  //  y: ${U.RoundNum(
    // 			this.obj.position.y,
    // 			3
    // 		)}  //  z: ${U.RoundNum(this.obj.position.z, 3)}`
    // 	);

    // 	G.debug.TrackVar(
    // 		'buildingRot',
    // 		`x: ${U.RoundNum(this.obj.rotation.x, 3)}  //  y: ${U.RoundNum(
    // 			this.obj.rotation.y,
    // 			3
    // 		)}  //  z: ${U.RoundNum(this.obj.rotation.z, 3)}`
    // 	);
    // }).start();
  }

  onStateChange(newState) {
    this.SetBuildingState(newState.name);
  }

  SetBuildingState(_state) {
    this.ResetBuildingState();
    switch (_state) {
      case "SECOND":
        this.floors.ROOF.visible = false;
        break;
      case "FIRST":
        this.floors.ROOF.visible = false;
        this.floors.SECOND.visible = false;
        break;
      case "GROUND":
        this.floors.ROOF.visible = false;
        this.floors.SECOND.visible = false;
        this.floors.FIRST.visible = false;
        break;
      default:
        break;
    }
  }

  ResetBuildingState() {
    if (this.floorArray && Array.isArray(this.floorArray)) {
      this.floorArray.map((fl) => {
        this.floors[fl].visible = true;
        return null;
      });
    }
  }
}

export default Building;
