import * as THREE from "three";
import Entity from "../Entity";
import Loop from "../Loop";
import { G } from "../../globals";
import { CONFIG } from "../../config";
import U from "../../utils";

const markersThisType = [];

class OccupierMarker extends Entity {
	constructor(params) {
		super();

		this.ResetMarkerAppearance = this.ResetMarkerAppearance.bind(this);

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();
		this.markerStaticGroup = new THREE.Group();
		this.markerLogoGroup = new THREE.Group();

		this.params = params;

		markersThisType.push(this);
		document.addEventListener("popupWillClose", OccupierMarker.ResetAllMarkers);
		this.isActive = false;

		const textGeo = new THREE.TextGeometry(params.markerData.text, {
			font: params.font,
			size: 0.75,
			height: 0.01,
			curveSegments: 3,
		});

		this.textMesh = new THREE.Mesh(
			textGeo,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.textColor ? params.textColor : "#003E34"
				).convertGammaToLinear(2.2),
				// depthTest: false,
			})
		);

		const planePadding = { x: 2, y: 1.25 };

		this.planeMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(1, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.color ? params.color : "#EDF0EF"
				).convertGammaToLinear(2.2),
				// depthTest: false,
			})
		);

		const pinPoint = new THREE.Mesh(
			new THREE.CylinderGeometry(0.1, 0.1, 10, 3, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#003E34").convertGammaToLinear(2.2),
			})
		);

		let logoGroupTotalWidth = 0;
		this.logoArr = params.markerData.logos.map((item, i) => {
			const logoGroup = new THREE.Group();

			const logoPadding = { x: 1, y: 1, margin: 0.25 };

			const planeMesh = new THREE.Mesh(
				new THREE.PlaneGeometry(item.size.w + logoPadding.x, 3),
				new THREE.MeshBasicMaterial({
					color: new THREE.Color("white").convertGammaToLinear(2.2),
				})
			);

			const logoImg = new THREE.Mesh(
				new THREE.PlaneGeometry(item.size.w, item.size.h),
				new THREE.MeshBasicMaterial({
					color: new THREE.Color("#ffffff"),
					map: params.logos[i],
					transparent: true,
				})
			);

			logoImg.position.set(0, 0, 0.1);

			logoImg.material.map.encoding = THREE.sRGBEncoding;
			logoImg.material.map.anisotropy = CONFIG.r_anisotropy;
			logoImg.material.map.magFilter = THREE.LinearFilter;
			logoImg.material.map.minFilter = THREE.LinearMipMapLinearFilter;

			if (params.markerData.data) this.enableInteraction(planeMesh);

			logoGroup.add(logoImg);
			logoGroup.add(planeMesh);

			planeMesh.geometry.computeBoundingBox();
			const logoWidth =
				planeMesh.geometry.boundingBox.max.x -
				planeMesh.geometry.boundingBox.min.x +
				logoPadding.margin;

			// planeMesh.position.set(i * (item.size.w + logoPadding.x + logoPadding.margin), 0, 0);
			// logoImg.position.set(i * (item.size.w + logoPadding.x + logoPadding.margin), 0, 0.1);
			logoGroup.position.set(logoGroupTotalWidth + logoWidth / 2, 0, 0);
			logoGroupTotalWidth = logoGroupTotalWidth + logoWidth;

			this.markerLogoGroup.add(logoGroup);

			return logoGroup;
		});

		// const testGeo = new THREE.Mesh(
		// 	new THREE.BoxGeometry(2, 2, 2),
		// 	new THREE.MeshBasicMaterial({
		// 		color: new THREE.Color("magenta").convertGammaToLinear(2.2),
		// 	})
		// );

		pinPoint.position.set(0, -6.5, 0);

		this.textMesh.geometry.computeBoundingBox();

		let leftLabelPos;

		leftLabelPos = {
			x: this.textMesh.geometry.boundingBox.min.x,
			z:
				(this.textMesh.geometry.boundingBox.max.z -
					this.textMesh.geometry.boundingBox.min.z) /
				2,
		};

		// testGeo.position.set(leftLabelPos.x, 0, leftLabelPos.z);

		this.planeMesh.scale.set(
			this.textMesh.geometry.boundingBox.max.x + planePadding.x,
			this.textMesh.geometry.boundingBox.max.y + planePadding.y,
			this.textMesh.geometry.boundingBox.max.z
		);

		this.planeMesh.position.set(
			this.textMesh.geometry.boundingBox.max.x / 2,
			this.textMesh.geometry.boundingBox.max.y / 2,
			leftLabelPos.z + -0.1
		);

		this.planeMesh.renderOrder = 3;

		this.textMesh.renderOrder = 4;

		this.markerStaticGroup.add(pinPoint);
		// this.markerLabelGroup.add(testGeo);
		this.markerLabelGroup.add(this.textMesh);
		this.markerLabelGroup.add(this.planeMesh);
		this.markerLabelGroup.add(this.markerLogoGroup);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);
		this.marker.add(this.markerStaticGroup);
		this.marker.add(this.markerLookGroup);

		this.enableInteraction(this.planeMesh);

		this.loop = new Loop(() => {
			// this.ScaleWithZoom();
			if (this.marker.visible) this.RotateToFaceCamera();
		}).start();

		setTimeout(() => {
			this.markerLogoGroup.position.set(
				-1.11, //we offset the planeMesh padding against the origin which starts at the beginning of text (as visualsed by the pin line)
				this.textMesh.geometry.boundingBox.max.y + planePadding.y + 1,
				leftLabelPos.z + -0.1
			);

			this.markerLabelGroup.position.set(
				-leftLabelPos.x,
				this.markerLabelGroup.position.y,
				-leftLabelPos.z
			);
			this.marker.position.set(
				params.markerData.location.x,
				params.height * params.forceScale,
				params.markerData.location.z
			);

			this.marker.scale.set(
				this.marker.scale.x * params.forceScale,
				this.marker.scale.y * params.forceScale,
				this.marker.scale.z * params.forceScale
			);
		}, 300);
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}

	onClick() {
		if (this.params.markerData && this.isVisible && G.ShowAmenityPopup) {
			if (!this.isActive) {
				G.ShowAmenityPopup(true, this.params.markerData);
				OccupierMarker.ResetAllMarkers();
				this.SetActiveAppearance();
			} else {
				G.ShowAmenityPopup(false);
				this.ResetMarkerAppearance();
			}
		}
	}

	onMouseEnter() {
		if (this.isVisible) {
			document.body.style.cursor = "pointer";
			this.planeMesh.material.color = new THREE.Color('#003E34').convertGammaToLinear(2.2);
			this.textMesh.material.color = new THREE.Color('white').convertGammaToLinear(2.2);
		}
	}

	onMouseLeave() {
		document.body.style.cursor = "auto";
		if (this.isActive) {
			this.SetActiveAppearance()
		} else {
			this.planeMesh.material.color = new THREE.Color(
				this.params.markerData.color ? this.params.markerData.color : "white"
			).convertGammaToLinear(2.2);
			this.textMesh.material.color = new THREE.Color(
				this.params.markerData.textColor ? this.params.markerData.textColor : "#003E34"
			).convertGammaToLinear(2.2);
		}
		
	}

	SetActiveAppearance() {
		this.isActive = true;
		if (this.params.activeColor) {
			this.planeMesh.material.color = new THREE.Color(
				this.params.activeColor
			).convertGammaToLinear(2.2);
		}

		if (this.params.activeTextColor)
			this.textMesh.material.color = new THREE.Color(
				this.params.activeTextColor
			).convertGammaToLinear(2.2);
	}

	ResetMarkerAppearance() {
		this.isActive = false;

		this.planeMesh.material.color = new THREE.Color(this.params.color).convertGammaToLinear(
			2.2
		);

		this.textMesh.material.color = new THREE.Color(this.params.textColor).convertGammaToLinear(
			2.2
		);
	}

	static ResetAllMarkers() {
		markersThisType.map((m) => m.ResetMarkerAppearance());
	}
}

export default OccupierMarker;
