import React from "react";
import { G } from "../globals";

export const amenityPopups = {
	borrowBikes: {
		img: "amenity/borrow-bikes-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Borrow Bikes</strong>
				</h3>
				<div className="intro-para">
					The perfect way to escape the office: grab a bike and go for a lunchtime ride
					around the park. It couldn’t be easier.
				</div>
				<div className="copy">
					There are a number of bikes available for occupiers to borrow during the working
					day. Simply stop by and pick one up at the Park Management Centre.
				</div>
			</div>
		),
	},
	walkingRoutes: {
		img: "amenity/walking-routes-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Walking Routes</strong>
				</h3>
				<div className="intro-para">
					There are plenty of opportunities for walking and running in and around Stockley
					Park. Whether its the lakeside paths in Stockley Park or taking in the local
					Country Park and Canal Towpath.
				</div>
			</div>
		),
	},
	lakeside: {
		img: "amenity/lake-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>The Lake</strong>
				</h3>
				<div className="intro-para">
					Stockley Park enjoys a reputation as one of Europe's premier parks due to its
					150 acres of parkland, lakes and landscape areas.
				</div>
			</div>
		),
	},
	fitnessBootcamp: {
		img: "amenity/bootcamp-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Fitness Bootcamp</strong>
				</h3>
				<div className="intro-para">
					Stockley Fitness offers professional, focused and fun outdoor bootcamp style
					training to help you achieve your exercise goals and feel great!
				</div>
				<div className="copy">
					Bootcamp sessions At The Squareabout take place on: <br />
					Monday 17:15 – 18:15 <br />
					Wednesday 17:15 – 18:15 <br />
					Friday 12:20 -13:10
				</div>
			</div>
		),
	},
	foodMarket: {
		img: "amenity/food-market-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Food Market</strong>
				</h3>
				<div className="intro-para">
					A diverse range of delicacies offered every lunch time from Street Food Vendors
					between 11:30 - 14:00 including fresh Caribbean, Indian and Spanish food.
				</div>
			</div>
		),
	},
	runningClub: {
		img: "amenity/running-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Running Club</strong>
				</h3>
				<div className="intro-para">
					The group meets twice a week - every Wednesday and Thursday lunchtime, with
					three course lengths available. All skill levels are welcome, from complete
					beginners to keen enthusiasts and, best of all, it’s completely free!
				</div>
			</div>
		),
	},
	arena: {
		img: "amenity/arena-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>The Arena</strong>
				</h3>
				<div className="intro-para">
					Neighbouring to Stockley Park, The Arena provides amenities such as Nuffield
					Health Club, Costa Coffee, Greggs, Subway and WH Smith.
				</div>
				<div className="copy">
					<span className="copy-internal-link" onClick={() => G.GoToState("ARENA")}>
						Learn more
					</span>
				</div>
			</div>
		),
	},
	trimTrail: {
		img: "amenity/trimtrail-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Trim Trail</strong>
				</h3>
				<div className="intro-para">
					If you fancy a lunchtime exercise session, the equipment is ready for you to use
					with no booking required and includes:
				</div>
				<div className="copy">
					Tire Trail
					<br />
					Ability Bench
					<br />
					Swinging Steps
					<br />
					Pull Up Station
					<br />
					Cross Beams
					<br />
					Traverse Wall
					<br />
					Balance Beams
				</div>
			</div>
		),
	},
	golfClub: {
		img: "amenity/golfclub-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Stockley Park Golf Club</strong>
				</h3>
				<div className="intro-para">
					The course is a 6,625 yard par 72 tree lined course set in 240 acres of rolling
					countryside. The modern Clubhouse overlooks the 18th green and lake, whilst 30
					miles of drainage under the ground allows excellent playing conditions all year
					round with no need for winter tees, temporary greens or trolleys bans.
				</div>
			</div>
		),
	},
	meetingPods: {
		img: "amenity/meetingpod-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Meeting Pod</strong>
				</h3>
				<div className="intro-para">
					The perfect meeting room away from the office, set in a picturesque area
					overlooking one of the Park’s lakes.
				</div>
				<div className="copy">
					Space enough for up to four people, the meeting pod has its own dedicated WiFi
					and is free and easy for Stockley Park occupiers to book.
				</div>
			</div>
		),
	},
	thesetcafe: {
		img: "amenity/set-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>The Set Cafe</strong>
				</h3>
				<div className="intro-para">
					Situated at the Squareabout, the Set Cafe is a convenient stop for coffee and
					pastries.
				</div>
			</div>
		),
	},
	towpath: {
		img: "amenity/tow-path-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">
					<strong>Canal Towpath</strong>
				</h3>
				<div className="intro-para">
				The newly resurfaced canal towpath offers a swift and easy cycle – or a 20 min walk – to Hayes & Harlington town centre and Crossrail Station.
				</div>
			</div>
		),
	},
};

export const connectionPopups = {
	easitShuttle: {
		content: (
			<div>
				<h3 className="popup-title">easitShuttle</h3>
				<div className="intro-para">
					Catch this free shuttle to destinations around Stockley Park and{" "}
					<span className="text-national-rail-icon">    </span>Hayes &amp; Harlington.
				</div>
				<div className="copy">
					From there you can board trains that take you to Heathrow, London Paddington and
					Reading.
					<br />
					<br />
					<span className="copy-internal-link">
						For more information visit the easitStockley page
					</span>
				</div>
			</div>
		),
	},
	busServices: {
		content: (
			<div>
				<h3 className="popup-title">Bus Services</h3>
				<div className="intro-para">
					There is a bus going by Union Stockley Park every few minutes. From lunchtime
					excursions to catching that flight – it can’t be easier to get around.
				</div>
				<ul className="bus-popup-schedule-list w-list-unstyled">
					<li className="bus-popup-schedule-item">
						<div className="bus-popup-schedule-icon national-rail"></div>
						<div className="bus-popup-schedule-text">
							Hayes &amp; Harlington
							<br />6 minutes
						</div>
					</li>
					<li className="bus-popup-schedule-item">
						<div className="bus-popup-schedule-icon national-rail"></div>
						<div className="bus-popup-schedule-text">
							West Drayton
							<br />6 minutes
						</div>
					</li>
					<li className="bus-popup-schedule-item">
						<div className="bus-popup-schedule-icon tube-icon"></div>
						<div className="bus-popup-schedule-text">
							Uxbridge
							<br />
							20 minutes
						</div>
					</li>
					<li className="bus-popup-schedule-item">
						<div className="bus-popup-schedule-icon heathrow-icon"></div>
						<div className="bus-popup-schedule-text">
							Heathrow
							<br />9 minutes
						</div>
					</li>
				</ul>
			</div>
		),
	},
	parking: {
		img: "parking-popup-img.jpg",
		content: (
			<div>
				<h3 className="popup-title">Parking</h3>
				<div className="intro-para">
					Union provides accommodation for 216 car parking spaces right at your doorstep,
					a ratio of 1:xxx sq ft
				</div>
			</div>
		),
	},
	heathrowShuttle: {
		content: (
			<div>
				<h3 className="popup-title">Heathrow Shuttle</h3>
				<div className="intro-para">
					The A10 Heathrow services provides a convenient link between Heathrow and
					Stockley Park, taking 10 minutes to travel each way, as well as providing an
					additional connection with <span className="text-tube-icon">    </span> Uxbridge
					Tube Station.
				</div>
			</div>
		),
	},
};
