import React, { Component } from "react";
import Compass from "./Compass";
import SlideNav from "./SlideNav";
import Burger from "./Burger";
import Loading from "./Loading";
import Instructions from "./Instructions";
import EasitStockleyPage from "./easitStockleyPage";
import Specification from "./Specification";
import OccupierPage from "./OccupiersPage";
import Gallery from "./Gallery";
import ImageViewer from "./ImageViewer";
import AmenityPopup from "./AmenityPopup";
import SchedulePopup from "./SchedulePopup";
import VirtualViewer from "./VirtualViewer";
import RibbonNav from "./RibbonNav";
import VideoPlayer from "./VideoPlayer";
import { G } from "../globals";

import entranceImg from "../images/gallery/entrance-cgi.jpg";
import terraceImg from "../images/gallery/terrace-cgi.jpg";
import atriumImg from "../images/gallery/still-cgi.jpg";
import { CONFIG } from "../config";

class UI extends Component {
	constructor() {
		super();

		this.ShowEasitPage = this.ShowEasitPage.bind(this);
		this.ShowOccupierPage = this.ShowOccupierPage.bind(this);
		this.ShowSpecification = this.ShowSpecification.bind(this);
		this.ShowGallery = this.ShowGallery.bind(this);
		this.ShowImageViewer = this.ShowImageViewer.bind(this);
		this.ShowAmenityPopup = this.ShowAmenityPopup.bind(this);
		this.ShowSchedulePopup = this.ShowSchedulePopup.bind(this);
		this.ResetOverlays = this.ResetOverlays.bind(this);
		this.CloseAllOverlayPages = this.CloseAllOverlayPages.bind(this);
		G.ShowAmenityPopup = this.ShowAmenityPopup;
		G.ShowImageViewer = this.ShowImageViewer;
		G.ShowGallery = this.ShowGallery;

		this.state = {
			showEasitPage: false,
			showSpecification: false,
			showGallery: false,
			showAmenityPopup: false,
			showSchedulePopup: false,
			showOccupierPage: false,
			showImageViewer: false,
			imageToShow: null,
		};

		this.popupData = null;
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.ResetOverlays);
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.ResetOverlays);
	}

	ShowEasitPage(bool = false) {
		G.forceDisableInteraction = bool;
		this.setState({ showEasitPage: bool });
	}

	ShowSpecification(bool = false) {
		G.forceDisableInteraction = bool;
		this.setState({ showSpecification: bool });
	}

	ShowOccupierPage(bool = false) {
		G.forceDisableInteraction = bool;
		this.setState({ showOccupierPage: bool });
	}

	ShowGallery(bool = false) {
		G.forceDisableInteraction = bool;
		this.setState({ showGallery: bool });
	}

	ShowImageViewer(bool = false, data = null) {
		G.forceDisableInteraction = bool;
		this.setState({ showImageViewer: bool, imageToShow: data });
	}

	ShowAmenityPopup(bool = false, data = null) {
		let delay = 0;

		if (bool && this.state.showAmenityPopup) {
			//IF SELECTING ANOTHER POPUP WHILE ONE IS ALREADY OPEN
			delay = 400;
			this.FirePopupEvent();
			setTimeout(() => {
				this.setState({ showAmenityPopup: false });
			}, 200);
		}

		if (!bool) {
			this.FirePopupEvent();
			delay = 200;
		}

		setTimeout(() => {
			this.popupData = data;
			this.setState({ showAmenityPopup: bool });
		}, delay);
	}

	ShowSchedulePopup(bool = false) {
		let delay = 0;

		if (bool && this.state.showSchedulePopup) {
			//IF SELECTING ANOTHER POPUP WHILE ONE IS ALREADY OPEN
			delay = 400;
			this.FirePopupEvent();
			setTimeout(() => {
				this.setState({ showSchedulePopup: false });
			}, 200);
		}

		if (!bool) {
			this.FirePopupEvent();
			delay = 200;
		}

		setTimeout(() => {
			this.setState({ showSchedulePopup: bool });
		}, delay);
	}

	CloseAllOverlayPages() {
		G.forceDisableInteraction = false;
		this.setState({
			showEasitPage: false,
			showSpecification: false,
			showGallery: false,
			showAmenityPopup: false,
			showSchedulePopup: false,
			showOccupierPage: false,
			showImageViewer: false,
		});
	}

	ResetOverlays() {
		this.ShowAmenityPopup(false);
	}

	FirePopupEvent() {
		const eventDelegate = new CustomEvent("popupWillClose", { bubbles: true });
		document.dispatchEvent(eventDelegate);
	}

	render() {
		const CurrentView =
			this.props.currentState && this.props.currentState.ui
				? this.props.currentState.ui
				: this.props.prevState && this.prevState.ui
				? this.prevState.ui
				: null;

		return (
			<div ref={(ref) => (this.mainDiv = ref)} className="ui">
				{CurrentView ? (
					<CurrentView
						currentState={this.props.currentState}
						GoToState={this.props.GoToState}
						NextState={this.props.NextState}
						ShowGallery={this.ShowGallery}
						ShowEasitPage={this.ShowEasitPage}
						ShowAmenityPopup={this.ShowAmenityPopup}
						ShowSpecification={this.ShowSpecification}
						ShowOccupierPage={this.ShowOccupierPage}
						ShowSchedulePopup={this.ShowSchedulePopup}
						ShowImageViewer={this.ShowImageViewer}
					/>
				) : null}
				<div className="top-right-wrapper">
					<Compass currentState={this.props.currentState} />
				</div>
				<SlideNav
					currentState={this.props.currentState}
					NextState={this.props.NextState}
					GetNextState={this.props.GetNextState}
				/>
				<Burger
					currentState={this.props.currentState}
					GoToState={this.props.GoToState}
					ShowGallery={this.ShowGallery}
					ShowSchedulePopup={this.ShowSchedulePopup}
					ShowEasitPage={this.ShowEasitPage}
					ShowSpecification={this.ShowSpecification}
					ShowOccupierPage={this.ShowOccupierPage}
					CloseAllOverlayPages={this.CloseAllOverlayPages}
				/>
				<RibbonNav
					GoToState={this.props.GoToState}
					currentState={this.props.currentState}
				/>
				<Instructions currentState={this.props.currentState} />
				{!CONFIG.d_disableViz ? <Loading /> : null}
				<div className="overlays">
					{this.state.showOccupierPage ? (
						<OccupierPage
							currentState={this.props.currentState}
							GoToState={this.props.GoToState}
							ShowOccupierPage={this.ShowOccupierPage}
						/>
					) : null}
					{this.state.showEasitPage ? (
						<EasitStockleyPage
							currentState={this.props.currentState}
							GoToState={this.props.GoToState}
							ShowEasitPage={this.ShowEasitPage}
						/>
					) : null}
					{this.state.showSpecification ? (
						<Specification
							currentState={this.props.currentState}
							GoToState={this.props.GoToState}
							ShowSpecification={this.ShowSpecification}
						/>
					) : null}
					{this.state.showGallery ? (
						<Gallery
							currentState={this.props.currentState}
							content={this.state.showGallery}
							GoToState={this.props.GoToState}
							ShowGallery={this.ShowGallery}
						/>
					) : null}
					{this.state.showImageViewer ? (
						<ImageViewer
							currentState={this.props.currentState}
							content={this.state.imageToShow}
							GoToState={this.props.GoToState}
							ShowImageViewer={this.ShowImageViewer}
						/>
					) : null}
				</div>

				<div className="popup-overlay-wrapper">
					{this.state.showAmenityPopup ? (
						<AmenityPopup
							ShowPopup={this.ShowAmenityPopup}
							ShowOccupierPage={this.ShowOccupierPage}
							data={this.popupData}
						/>
					) : null}
				</div>
				<div className="popup-overlay-wrapper">
					{this.state.showSchedulePopup ? (
						<SchedulePopup
							currentState={this.props.currentState}
							ShowPopup={this.ShowSchedulePopup}
							GoToState={this.props.GoToState}
						/>
					) : null}
				</div>
				<div className="popup-overlay-wrapper">
					<VirtualViewer
						currentState={this.props.currentState}
						previousState={this.props.previousState}
						GoToState={this.props.GoToState}
					/>
				</div>
			</div>
		);
	}
}

export default UI;
