import React, { Component } from "react";
import Player from "@vimeo/player";

class VideoPlayer extends Component {
	constructor() {
		super();

		this.state = {
			show: true,
		};
	}

	componentDidMount() {
		this.player = new Player("video", {
			url: "https://vimeo.com/432879201/05c3145f13",
			controls: false,
			muted: true,
			autoplay: true,
		});
		this.player.on("loaded", () => {
			const iframe = document.querySelector(".video iframe");
			iframe.classList.add("video-full");
			this.player.play();
		});

		this.player.on("ended", () => {
			this.Close();
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show !== this.props.show) {
			if (this.props.show) this.Play();
			else this.Stop();
		}
	}

	Play() {
		this.videoPlay.classList.add("fade");
		setTimeout(() => {
			this.player.play();
		}, 400);
	}

	Close() {
		//outro anim...then...
		this.OutroAnim();

		setTimeout(() => {
			this.props.ToggleVideo(false);
		}, 800);
	}

	OutroAnim() {
		this.videoWipe.classList.remove("fade");
	}

	render() {
		return (
			<div className={`video-player-wrapper clickable`}>
				<div className="video-skip-button" onClick={() => this.Close()}></div>
				<div id="video" className="video"></div>
				<div ref={(ref) => (this.videoPlay = ref)} className="video-play hidden">
					<div className="video-play-button" onClick={() => this.Play()}></div>
				</div>
				<div ref={(ref) => (this.videoWipe = ref)} className="video-wipe fade"></div>
			</div>
		);
	}
}

export default VideoPlayer;
