import React, { Component } from "react";
import FloorPlanViewer from "./FloorPlanViewer";
import anime from "animejs";
import { floorsData, GetFloor } from "../data/floorsData";
import { G } from "../globals";
import U from "../utils";
import "simplebar";
import "simplebar/dist/simplebar.css";

class FloorView extends Component {
  constructor(props) {
    super();

    this.OutroAnim = this.OutroAnim.bind(this);
    this.IntroAnim = this.IntroAnim.bind(this);
    this.ScrollFade = this.ScrollFade.bind(this);
    this.ToggleFloorPlanViewer = this.ToggleFloorPlanViewer.bind(this);

    this.state = {
      showFloorPlanViewer: false,
    };

    this.currentFloor = GetFloor(props.currentState.name);
  }

  componentDidMount() {
    document.addEventListener("stateWillChange", this.OutroAnim);
    document.addEventListener("camDone", this.IntroAnim);
    document.addEventListener("scroll", this.ScrollFade);
  }

  componentWillUnmount() {
    document.removeEventListener("stateWillChange", this.OutroAnim);
    document.removeEventListener("camDone", this.IntroAnim);
    document.removeEventListener("scroll", this.ScrollFade);
  }

  OutroAnim(e) {
    //QUICK AND DIRTY TO PREVENT OUTRO ON OTHER PAGES
    if (e.detail.newState.name === "SECOND") return;
    if (e.detail.newState.name === "FIRST") return;
    if (e.detail.newState.name === "GROUND") return;

    const timeline = new anime.timeline();
    timeline.add({
      targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
      opacity: 0,
      easing: "easeInOutQuad",
      duration: 400,
    });
  }

  IntroAnim() {
    const timeline = new anime.timeline();
    timeline.add({
      targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
      opacity: 1,
      easing: "easeInOutQuad",
      duration: 800,
    });
  }

  NextPlan() {
    const currentIndex = this.currentFloor.index;
    if (currentIndex === 2) return;
    const newPlan = floorsData[currentIndex + 1];
    this.props.GoToState(newPlan.state);
  }

  PrevPlan() {
    const currentIndex = this.currentFloor.index;
    if (currentIndex === 0) return;
    const newPlan = floorsData[currentIndex - 1];
    this.props.GoToState(newPlan.state);
  }

  ToggleFloorPlanViewer(bool = false) {
    G.forceDisableInteraction = bool;
    this.setState({ showFloorPlanViewer: bool });
  }

  ScrollFade(e) {
    if (!this.scrollFadeDiv) return;
    if (window.scrollY > 50) this.scrollFadeDiv.classList.add("scrolled-fade");
    else this.scrollFadeDiv.classList.remove("scrolled-fade");
  }

  render() {
    this.currentFloor = GetFloor(this.props.currentState.name);

    return (
      <div ref={(ref) => (this.slideDiv = ref)} className="slide">
        <div ref={(ref) => (this.content = ref)} className="content auto-fade">
          <div className="content-side">
            <div className="section-graphic-text the-detail" />
            <div className="section-graphic detail-graphic" />
          </div>
          <div className="content-main extra-wide">
            <div className="section-header-wrapper clickable">
              <div
                className="return-button"
                onClick={() => this.props.GoToState("BUILDING")}
              >
                Back
              </div>
            </div>
            <div className="section-mid-wrapper">
              <ul className="floor-selector w-list-unstyled clickable">
                <li
                  className={`floor-selector-arrow arrow-up ${
                    this.state.showFloorPlanViewer
                      ? "fade"
                      : this.currentFloor.index === 0
                      ? "disable-fade"
                      : ""
                  }`}
                  onClick={() => this.PrevPlan()}
                ></li>
                <li className="floor-selector-title">
                  <h2 className="floor-view-title">
                    {this.currentFloor.name} Floor
                    <br />
                    <span
                      className={`bold ${
                        !this.currentFloor.sqft ? "fade" : ""
                      }`}
                    >
                      {U.FormatNumber(this.currentFloor.sqft)} sq ft
                    </span>
                  </h2>
                </li>
                <li
                  className={`floor-selector-arrow arrow-down ${
                    this.state.showFloorPlanViewer
                      ? "fade"
                      : this.currentFloor.index === 2
                      ? "disable-fade"
                      : ""
                  }`}
                  onClick={() => this.NextPlan()}
                ></li>
              </ul>
              <ul
                role="list"
                className={`schedule-sub-list clickable ground-stats-list w-list-unstyled ${
                  this.props.currentState.name === "GROUND" ? "" : "hidden"
                }`}
              >
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Let to Heidelberg Graphic Equipment Limited
                  </div>
                </li>
              </ul>
              <ul
                role="list"
                className={`schedule-sub-list clickable ground-stats-list w-list-unstyled ${
                  this.props.currentState.name === "SECOND" ? "" : "hidden"
                }`}
              >
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Let to Keyence
                  </div>
                </li>
              </ul>
              <ul
                role="list"
                className={`schedule-sub-list clickable ground-stats-list w-list-unstyled ${
                  this.props.currentState.name === "FIRST" ? "" : "hidden"
                }`}
              >
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Part let to Teledyne
                  </div>
                </li>
                <li className="schedule-sub-item">
                  <div className="schedule-sub-text sub-label">
                    Suites available from 7,900 – 17,075 sq ft
                  </div>
                </li>
              </ul>
            </div>
            <div className="section-bottom-wrapper clickable">
              <ul className="section-nav-list w-list-unstyled">
                <li
                  className="section-nav-item"
                  onClick={() => this.props.ShowSchedulePopup(true)}
                >
                  View area schedule
                </li>
                <li
                  className="section-nav-item"
                  onClick={() => this.ToggleFloorPlanViewer(true)}
                >
                  View floor plan
                </li>
              </ul>
              <div className="notice">All areas are IPMS3 approximate.</div>
            </div>
          </div>
        </div>
        <div className="usp-logo-wrapper">
          <div className="usp-logo"></div>
        </div>
        <div className="content-fade auto-fade" />
        <div className="content-top-fade auto-fade" />
        <div className="content-right-fade auto-fade" />
        <div className="content-bottom-fade auto-fade" />
        <div className="overlays">
          <FloorPlanViewer
            currentFloor={this.currentFloor}
            isActive={this.state.showFloorPlanViewer}
            CloseFunc={this.ToggleFloorPlanViewer}
          />
        </div>
      </div>
    );
  }
}

export default FloorView;
