import React, { Component } from "react";

class OccupiersPage extends Component {
	componentDidMount() {
		setTimeout(() => {
			this.IntroAnim();
		}, 100);
	}

	IntroAnim() {
		this.overlayBg.classList.remove("fade");
		setTimeout(() => {
			this.headerDiv.classList.remove("fade");
			setTimeout(() => {
				this.contentDiv.classList.remove("fade");
			}, 400);
		}, 400);
	}

	OutroAnim() {
		this.headerDiv.classList.add("fade");
		this.contentDiv.classList.add("fade");
		setTimeout(() => {
			this.overlayBg.classList.add("fade");
		}, 600);
	}

	CloseHandler() {
		this.OutroAnim();

		setTimeout(() => {
			this.props.ShowOccupierPage(false);
		}, 1000);
	}

	render() {
		return (
			<div className="content-overlay clickable always-top occupiers-overlay">
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="content-overlay-bg community-bg anim-all-200 fade"
				></div>
				<div className="content-overlay-container ">
					<div className="content-side"></div>
					<div
						ref={(ref) => (this.headerDiv = ref)}
						className="content-main anim-all-400 fade"
					>
						<div className="section-header-wrapper">
							<div className="section-header occupier-header"></div>
						</div>
					</div>
					<div className="overlay-close-icon" onClick={() => this.CloseHandler()}></div>
					<div
						ref={(ref) => (this.contentDiv = ref)}
						className="content-page align-with-header anim-all-400 fade"
					>
						<ul className="occupiers-list w-list-unstyled">
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-alexion"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Alexion Pharma UK </div>
									<div className="occupier-address">3 Furzeground Way</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-apple"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Apple</div>
									<div className="occupier-address">2 Furzeground Way</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-canon"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Canon</div>
									<div className="occupier-address">3 The Square</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-cargologic"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Cargo Logic Management</div>
									<div className="occupier-address">3 Roundwood Avenue</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-celgene"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Celgene</div>
									<div className="occupier-address">1 Longwalk Road</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-coats"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Coats</div>
									<div className="occupier-address">4 Longwalk Road</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-gilead"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Gilead Sciences Europe</div>
									<div className="occupier-address">3 Roundwood Avenue</div>
								</div>
							</li>
							<li className="occupiers-item hidden">
								<div className="occupier-logo-area logo-fiserv"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Fiserv</div>
									<div className="occupier-address">7 Roundwood Avenue</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-hasbro"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Hasbro</div>
									<div className="occupier-address">4 The Square</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-havi"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Havi Global Solutions</div>
									<div className="occupier-address">3 Furzeground Way</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-hikvision"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">HIKVISION</div>
									<div className="occupier-address">4 The Square</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-img"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">IMG Productions</div>
									<div className="occupier-address">4 &amp; 5 Longwalk Road</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-stryker"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Stryker</div>
									<div className="occupier-address">1 Roundwood Avenue</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-kuehne"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Kuehne + Nagel</div>
									<div className="occupier-address">1 Roundwood Avenue</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-lucozade"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Lucozade Ribena Suntory</div>
									<div className="occupier-address">2 Longwalk Road</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-ms"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Marks and Spencer</div>
									<div className="occupier-address">3 Longwalk Road</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-mitsubishi"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">
										Mitsubishi Heavy Industries <br />
										Air-Conditioning Europe
									</div>
									<div className="occupier-address">5 The Square</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-msc"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">MSC Cruise Management </div>
									<div className="occupier-address">5 Roundwood Avenue</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-nobel"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Nobel Biocare</div>
									<div className="occupier-address">3 Furzeground Way</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-orega"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Orega</div>
									<div className="occupier-address">4 Longwalk Road</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-regus"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Regus</div>
									<div className="occupier-address">6 &amp; 9 The Square</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-samsonite"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Samsonite</div>
									<div className="occupier-address">1 The Square</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-sharp"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Sharp Electronics</div>
									<div className="occupier-address">4 Furzeground Way</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-spaces"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Spaces</div>
									<div className="occupier-address">
										The Bower, 4 Roundwood Avenue
									</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-toshiba"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Toshiba</div>
									<div className="occupier-address">3 Furzeground Way</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-verifone"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">Verifone</div>
									<div className="occupier-address">3 Roundwood Avenue</div>
								</div>
							</li>
							<li className="occupiers-item">
								<div className="occupier-logo-area logo-worldvision"></div>
								<div className="occupier-text-area">
									<div className="occupier-title">World Vision International</div>
									<div className="occupier-address">1 Roundwood Avenue</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default OccupiersPage;
