import React, { Component } from "react";

class OverviewGalleryThumb extends Component {
	constructor() {
		super();

		this.currentSlide = 0;
	}

	componentDidMount() {
		this.JumpToSlide(0);
		this.Animate();
	}

	Animate() {
		if (!this.galleryImg) return;
		this.NavigateSlides("next");
		setTimeout(() => {
			if (!this.galleryImg) return;
			this.Animate();
		}, 5000);
	}

	JumpToSlide(index) {
		this.galleryImg.classList.add("fade-out");

		setTimeout(() => {
			if (!this.galleryImg) return;
			this.galleryImg.style.backgroundImage = `url(${this.props.content[index].img})`;
			this.galleryImg.classList.remove("fade-out");
		}, 700);
	}

	NavigateSlides(_dir) {
		let dir = 0;

		if (_dir === "next") {
			dir = +1;
		} else if (_dir === "prev") {
			dir = -1;
		}
		this.currentSlide += dir;

		if (this.currentSlide > this.props.content.length - 1) {
			this.currentSlide = 0;
		} else if (this.currentSlide < 0) {
			this.currentSlide = this.props.content.length - 1;
		}

		this.JumpToSlide(this.currentSlide);
	}

	render() {
		return (
			<div
				className="overview-gallery-thumb clickable"
				onClick={() => {this.props.ShowGallery(this.props.content)}}
			>
				<div className="overview-gallery-thumb-zoom">VIEW ALL PHOTOS</div>
				<div
					ref={(ref) => (this.galleryImg = ref)}
					className="overview-gallery-thumb-img"
				></div>
			</div>
		);
	}
}

export default OverviewGalleryThumb;
