import React, { Component } from "react";

class Instruction extends Component {
	render() {
		return (
			<li
				key={this.props.key}
				className={`instruction instruction-hide ${this.props.show ? "" : "hidden"}`}
			>
				<div className={`instruction-icon ${this.props.icon}`}></div>
				<div className="instruction-divider"></div>
				<div className="instruction-text">{this.props.children}</div>
			</li>
		);
	}
}

export default Instruction;
