import React, { Component } from "react";
import anime from "animejs";

class Specification extends Component {
	componentDidMount() {
		setTimeout(() => {
			this.IntroAnim();
		}, 100);
	}

	IntroAnim() {
		this.overlayBg.classList.remove("fade");
		setTimeout(() => {
			this.headerDiv.classList.remove("fade");
			setTimeout(() => {
				this.contentDiv.classList.remove("fade");
			}, 400);
		}, 400);
	}

	OutroAnim() {
		this.headerDiv.classList.add("fade");
		this.contentDiv.classList.add("fade");
		setTimeout(() => {
			this.overlayBg.classList.add("fade");
		}, 600);
	}

	CloseHandler() {
		this.OutroAnim();

		setTimeout(() => {
			this.props.ShowSpecification(false);
		}, 1000);
	}

	render() {
		return (
			<div className="content-overlay clickable always-top specification-overlay">
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="content-overlay-bg anim-all-200 fade"
				/>
				<div className="content-overlay-container">
					<div className="content-side" />
					<div
						ref={(ref) => (this.headerDiv = ref)}
						className="content-main anim-all-400 fade"
					>
						<div className="section-header-wrapper">
							<div className="section-header specification-title" />
						</div>
						<div className="section-bottom-wrapper">
							<div className="sub-link">
								<span className="underline">
									<a href="./dl/USP_FullSpecification.pdf" target="_blank">
										Download full specification
									</a>
								</span>
								<br />
								<span className="download-size">(PDF, 0.07 mb)</span>
							</div>
						</div>
					</div>
					<div className="overlay-close-icon" onClick={() => this.CloseHandler()} />
					<div
						ref={(ref) => (this.contentDiv = ref)}
						className="content-page align-with-header anim-all-400 fade"
					>
						<div className="section-header-wrapper">
							<div className="copy clickable">
								Union Stockley Park delivers market-leading sustainability
								credentials to meet today’s discerning occupier requirements. The
								property also delivers flexible, high-level operational performance,
								through the extent of the refurbishment and services provision, as
								provided here.
							</div>
						</div>
						<ul className="spec-list w-list-unstyled">
							<li className="spec-item">
								<div className="spec-text bold">Occupational Density </div>
								<div className="spec-text">
									One person per 8 sqm of net lettable office area for design of
									building services.
								</div>
								<div className="spec-text">
									For WC accommodation, 1 per 8 sqm on a 60:60 ratio.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">Floor to Ceiling Dimensions</div>
								<div className="spec-text">
									Ground floor reception to soffit:
									<br />
									<span className="bold">4735mm approx </span>
								</div>
								<div className="spec-text">
									Ground floor office areas
									<br />
									<span className="bold">3390mm approx </span>
								</div>
								<div className="spec-text">
									First and second floor office areas
									<br />
									<span className="bold">2800mm approx</span>
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">Planning Grid </div>
								<div className="spec-text">
									Generally at 9m centres, providing for a 1500mm planning grid.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">Showers & Bike Storage</div>
								<div className="spec-text">
									3x male, 3x female and 1x DDA shower.
								</div>
								<div className="spec-text">80 bike spaces.</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">Air Conditioning</div>
								<div className="spec-text">
									Comfort cooled and heated by variable refrigerant flow (VRF)
									system FCUs.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Services Subdivision</strong>
								</div>
								<div className="spec-text">
									Designed for independent occupation on a floor-by-floor basis,
									with the capacity for further tenant sub-division per floor.
								</div>
								<div className="spec-text">
									FCUs can be controlled in groups for up to 9 tenancies and a
									landlord’s group, via the BMS.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">Raised Access Floors</div>
								<div className="spec-text">
									A clear void depth of 150mm minimum.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">Floor Loadings</div>
								<div className="spec-text">
									Office use:
									<br />
									<span className="bold">2.5k N/sq m</span>
								</div>
								<div className="spec-text">
									Partitions: <br />‍<span className="bold"> 1.0 k N/sq m</span>
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Ceiling Finishes</strong>
								</div>
								<div className="spec-text">
									SAS System fully demountable 600 x 600 perforated metal tegular
									tiles. Painted plasterboard margins around cores, atrium and
									cladding.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Passenger Lifts</strong>
								</div>
								<div className="spec-text">
									2 no. 12 person Lifts are provided with new lift cars, new lift
									frame structure and new M&E equipment within the existing
									shafts. New internal car finishes and floor finishes to match
									ground floor.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Feature Staircase </strong>
								</div>
								<div className="spec-text">
									Feature Stair: New open feature stair within the atrium serving
									Ground to Second Floors comprising exposed painted steel finish
									stringer, porcelain tile steps, glass balustrade, and oak
									handrail.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Internal Doors</strong>
								</div>
								<div className="spec-text">
									Solid soft wood frame with vision panels and self-closers. Solid
									wood architraves to timber doors.
								</div>
								<div className="spec-text">
									Satin stainless steel ironmongery including full height pull
									handles to office doors at ground floor.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Glazing </strong>
								</div>
								<div className="spec-text">
									Newly installed, double-glazed sealed units designed for Part L
									compliance.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Electrical Design Loads </strong>
								</div>
								<div className="spec-text">
									Office Lighting
									<br />
									<span className="bold">8W/m²</span>
								</div>
								<div className="spec-text">
									Office Small Power
									<br />
									<span className="bold">25 W/m²</span> <br />‍
									<span className="sub">
										(based upon 1 person per 8 m² with an allowance for fan-coil
										units @ 2W/m²)
									</span>
								</div>
								<div className="spec-text">
									Additional Spare Capacity
									<br />
									<span className="bold">75 W/m²</span>
								</div>
								<div className="spec-text">
									Future provision for connection of generator to support the
									entire building if required.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Security Systems</strong>
								</div>
								<div className="spec-text">
									Access control and intercom to reception sliding doors. Wireways
									to be provided for access control systems in the office.
									External building and car park CCTV installation.{" "}
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>BREEAM Rating</strong>
								</div>
								<div className="spec-text">
									Targeting BREEAM rating of ‘Very Good’ or better.{" "}
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>EPC Rating</strong>
								</div>
								<div className="spec-text">A(18) rated.</div>
							</li>

							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Lighting </strong>
								</div>
								<div className="spec-text">
									High frequency DALI control gear with LED lamp sources in office
									areas, WCs and atrium.
								</div>
								<div className="spec-text">
									Lighting control system with occupancy movement sensors in
									office and WCs plus automatic daylight dimming in perimeter
									office areas.{" "}
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Electric Car Charging </strong>
								</div>
								<div className="spec-text">
									10 car charging points have been provided within the 225 spaces
									in the car park.
								</div>
							</li>
							<li className="spec-item">
								<div className="spec-text bold">
									<strong>Wired Score</strong>
								</div>
								<div className="spec-text">Wired Score Gold</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default Specification;
