import React, { Component } from "react";

class RibbonNav extends Component {
	constructor() {
		super();

		this.SetSectionActive = this.SetSectionActive.bind(this);
		this.ResetRibbon = this.ResetRibbon.bind(this);
	}

	componentDidMount() {
		this.SetWidths();

		this.SetSectionActive({ detail: { newState: this.props.currentState } });
		document.addEventListener("stateWillChange", this.SetSectionActive);
	}

	componentDidUpdate() {
		// document.addEventListener("stateWillChange", this.ResetRibbon);
		
	}

	SetWidths() {
		const items = [...this.ribbonDiv.querySelectorAll(".ribbon-item")];
		items.map((item) => {
			const contentItem = item.querySelector(".ribbon-line");
			item.classList.add("ribbon-item-expanded");
			contentItem.classList.add("ribbon-line-expanded");
			item.setAttribute("w", item.clientWidth + 4);
			// item.classList.add(`${item.clientWidth}px`);
		});
	}

	SetSectionActive(e) {
		this.ResetRibbon();

		const ribbonGroup = e.detail.newState.ribbonGroup ? e.detail.newState.ribbonGroup : "";

		const selectedItem = this.ribbonDiv.querySelector(`.${ribbonGroup.toLowerCase()}-ribbon`);

		if (selectedItem === -1 || !selectedItem) {
			this.ribbonDiv.classList.remove("ribbon-nav-show");
			return;
		}

		this.ribbonDiv.classList.add("ribbon-nav-show");

		const selectedItemRibbon = selectedItem.querySelector(".ribbon-line");
		selectedItem.classList.add("ribbon-item-expanded");
		selectedItem.style.width = `${selectedItem.getAttribute("w")}px`;
		selectedItemRibbon.classList.add("ribbon-line-expanded");
	}

	ResetRibbon(e) {
		const items = [...this.ribbonDiv.querySelectorAll(".ribbon-item")];
		items.map((item) => {
			item.classList.remove("ribbon-item-expanded");
			item.querySelector(".ribbon-line").classList.remove("ribbon-line-expanded");
			item.style.width = "";
		});
	}

	GetGraphic() {
		let result = "hidden";
		if (!this.props.currentState.ribbonGroup) return result;
		switch (this.props.currentState.ribbonGroup) {
			case "overview":
			case "building":
			case "contact":
				result = "detail-graphic";
				break;
			case "location":
			case "connections":
				result = "community-graphic";
				break;
			default:
				break;
		}

		return result;
	}

	render() {
		return (
			<div
				ref={(ref) => (this.ribbonDiv = ref)}
				className="ribbon-nav w-list-unstyled auto-fade"
			>
				<div
					className="ribbon-item overview-ribbon"
					onClick={() => this.props.GoToState("OVERVIEW")}
				>
					<div className="ribbon-line">Overview</div>
				</div>
				<div
					className="ribbon-item location-ribbon"
					onClick={() => this.props.GoToState("LOCATION")}
				>
					<div className="ribbon-line">Stockley Park</div>
				</div>
				<div
					className="ribbon-item connections-ribbon"
					onClick={() => this.props.GoToState("CONNECTIONS")}
				>
					<div className="ribbon-line">Connections</div>
				</div>
				<div
					className="ribbon-item building-ribbon"
					onClick={() => this.props.GoToState("BUILDING")}
				>
					<div className="ribbon-line">The Building</div>
				</div>
				<div
					className="ribbon-item contact-ribbon"
					onClick={() => this.props.GoToState("CONTACT")}
				>
					<div className="ribbon-line">Contact</div>
				</div>
				<div className={`section-graphic desktop-nav-graphic ${this.GetGraphic()}`} />
			</div>
		);
	}
}

export default RibbonNav;
