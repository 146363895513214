//stores completion imagery for convenience, so we don't pollute individual components with a myriad of imports

import showGal1 from "../images/gallery/completion/show-gal-1.jpg";
import showGal2 from "../images/gallery/completion/show-gal-2.jpg";
import showGal3 from "../images/gallery/completion/show-gal-3.jpg";
import showGal4 from "../images/gallery/completion/show-gal-4.jpg";
import showGal5 from "../images/gallery/completion/show-gal-5.jpg";
import showGal6 from "../images/gallery/completion/show-gal-6.jpg";

import entrance1 from "../images/gallery/completion/entrance-1.jpg";

import atrium2 from "../images/gallery/completion/atrium-2.jpg";
import atrium3 from "../images/gallery/completion/atrium-3.jpg";
import atrium4 from "../images/gallery/completion/atrium-4.jpg";
import atrium5 from "../images/gallery/completion/atrium-5.jpg";
import atrium6 from "../images/gallery/completion/atrium-6.jpg";
import atrium7 from "../images/gallery/completion/atrium-7.jpg";

import coffee1 from "../images/gallery/completion/coffee-1.jpg";
import coffee2 from "../images/gallery/completion/coffee-2.jpg";

import office1 from "../images/gallery/completion/office-1.jpg";
import office2 from "../images/gallery/completion/office-2.jpg";
import office3 from "../images/gallery/completion/office-3.jpg";
import office4 from "../images/gallery/completion/office-4.jpg";

import reception1 from "../images/gallery/completion/reception-1.jpg";
import reception2 from "../images/gallery/completion/reception-2.jpg";
import reception3 from "../images/gallery/completion/reception-3.jpg";
import reception4 from "../images/gallery/completion/reception-4.jpg";
import reception5 from "../images/gallery/completion/reception-5.jpg";
import reception6 from "../images/gallery/completion/reception-6.jpg";

import showers1 from "../images/gallery/completion/showers-1.jpg";
import showers2 from "../images/gallery/completion/showers-2.jpg";

import terrace1 from "../images/gallery/completion/terrace-1.jpg";
import terrace3 from "../images/gallery/completion/terrace-3.jpg";
import terrace4 from "../images/gallery/completion/terrace-4.jpg";
import terrace5 from "../images/gallery/completion/terrace-5.jpg";
import terrace6 from "../images/gallery/completion/terrace-6.jpg";
import terrace7 from "../images/gallery/completion/terrace-7.jpg";
import terrace8 from "../images/gallery/completion/terrace-8.jpg";

export const imageData = {
	showGal1: {
		img: showGal1,
		txt: "",
	},
	showGal2: {
		img: showGal2,
		txt: "",
	},
	showGal3: {
		img: showGal3,
		txt: "",
	},
	showGal4: {
		img: showGal4,
		txt: "",
	},
	showGal5: {
		img: showGal5,
		txt: "",
	},
	showGal6: {
		img: showGal6,
		txt: "",
	},
	entrance1: {
		img: entrance1,
		txt: "",
	},
	office1: {
		img: office1,
		txt: "",
	},
	office2: {
		img: office2,
		txt: "",
	},
	office3: {
		img: office3,
		txt: "",
	},
	office4: {
		img: office4,
		txt: "",
	},
	atrium2: {
		img: atrium2,
		txt: "",
	},
	atrium3: {
		img: atrium3,
		txt: "",
	},
	atrium4: {
		img: atrium4,
		txt: "",
	},
	atrium5: {
		img: atrium5,
		txt: "",
	},
	atrium6: {
		img: atrium6,
		txt: "",
	},
	atrium7: {
		img: atrium7,
		txt: "",
	},
	coffee1: {
		img: coffee1,
		txt: "",
	},
	coffee2: {
		img: coffee2,
		txt: "",
	},
	reception1: {
		img: reception1,
		txt: "",
	},
	reception2: {
		img: reception2,
		txt: "",
	},
	reception3: {
		img: reception3,
		txt: "",
	},
	reception4: {
		img: reception4,
		txt: "",
	},
	reception5: {
		img: reception5,
		txt: "",
	},
	reception6: {
		img: reception6,
		txt: "",
	},
	showers1: {
		img: showers1,
		txt: "",
	},
	showers2: {
		img: showers2,
		txt: "",
	},
	terrace1: {
		img: terrace1,
		txt: "",
	},
	terrace3: {
		img: terrace3,
		txt: "",
	},
	terrace4: {
		img: terrace4,
		txt: "",
	},
	terrace5: {
		img: terrace5,
		txt: "",
	},
	terrace6: {
		img: terrace6,
		txt: "",
	},
	terrace7: {
		img: terrace7,
		txt: "",
	},
	terrace8: {
		img: terrace8,
		txt: "",
	},
};

//these are pre-made galleries, mixing images above so we don't have to load duplicates

export const entranceGalleryData = [
	imageData.showGal1,
	imageData.entrance1,
	imageData.showGal2,
	imageData.reception1,
];

export const atriumGalleryData = [
	imageData.showGal3,
	imageData.reception4,
	imageData.coffee2,
	imageData.atrium7,
	imageData.atrium2,
	imageData.atrium3,
	imageData.atrium4,
	imageData.atrium5,
	imageData.atrium6,
	imageData.showGal6,
];

export const firOfficeGalleryData = [imageData.office4];

export const secOfficeGalleryData = [imageData.office1, imageData.office2, imageData.office3];

export const coffeeGalleryData = [
	imageData.atrium7,
	imageData.coffee2,
	imageData.coffee1,
	imageData.showGal4,
];

export const receptionGalleryData = [
	imageData.reception1,
	imageData.reception2,
	imageData.reception3,
	imageData.reception4,
	imageData.reception5,
	imageData.reception6,
];

export const showerGalleryData = [imageData.showers1, imageData.showers2];

export const terraceGalleryData = [
	imageData.terrace1,
	imageData.showGal5,
	imageData.terrace3,
	imageData.terrace4,
	imageData.terrace5,
	imageData.terrace6,
	imageData.terrace7,
	imageData.terrace8,
];
