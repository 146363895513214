import React, { Component } from "react";
import anime from "animejs";
import "simplebar";
import "simplebar/dist/simplebar.css";
import ExpandableConnectionsTab from "./ExpandableConnectionsTab";
import U from "../utils";

class ConnectionsMap extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
		this.IntroAnim = this.IntroAnim.bind(this);
		this.ScrollFade = this.ScrollFade.bind(this);
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.OutroAnim);
		document.addEventListener("camDone", this.IntroAnim);
		document.addEventListener("scroll", this.ScrollFade);
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.OutroAnim);
		document.removeEventListener("camDone", this.IntroAnim);
		document.removeEventListener("scroll", this.ScrollFade);
	}

	OutroAnim(e) {
		if (e.detail.newState.name === "LOCATION") return;

		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".fade")],
			opacity: 0,
			easing: "easeInOutQuad",
			duration: 400,
		});
	}

	IntroAnim() {
		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".fade")],
			opacity: U.GetResponsiveMode() === "desktop" ? 1 : 0.9,
			easing: "easeInOutQuad",
			duration: 800,
		});
	}

	ScrollFade(e) {
		if (!this.scrollFadeDiv) return;
		if (window.scrollY > 50) this.scrollFadeDiv.classList.add("scrolled-fade");
		else this.scrollFadeDiv.classList.remove("scrolled-fade");
	}

	render() {
		return (
			<div ref={(ref) => (this.slideDiv = ref)} className="slide">
				<div className="content fade">
					<div className="content-side">
						<div className="section-graphic-text the-community"></div>
						<div className="section-graphic community-graphic"></div>
					</div>
					<div className="content-main simplebar-padding" data-simplebar>
						<div className="section-header-wrapper clickable">
							<div
								className="return-button"
								onClick={() => this.props.GoToState("CONNECTIONS")}
							>
								Back
							</div>
							<div className="intro-para">
								Stockley Park is easily accessible from many locations in London and
								the Thames Valley by a variety of modes of transport, providing for
								every commuter’s specific needs. The forthcoming arrival of
								Crossrail services to West Drayton and Hayes & Harlington will
								extend easy reach from UNION to many locations across London, as
								well as providing swifter services to Reading, Maidenhead and Slough
								to the west.{" "}
							</div>
							<ExpandableConnectionsTab label={"Bus"}>
								<li className="connection-category-item">
									<div className="category-icon rail-icon"></div>
									<div className="category-text">
										Hayes &amp; Harlington
										<span className="copy-sub"> (3.3 mi)</span>
										<br />
										<strong>6 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-icon rail-icon"></div>
									<div className="category-text">
										West Drayton<span className="copy-sub"> (1.5 mi)</span>
										<br />
										<strong>6 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-icon tube-icon"></div>
									<div className="category-text">
										Uxbridge<span className="copy-sub"> (3.8 mi)</span>
										<br />‍<strong>20 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-icon plane-icon"></div>
									<div className="category-text">
										Heathrow<span className="copy-sub"> (3.4 mi)</span>
										<br />‍<strong>9 minutes</strong>
									</div>
								</li>
							</ExpandableConnectionsTab>
							<ExpandableConnectionsTab label={"Rail"}>
								<li className="connection-category-item">
									<div className="category-icon rail-icon"></div>
									<div className="category-text">
										London
										<br />
										<strong>36 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-icon rail-icon"></div>
									<div className="category-text">
										Slough
										<br />‍<strong>33 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-icon rail-icon"></div>
									<div className="category-text">
										Reading
										<br />
										<strong>56 minutes</strong>
									</div>
								</li>
							</ExpandableConnectionsTab>
							<ExpandableConnectionsTab label={"Car"}>
								<li className="connection-category-item">
									<div className="category-text">
										Heathrow <span className="copy-sub"> (2.4 mi)</span>
										<br />
										<strong>5 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-text">
										M4 <span className="copy-sub"> (1.3 mi)</span>
										<br />
										<strong>3 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-text">
										M25 <span className="copy-sub"> (3.6 mi)</span>
										<br />
										<strong>6 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-text">
										Hayes &amp; Harlington{" "}
										<span className="copy-sub"> (3.3 mi)</span>
										<br />
										<strong>11 minutes</strong>
									</div>
								</li>
								<li className="connection-category-item">
									<div className="category-text">
										Uxbridge <span className="copy-sub"> (3.9 mi)</span>
										<br />
										<strong>11 minutes</strong>
									</div>
								</li>
							</ExpandableConnectionsTab>
						</div>
					</div>
				</div>
				<div className="usp-logo-wrapper">
					<div className="usp-logo"></div>
				</div>
				<div className="content-fade fade"></div>
				<div
					ref={(ref) => (this.scrollFadeDiv = ref)}
					className="content-scroll-fade"
				></div>
				<div className="content-top-fade fade"></div>
				<div className="content-right-fade fade"></div>
				<div className="content-bottom-fade fade"></div>
			</div>
		);
	}
}

export default ConnectionsMap;
