import Home from "../components/Home";
import Intro from "../components/Intro";
import Overview from "../components/Overview";
import Building from "../components/Building";
import Location from "../components/Location";
import Connections from "../components/Connections";
import ConnectionsMap from "../components/ConnectionsMap";
import Contact from "../components/Contact";
import FloorView from "../components/FloorView";
import Arena from "../components/Arena";

export const STATE = {
	INIT: {
		ui: null,
		camData: {
			camera: {
				pos: { x: -23.1, y: 3.1, z: -6.3 },
				duration: 200,
				fov: 30,
			},
			target: {
				pos: { x: 0.4, y: 0, z: -0.78 },
				duration: 200,
			},
			controls: [],
		},
	},
	DEBUG: {
		ui: null,
		camData: {
			camera: {
				pos: { x: 26.83, y: 16.254, z: 9.844 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: -7.741, y: 0, z: -2.678 },
				duration: 3000,
			},
			controls: ["all"],
			interactable: true,
		},
		markers: [
			// "amenityMarkers",
			// "connectionsMapMarker",
			// "connectionsHighlightMarkers",
			// "connectionsMapMarkers",
			// "busMarkers",
			// "uspMarkerSmall",
			// 'parkMarkers',
			// 'heathrowShuttleMarkers',
			// 'm4Markers',
			// 'connectionM4Marker'
			// "occBuildingMarkers",
			"exploreFloorImageMarkers",
			// "connectionsDirectionMarkers",
			"groundFloorImageMarkers",
			// "fitnessEventsMarkers",
			// "starEventsMarkers",
			// "foodEventsMarkers",
		],
	},
	INTRO: {
		ui: Intro,
		options: {
			showCompass: false,
		},
		camData: {
			camera: {
				pos: { x: -23.1, y: 3.1, z: -6.3 },
				duration: 200,
				fov: 30,
			},
			target: {
				pos: { x: 0.4, y: 0, z: -0.78 },
				duration: 200,
			},
			controls: ["none"],
		},
	},
	OVERVIEW: {
		ui: Overview,
		labelName: "Overview",
		ribbonGroup: "overview",
		options: {
			showCompass: true,
		},
		camData: {
			camera: {
				pos: { x: -32.986, y: 51.329, z: -57.629 },
				duration: 3000,
				fov: 30,
				filmOffset: -2,
				portraitMode: {
					filmOffset: 0,
					fov: 40,
					hOffset: {
						tablet: 200,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: 0, y: 0, z: -0.6 },
				duration: 3000,
			},
			controls: ["orbit", "gyro"],
			interactable: true,
		},
		markers: ["buildingMarkers"],
	},
	ARENA: {
		ui: Arena,
		labelName: "Arena",
		ribbonGroup: "location",
		options: {
			showCompass: true,
		},
		camData: {
			camera: {
				pos: { x: -12.011, y: 38.287, z: -85.021 },
				duration: 3000,
				fov: 30,
				filmOffset: -3,
				portraitMode: {
					filmOffset: 0,
					fov: 30,
					hOffset: {
						tablet: 200,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: -26.46, y: 0, z: -117.031 },
				duration: 3000,
			},
			controls: ["gyro"],
		},
	},
	BUILDING: {
		ui: Building,
		labelName: "Building",
		ribbonGroup: "building",
		options: {
			showCompass: true,
		},
		camData: {
			camera: {
				pos: { x: -21.401, y: 14.691, z: -14.124 },
				duration: 3000,
				fov: 20,
				filmOffset: -4,
				portraitMode: {
					filmOffset: 0,
					hOffset: {
						tablet: 200,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: 0, y: 0, z: -0.6 },
				duration: 3000,
			},
			controls: ["orbit", "gyro"],
			interactable: true,
		},
		markers: ["exploreFloorImageMarkers"],
	},
	SECOND: {
		ui: FloorView,
		ribbonGroup: "building",
		options: {
			showCompass: true,
			virtualSceneOrigin: true,
		},
		camData: {
			camera: {
				pos: { x: -14.3, y: 22, z: 14 },
				duration: 3000,
				fov: 30,
				filmOffset: -4,
				portraitMode: {
					filmOffset: 0,
					hOffset: {
						tablet: 300,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: 0.348, y: 0, z: -0.371 },
				duration: 3000,
			},
			controls: ["orbit", "gyro", "zoom", "pan"],
			interactable: true,
			panSpeed: 0.25,
			rotateSpeed: 0.1,
		},
		markers: ["secondFloorImageMarkers"],
	},
	FIRST: {
		ui: FloorView,
		ribbonGroup: "building",
		options: {
			showCompass: true,
			virtualSceneOrigin: true,
		},
		camData: {
			camera: {
				pos: { x: -14.3, y: 20, z: 14 },
				duration: 3000,
				fov: 30,
				filmOffset: -4,
				portraitMode: {
					filmOffset: 0,
					hOffset: {
						tablet: 300,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: 0.348, y: 0, z: -0.371 },
				duration: 3000,
			},
			controls: ["orbit", "gyro", "zoom", "pan"],
			interactable: true,
			panSpeed: 0.25,
			rotateSpeed: 0.1,
		},
		markers: ["firstFloorImageMarkers"],
	},
	GROUND: {
		ui: FloorView,
		ribbonGroup: "building",
		options: {
			showCompass: true,
			virtualSceneOrigin: true,
		},
		camData: {
			camera: {
				pos: { x: -14.3, y: 18, z: 14 },
				duration: 3000,
				fov: 30,
				filmOffset: -4,
				portraitMode: {
					filmOffset: 0,
					hOffset: {
						tablet: 300,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: 0.348, y: 0, z: -0.371 },
				duration: 3000,
			},
			controls: ["orbit", "gyro", "zoom", "pan"],
			interactable: true,
			panSpeed: 0.25,
			rotateSpeed: 0.1,
			limit: {
				min: { x: -20, z: -20 },
				max: { x: 20, z: 20 },
			},
		},
		markers: ["groundFloorImageMarkers"],
	},
	LOCATION: {
		ui: Location,
		labelName: "Stockley Park",
		ribbonGroup: "location",
		options: {
			showCompass: true,
		},
		camData: {
			camera: {
				pos: { x: 125.2, y: 154.5, z: 69.6 },
				duration: 3000,
				fov: 30,

				portraitMode: {
					filmOffset: 0,
					fov: 40,
					hOffset: {
						tablet: 200,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: -0.8, y: 0, z: -5.1 },
				duration: 3000,
			},
			controls: ["pan", "gyro"],
			interactable: true,
			limit: {
				min: { x: -218, z: -200 },
				max: { x: 230, z: 110 },
			},
		},
		markers: ["amenityMarkers", "occBuildingMarkers"],
	},
	CONNECTIONS: {
		labelName: "Connections",
		ui: Connections,
		ribbonGroup: "connections",
		options: {
			showCompass: true,
		},
		camData: {
			camera: {
				pos: { x: -51.5, y: 299.8, z: -74 },
				duration: 3000,
				fov: 30,
				portraitMode: {
					filmOffset: 0,
					fov: 40,
					hOffset: {
						tablet: 350,
						mobile: 300,
					},
				},
			},
			target: {
				pos: { x: -49.2, y: 0, z: -84 },
				duration: 3000,
			},
			controls: ["pan", "gyro"],
			interactable: true,
			limit: {
				min: { x: -218, z: -200 },
				max: { x: 230, z: 110 },
			},
		},
		markers: [
			"busMarkers",
			"m4Markers",
			"connectionsHighlightMarkers",
			"connectionM4Marker",
			"uspMarkerSmall",
			"connectionsDirectionMarkers",
		],
	},
	CONNECTIONS_MAP: {
		ribbonGroup: "connections",
		ui: ConnectionsMap,
		options: {
			showCompass: true,
		},
		camData: {
			camera: {
				pos: { x: -244.08, y: 2800, z: 720.629 },
				duration: 3000,
				fov: 30,
				portraitMode: {
					filmOffset: 0,
					fov: 40,
					hOffset: {
						tablet: 200,
						mobile: 150,
					},
				},
			},
			target: {
				pos: { x: -246.482, y: 0, z: 208.261 },
				duration: 3000,
			},
			controls: ["pan", "gyro"],
			limit: {
				min: { x: -1070, z: -1100 },
				max: { x: 1700, z: 1700 },
			},
		},
		markers: [
			"connectionsMapMarker",
			"connectionsRoadMapMarkers",
			"connectionsMapMarkers",
			"connectionsMapDirectionMarkers",
			"uspMarker",
		],
	},
	CONTACT: {
		labelName: "Contact",
		ui: Contact,
		ribbonGroup: "contact",
		options: {
			showCompass: false,
		},
	},
	VIRTUAL_OUTSIDE: {
		options: {
			showCompass: false,
			virtualScene: "exterior",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: -1.805, y: 0.862, z: -14.281 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: 0, y: 0.9, z: 0 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_RECEPTION: {
		options: {
			showCompass: false,
			virtualScene: "reception",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: -2.91, y: 0.587, z: -5.275 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: -0.558, y: 0.5, z: -5.598 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_TERRACE: {
		options: {
			showCompass: false,
			virtualScene: "terrace",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: -5.673, y: 0.64, z: -2.368 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: -6.511, y: 0.4, z: -7.182 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_BREAKOUT: {
		options: {
			showCompass: false,
			virtualScene: "breakout",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: -2.985, y: 0.5, z: -3.091 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: -1.107, y: 0.5, z: -2.303 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_ATRIUM: {
		options: {
			showCompass: false,
			virtualScene: "atrium",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: -0.201, y: 0.45, z: -2.516 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: 0.123, y: 0.6, z: -0.879 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_GROUNDOFFICE: {
		options: {
			showCompass: false,
			virtualScene: "office",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: -6.308, y: 0.541, z: 4.999 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: 3.373, y: 0.5, z: 0.733 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_SHOWERS: {
		options: {
			showCompass: false,
			virtualScene: "showers",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: 3.719, y: 0.389, z: -1.42 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: 13.627, y: 0.5, z: -2.718 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_FIRSTFLOOR: {
		options: {
			showCompass: false,
			virtualScene: "firstfloor",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: 0.492, y: 1.536, z: 2.889 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: 0.231, y: 1.5, z: 0.967 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
	VIRTUAL_SECONDFLOOR: {
		options: {
			showCompass: false,
			virtualScene: "secondfloor",
			minDistance: 0,
		},
		camData: {
			camera: {
				pos: { x: -1.08, y: 2.681, z: 1.379 },
				duration: 3000,
				fov: 20,
			},
			target: {
				pos: { x: 0.046, y: 2.6, z: 0.256 },
				duration: 3000,
			},
			// controls: ["pan", "gyro"],
			interactable: false,
		},
		markers: [],
	},
};

export const stateOrder = [
	STATE.INTRO,
	STATE.OVERVIEW,
	STATE.LOCATION,
	STATE.CONNECTIONS,
	STATE.BUILDING,
	STATE.CONTACT,
];

//assign names and indices to state
const stateKeys = Object.keys(STATE);
stateKeys.map((k, i) => {
	STATE[k].name = k;

	return null;
});

stateOrder.map((s, i) => {
	s.index = i;
	return null;
});

export function GetStateArray() {
	return Object.values(STATE);
}
