import React, { Component } from "react";

class Gallery extends Component {
	constructor(props) {
		super();

		this.gallery = {
			images: props.content,
			galleryImageContainer: null,
			thumbWrappper: null,
			currentSlide: 0,
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.IntroAnim();
		}, 100);

		this.gallery.galleryImageContainer = this.galleryImage;
		this.gallery.thumbWrapper = this.galleryThumbsContainer;
		this.JumpToSlide(0);
	}

	JumpToSlide(index) {
		this.galleryImage.classList.add("fade-out");

		setTimeout(() => {
			this.gallery.galleryImageContainer.style.backgroundImage = `url(${this.gallery.images[index].img})`;
			this.galleryCaption.innerHTML = this.gallery.images[index].caption;
			this.gallery.images[index].caption === ""
				? this.galleryCaption.classList.add("hidden")
				: this.galleryCaption.classList.remove("hidden");
			this.galleryImage.classList.remove("fade-out");
		}, 400);

		const thumbs = Array.from(this.galleryThumbsContainer.children);
		thumbs.map((thumb) => thumb.classList.remove("thumb-active"));
		thumbs[index].classList.add("thumb-active");
	}

	NavigateSlides(_dir) {
		let dir = 0;

		if (_dir === "next") {
			dir = +1;
		} else if (_dir === "prev") {
			dir = -1;
		}
		this.gallery.currentSlide += dir;

		if (this.gallery.currentSlide > this.gallery.images.length - 1) {
			this.gallery.currentSlide = 0;
		} else if (this.gallery.currentSlide < 0) {
			this.gallery.currentSlide = this.gallery.images.length - 1;
		}

		this.JumpToSlide(this.gallery.currentSlide);
	}

	IntroAnim() {
		this.overlayBg.classList.remove("fade");
		setTimeout(() => {
			this.galleryDiv.classList.remove("fade");
		}, 400);
	}

	OutroAnim() {
		this.galleryDiv.classList.add("fade");
		this.overlayBg.classList.add("fade");
	}

	CloseHandler() {
		this.OutroAnim();

		setTimeout(() => {
			this.props.ShowGallery(false);
		}, 1000);
	}

	render() {
		return (
			<div className="content-overlay clickable always-top">
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="content-overlay-bg anim-all-200 fade"
				></div>
				<div className="content-overlay-container">
					<div
						className="overlay-close-icon close-button-bg"
						onClick={() => this.CloseHandler()}
					></div>
					<div
						ref={(ref) => (this.galleryDiv = ref)}
						className="gallery anim-all-400 fade"
					>
						<div className="gallery-controls">
							<div
								ref={(ref) => (this.galleryCaption = ref)}
								className="gallery-title"
							>
								Atrium
							</div>
							<div className="gallery-control-buttons">
								<div
									ref={(ref) => (this.galleryThumbsContainer = ref)}
									className="gallery-thumb-container"
								>
									{this.gallery.images.map((images, i) => {
										return (
											<div
												key={i}
												className="thumb clickable"
												onClick={() => this.JumpToSlide(i)}
											></div>
										);
									})}
								</div>
								<div
									className="gallery-control prev-button"
									onClick={() => this.NavigateSlides("prev")}
								></div>
								<div
									className="gallery-control next-button"
									onClick={() => this.NavigateSlides("next")}
								></div>
							</div>
						</div>
						<div
							ref={(ref) => (this.galleryImage = ref)}
							className="gallery-image"
						></div>
					</div>
				</div>
			</div>
		);
	}
}

export default Gallery;
