import * as THREE from 'three';
import Entity from './Entity';
import { G } from '../globals';
import U from '../utils';
import Loop from './Loop';

class Lights extends Entity {
	constructor() {
		super();

		const ambLight = new THREE.AmbientLight('white', 0.5);
		const dirLight = new THREE.DirectionalLight('white', 0.5);

		const lightTarget = new THREE.Mesh(
			new THREE.BoxGeometry(1, 1, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color('#ffffff').convertGammaToLinear(2.2)
			})
		);

		const lightPosMarker = new THREE.Mesh(
			new THREE.BoxGeometry(1, 1, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color('red').convertGammaToLinear(2.2)
			})
		);

		this.Instantiate(ambLight, 'ambLight');
		this.Instantiate(dirLight, 'dirLight');
		// this.Instantiate(lightTarget, 'lightTarget');
		// this.Instantiate(lightPosMarker, 'lightPosMarker');

		dirLight.target = lightTarget;
		dirLight.position.set(23, 20, 1);
	}
}

export default Lights;
