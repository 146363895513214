import React, { Component } from "react";
import anime from "animejs";
import { amenityPopups } from "../data/popupData";
import Check from "./Check";
import { G } from "../globals";
import U from "../utils";

class Location extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
		this.IntroAnim = this.IntroAnim.bind(this);
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.OutroAnim);
		document.addEventListener("camDone", this.IntroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.OutroAnim);
		document.removeEventListener("camDone", this.IntroAnim);
	}

	OutroAnim(e) {
		if (e.detail.newState.name === "LOCATION") return;

		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".fade")],
			opacity: 0,
			easing: "easeInOutQuad",
			duration: 400,
		});
	}

	IntroAnim() {
		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".fade")],
			opacity: U.GetResponsiveMode() === "desktop" ? 1 : 0.9,
			easing: "easeInOutQuad",
			duration: 800,
		});
	}

	render() {
		return (
			<div ref={(ref) => (this.slideDiv = ref)} className="slide">
				<div className="content mob-clickable fade">
					<div className="content-side">
						<div className="section-graphic-text the-community" />
						<div className="section-graphic community-graphic" />
					</div>
					<div className="content-main">
						<div className="section-header-wrapper">
							<div className="section-header in-good-company" />
							<div className="copy clickable">
								Stockley Park boasts excellent transport links, award winning
								accommodation and a host of amenities to help you create and enjoy
								the best environment for your business.
							</div>
							<Check
								text={"Show Occupiers"}
								defaultState={true}
								onDisableFunc={() => G.ToggleMarkers("occBuildingMarkers", false)}
								onEnableFunc={() => G.ToggleMarkers("occBuildingMarkers", true)}
							/>
							<Check
								text={"Show Amenities"}
								defaultState={true}
								onDisableFunc={() => G.ToggleMarkers("amenityMarkers", false)}
								onEnableFunc={() => G.ToggleMarkers("amenityMarkers", true)}
							/>
							{/*<Check
								text={"Show Events & Activities"}
								defaultState={false}
								onDisableFunc={() =>
									G.ToggleMarkers(
										[
											"fitnessEventsMarkers",
											"starEventsMarkers",
											"foodEventsMarkers",
										],
										false
									)
								}
								onEnableFunc={() =>
									G.ToggleMarkers(
										[
											"fitnessEventsMarkers",
											"starEventsMarkers",
											"foodEventsMarkers",
										],
										true
									)
								}
							/>*/}
						</div>
						<div className="section-bottom-wrapper clickable">
							<ul className="section-nav-list w-list-unstyled">
								<li
									className="section-nav-item"
									onClick={() => this.props.ShowOccupierPage(true)}
								>
									Stockley Park occupiers
								</li>
								<li
									className="section-nav-item"
									onClick={() => this.props.GoToState("ARENA")}
								>
									The Arena
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="usp-logo-wrapper">
					<div className="usp-logo"></div>
				</div>
				<div className="content-fade fade" />
				<div className="content-top-fade fade" />
				<div className="content-right-fade fade" />
				<div className="content-bottom-fade fade" />
			</div>
		);
	}
}

export default Location;
