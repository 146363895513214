import React, { Component } from "react";
import anime from "animejs";

class Arena extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
		this.IntroAnim = this.IntroAnim.bind(this);
		this.ScrollFade = this.ScrollFade.bind(this);
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.OutroAnim);
		document.addEventListener("camDone", this.IntroAnim);
		document.addEventListener("scroll", this.ScrollFade);
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.OutroAnim);
		document.removeEventListener("camDone", this.IntroAnim);
		document.removeEventListener("scroll", this.ScrollFade);
	}

	OutroAnim(e) {
		if (e.detail.newState.name === "ARENA") return;

		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
			opacity: 0,
			easing: "easeInOutQuad",
			duration: 400,
		});
	}

	IntroAnim() {
		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
			opacity: 1,
			easing: "easeInOutQuad",
			duration: 800,
		});
	}

	ScrollFade(e) {
		if (!this.scrollFadeDiv) return;
		if (window.scrollY > 50) this.scrollFadeDiv.classList.add("scrolled-fade");
		else this.scrollFadeDiv.classList.remove("scrolled-fade");
	}

	render() {
		return (
			<div ref={(ref) => (this.slideDiv = ref)} className="slide">
				<div ref={(ref) => (this.content = ref)} className="content auto-fade">
					<div className="content-side">
						<div className="section-graphic-text the-community"></div>
						<div className="section-graphic community-graphic"></div>
					</div>
					<div className="content-main">
						<div className="section-header-wrapper clickable">
							<div
								className="return-button"
								onClick={() => this.props.GoToState("LOCATION")}
							>
								Back
							</div>
							<div
								className="arena-thumb clickable"
								onClick={() => this.props.ShowImageViewer(true, "arena-hotel.jpg")}
							></div>
							<h3 className="popup-title">
								<strong>The Arena</strong>
							</h3>
							<div className="intro-para">
								Grab a coffee, some food or an afternoon swim<strong>:</strong> the
								Arena is the centre of it all. In addition, the Arena will shortly
								provide a brand new 81-room Travelodge Hotel.
							</div>
							<div className="amenity-list-container">
								<div className="amenity-list-wrapper">
									<div className="amenity-icon amenity-food-icon"></div>
									<ul role="list" className="amenity-list w-list-unstyled">
										<li className="amenity-list-item">Costa Coffee</li>
										<li className="amenity-list-item">Greggs</li>
										<li className="amenity-list-item">Subway</li>
									</ul>
								</div>
								<div className="amenity-list-wrapper">
									<div className="amenity-icon amenity-fitness-icon"></div>
									<ul role="list" className="amenity-list w-list-unstyled">
										<li className="amenity-list-item">Nuffield Health</li>
										<li className="amenity-list-item">Golf Club</li>
									</ul>
								</div>
								<div className="amenity-list-wrapper full-width">
									<div className="amenity-icon amenity-shop-icon"></div>
									<ul role="list" className="amenity-list w-list-unstyled">
										<li className="amenity-list-item">WH Smith</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-fade auto-fade"></div>
				<div
					ref={(ref) => (this.scrollFadeDiv = ref)}
					className="content-scroll-fade"
				></div>
				<div className="content-top-fade auto-fade"></div>
				<div className="burger-button-wrapper">
					<div className="burger-icon"></div>
				</div>
				<div className="top-right-wrapper">
					<div className="compass"></div>
				</div>
				<div className="content-right-fade auto-fade"></div>
				<div className="content-bottom-fade auto-fade"></div>
			</div>
		);
	}
}

export default Arena;
