import React, { Component } from "react";
import Debug from "./Debug";
import Viz from "./viz/Viz";
import UI from "./components/UI";
import VideoPlayer from "./components/VideoPlayer";
import { G } from "./globals";
import { CONFIG } from "./config";
import { STATE, stateOrder } from "./data/states";

class App extends Component {
	constructor() {
		super();

		this.GoToState = this.GoToState.bind(this);
		this.NextState = this.NextState.bind(this);
		this.GetNextState = this.GetNextState.bind(this);
		this.PrevState = this.PrevState.bind(this);

		this.Init = this.Init.bind(this);
		G.GoToState = this.GoToState;

		this.state = {
			current: STATE.INIT,
			currentIndex: 0,
			previous: null,
			showVideo: false,
		};

		document.addEventListener("loadDone", this.Init);
	}

	componentDidMount() {
		setTimeout(() => {
			this.Init();
		}, 300);

		this.mainDiv.addEventListener("gesturestart", this.DisableZoomEvents);
	}

	componentDidUpdate() {
		//FIRE OFF EVENT!
		this.FireEvent();
	}

	Init() {
		if (!CONFIG.d_enableDebug) {
			if (window.location.hash !== '') {
				const dest = window.location.hash.slice(1, window.location.hash.length).toUpperCase();
				if (STATE[dest] !== undefined) this.GoToState(dest); else this.GoToState("INTRO");
			} else this.GoToState("INTRO");
		}
		else this.GoToState("DEBUG");
	}

	GoToState(p) {
		if (
			(typeof p === "string" &&
				STATE[p.toUpperCase()] &&
				STATE[p.toUpperCase()].name === this.state.current.name) ||
			(typeof p === "number" && STATE[p] && STATE[p].name === this.state.current.name)
		)
			return;
		return new Promise((resolve, reject) => {
			let newState;

			if (G.inTransition) return;

			if (typeof p === "string") {
				if (!STATE[p.toUpperCase()]) {
					console.error("INVALID STATE ON GOTOSTATE: " + p);
					return;
				} else newState = STATE[p.toUpperCase()];
			} else if (typeof p === "number") {
				if (p > stateOrder.length - 1 || p < 0) {
					console.error("INDEX OUT OF ARRAY ON GOTOSTATE: " + p);
					return;
				} else newState = stateOrder[p];
			}
			G.currentState = newState;
			G.previousState = this.state.current;
			window.location.hash = `#${newState.name.toLowerCase()}`
			this.FireOutroEvent(newState);


			setTimeout(() => {
				this.setState({
					current: newState,
					currentIndex:
						newState.index !== undefined ? newState.index : this.state.currentIndex,
					previous: this.state.current,
				});
				resolve(newState);
			}, 1000);
		});
	}

	NextState() {
		if (this.state.current.indx + 1 > stateOrder.length - 1) return;
		this.GoToState(this.state.currentIndex + 1);
	}

	PrevState() {
		if (this.state.current.index - 1 < 0) return;
		this.GoToState(this.state.currentIndex - 1);
	}

	GetNextState() {
		if (this.state.current.indx + 1 > stateOrder.length - 1) return null;
		return stateOrder[this.state.currentIndex + 1];
	}

	FireEvent() {
		// console.log('EVENT!');
		this.eventDelegate = new CustomEvent("stateChange", {
			bubbles: true,
			detail: { state: this.state.current },
		});
		if (CONFIG.d_disableViz) {
			this.debugCamEvent = new CustomEvent("camDone", {
				bubbles: true,
				detail: { state: this.state.current },
			});
			document.dispatchEvent(this.debugCamEvent);
		}
		document.dispatchEvent(this.eventDelegate);
	}

	FireOutroEvent(newState) {
		this.eventDelegate = new CustomEvent("stateWillChange", {
			bubbles: true,
			detail: { newState: newState },
		});
		document.dispatchEvent(this.eventDelegate);
	}

	DisableZoomEvents(e) {
		e.preventDefault();
	}

	render() {
		return (
			<div ref={(ref) => (this.mainDiv = ref)} className="app">
				<div className="interface no-event">
					{CONFIG.d_enableDebug ? (
						<Debug currentState={this.state.current} GoToState={this.GoToState} />
					) : null}
					{CONFIG.d_enableUI ? (
						<UI
							currentState={this.state.current}
							previousState={this.state.previous}
							GoToState={this.GoToState}
							NextState={this.NextState}
							GetNextState={this.GetNextState}
						/>
					) : null}
				</div>
				{!CONFIG.d_disableViz ? <Viz /> : <div className="render"></div>}
			</div>
		);
	}
}

export default App;
