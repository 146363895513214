import React from "react";

export const fitnessEventsData = [
	{
		location: { x: -64.913, y: 0, z: -64.26 },
		height: 9,
		data: {
			img: "amenity/runningclub-img.jpg",
			popupType: "event",
			type: "fitness",
			when: "February 23rd",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Hillingdon Half Marathon</strong>
					</h3>
					<div className="intro-para">
						On Sunday 23rd February 2020, starting and finishing at Brunel University,
						it is the perfect warm up for the London Marathon.
					</div>
					<div className="copy">
						You can do either 10K (one lap of the course) or the half marathon (2 laps
						of the course) and support The Hillingdon Hospitals charity while you're at
						it.
					</div>
				</div>
			),
		},
	},
	{
		location: { x: 47.768, y: 0, z: -63.61 },
		height: 9,
		data: {
			img: "amenity/runningclub-img.jpg",
			popupType: "event",
			type: "fitness",
			when: "January 2nd-31st",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Grand Stockley Park Virtual 5K</strong>
					</h3>
					<div className="intro-para">
						This event is open to all Stockley Park occupiers running from the 2nd
						January right through to the 31st January.
					</div>
					<div className="copy">
						You can choose to enter whenever you want, and the entry fee is only £5.
						Should you like to enter, please do visit my Virgin Money Just Giving Page
						“titled as Stockley Virtual 5K” where you can pay your entry fee. For all
						those who complete the 5K will receive a medal as well!
					</div>
				</div>
			),
		},
	},
	{
		location: { x: -41.172, y: 0, z: -122.07 },
		height: 9,
		data: {
			img: "events/trianthlon-img.jpg",
			popupType: "event",
			type: "fitness",
			when: "January 18th",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Super Sprint Triathlon</strong>
					</h3>
					<div className="intro-para">
						Support us in raising funds and awareness the Cystic Fibrosis Trust.
					</div>
					<div className="copy">
						We will be working with Nuffield Health Stockley Park and London Irish to
						put on a Super Sprint Triathlon. We recognise that it’s the taking part that
						counts which is why all participants will be entered into a charity prize
						draw with the chance of winning one months’ FREE membership to Nuffield
						Health and more!
					</div>
				</div>
			),
		},
	},
	{
		location: { x: -29.088, y: 0, z: -104.827 },
		height: 9,
		data: {
			img: "events/pilates-img.jpg",
			popupType: "event",
			type: "fitness",
			when: "January 15th",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Free Pilates</strong>
					</h3>
					<div className="intro-para">
						Nuffield Health Stockley Park is kindly hosting a free Pilates session for
						Stockley Park occupiers.
					</div>
					<div className="copy">
						Pilates is a fantastic technique for strengthening and balancing all the
						muscles of the body to help rehabilitate and prevent injuries as well as
						improve posture and range of movement.
					</div>
				</div>
			),
		},
	},
];

export const starEventsData = [
	{
		location: { x: -32.764, y: 0, z: -122.207 },
		height: 9,
		data: {
			img: "amenity/runningclub-img.jpg",
			popupType: "event",
			type: "star",
			when: "February 12th",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Valentine's Floral Bouquet Workshop</strong>
					</h3>
					<div className="intro-para">
						Join us on Wednesday 12th February and make your own floral bouquet ready to
						give to your loved one (or treat yourself) on Valentine’s Day!
					</div>
				</div>
			),
		},
	},
	{
		location: { x: -26.592, y: 0, z: -19.212 },
		height: 9,
		data: {
			img: "amenity/runningclub-img.jpg",
			popupType: "event",
			type: "star",
			when: "April 30th",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Creativity Webinar</strong>
					</h3>
					<div className="intro-para">
						In this special session of the Brunel Public Webinar Series, Professor Lucy
						Rogers – Inventor With a Sense of Fun – will share her hints and tips for
						creativity, particularly for those who count themselves as ‘not naturally
						creative’.
					</div>
				</div>
			),
		},
	},
	{
		location: { x: -7.138, y: 0, z: -56.471 },
		height: 9,
		data: {
			img: "amenity/photocomp-img.jpg",
			popupType: "event",
			type: "star",
			when: "March 4th",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Photography Competition Finale</strong>
					</h3>
					<div className="intro-para">
						Join us to cast your vote at the grand finale of the Empowered By Her
						photography competition in the reception area of 5 The Square, Stockley
						Park.
					</div>
					<div className="copy">
						Ten finalists have been chosen by a panel of judges. The selected images
						will be printed and displayed during the finale event organised by Stockley
						Park and Canon Europe Ltd.
					</div>
				</div>
			),
		},
	},
	{
		location: { x: -32.43, y: 0, z: -130.845 },
		height: 9,
		data: {
			img: "events/lunchlearn-img.jpg",
			popupType: "event",
			type: "star",
			when: "Monthly",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Lunch & Learn</strong>
					</h3>
					<div className="intro-para">
						Throughout 2020 we will be hosting monthly wellbeing talks with our friends
						at The Health Lodge.
					</div>
					<div className="copy">
						Join us for lunch and learn from Dr Louise Chiasson-Baxtor and her team on
						how making just little changes to your lifestyle can have a big impact
						overall, whether it’s diet, posture, mindfulness or exercise, there are ways
						we can improve our health in a sustainable way.
					</div>
				</div>
			),
		},
	},
];

export const foodEventsData = [
	{
		location: { x: 102.469, y: 0, z: -41.333 },
		height: 9,
		data: {
			img: "events/set-img.jpg",
			popupType: "event",
			type: "food",
			when: "January 16th",
			content: (
				<div>
					<h3 className="popup-title">
						<strong>Grand Reopening of The Set</strong>
					</h3>
					<div className="intro-para">
						It will be a double celebration as new operators, Venci, will also be
						celebrating five years serving coffee at Stockley Park!
					</div>
					<div className="copy">
						We’ll be cutting the ribbon to Venci’s new venture. Enjoy a free pastry with
						your coffee. Coffee based competitions, taking donations for Hillingdon
						Hospitals Charity.
					</div>
				</div>
			),
		},
	},
];
