import * as THREE from "three";
import Entity from "../Entity";
import Loop from "../Loop";
import { G } from "../../globals";
import { CONFIG } from "../../config";
import U from "../../utils";

class FeatureMarker extends Entity {
	constructor(params) {
		super();

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();
		this.markerStaticGroup = new THREE.Group();

		this.params = params;

		this.isActive = false;

		this.scaleRate = 200;

		const textGeo = new THREE.TextGeometry(params.markerData.text, {
			font: params.font,
			size: 1.5,
			height: 0.01,
			curveSegments: 3,
		});

		this.textMesh = new THREE.Mesh(
			textGeo,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.markerData.textColor ? params.markerData.textColor : "#CD7A60"
				).convertGammaToLinear(2.2),
				depthTest: true,
			})
		);

		this.textMesh.renderOrder = 4;

		this.icon = params.image
			? new THREE.Mesh(
					new THREE.PlaneGeometry(
						params.markerData.imageSize.width,
						params.markerData.imageSize.height
					),
					new THREE.MeshBasicMaterial({
						color: new THREE.Color("#ffffff"),
						map: params.image,
						transparent: true,
					})
			  )
			: null;

		let iconOffset = {
			width: params.markerData.imageSize ? params.markerData.imageSize.width : 0,
			height: params.markerData.imageSize ? params.markerData.imageSize.height : 0,
		};

		if (this.icon) {
			this.icon.material.map.encoding = THREE.sRGBEncoding;
			this.icon.material.map.anisotropy = CONFIG.r_anisotropy;
			this.icon.material.map.magFilter = THREE.LinearFilter;
			this.icon.material.map.minFilter = THREE.LinearMipMapLinearFilter;

			this.icon.position.set(iconOffset.width * 0.1, iconOffset.height * 0.3, 0);
			this.markerLabelGroup.add(this.icon);
			this.textMesh.position.set(0 + iconOffset.width, 0, 0);
		}
		this.markerLabelGroup.add(this.textMesh);

		if (!params.markerData.noPin) {
			const pinPoint = new THREE.Mesh(
				new THREE.CylinderGeometry(0.1, 0.1, 10, 3, 1),
				new THREE.MeshBasicMaterial({
					color: new THREE.Color("#003E34").convertGammaToLinear(2.2),
				})
			);
			const pinSphere = new THREE.Mesh(
				new THREE.SphereGeometry(1, 12, 12),
				new THREE.MeshBasicMaterial({
					color: new THREE.Color("#CD7A60").convertGammaToLinear(2.2),
				})
			);

			pinPoint.position.set(0, -6.5, 0);

			this.markerStaticGroup.add(pinPoint);
			this.markerStaticGroup.add(pinSphere);
		}

		this.planeMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(1, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.markerData.color ? params.markerData.color : "white"
				).convertGammaToLinear(2.2),
				depthTest: true,
			})
		);

		this.planeMesh.renderOrder = 3;

		this.textMesh.geometry.computeBoundingBox();

		let centerLabelPos;

		centerLabelPos = {
			x:
				(this.textMesh.geometry.boundingBox.max.x -
					this.textMesh.geometry.boundingBox.min.x) /
					2 +
				iconOffset.width / 2,
			z:
				(this.textMesh.geometry.boundingBox.max.z -
					this.textMesh.geometry.boundingBox.min.z) /
				2,
		};

		const planePadding = { x: 4, y: 2.5 };

		this.planeMesh.scale.set(
			this.textMesh.geometry.boundingBox.max.x + planePadding.x + iconOffset.width,
			this.textMesh.geometry.boundingBox.max.y + planePadding.y,
			this.textMesh.geometry.boundingBox.max.z
		);
		this.planeMesh.position.set(
			centerLabelPos.x,
			this.textMesh.geometry.boundingBox.max.y / 2,
			centerLabelPos.z + -0.1
		);

		this.markerLabelGroup.add(this.planeMesh);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);

		// this.markerLookGroup.add(this.markerLabelGroup);
		this.marker.add(this.markerStaticGroup);
		this.marker.add(this.markerLookGroup);

		if (params.markerData.data) this.enableInteraction(this.planeMesh);

		this.loop = new Loop(() => {
			// this.ScaleWithZoom();
			if (this.marker.visible) this.RotateToFaceCamera();
		}).start();

		setTimeout(() => {
			this.markerLabelGroup.position.set(
				-centerLabelPos.x,
				this.markerLabelGroup.position.y + 3,
				-centerLabelPos.z
			);
			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height * params.forceScale,
				params.markerData.location.z
			);

			this.marker.scale.set(
				this.marker.scale.x * params.forceScale,
				this.marker.scale.y * params.forceScale,
				this.marker.scale.z * params.forceScale
			);
		}, 300);
	}

	ScaleWithZoom() {
		if (G.cam.camera.zoomLevel > 160) {
			this.markerLookGroup.scale.set(
				(1 * G.cam.camera.zoomLevel) / this.scaleRate,
				(1 * G.cam.camera.zoomLevel) / this.scaleRate,
				(1 * G.cam.camera.zoomLevel) / this.scaleRate
			);
		} else this.markerLookGroup.scale.set(1, 1, 1);
	}

	onClick() {
		if (this.params.markerData.data && this.isVisible && G.ShowImageViewer) {
			if (!this.isActive) {
				G.ShowImageViewer(true, this.params.markerData.data);
				// HighlightMarker.ResetAllMarkers();
				// this.SetActiveAppearance();
			} else {
				G.ShowImageViewer(false);
				// this.ResetMarkerAppearance();
			}
		}
	}

	onMouseEnter() {
		// console.log("HELLO FROM FEATURE MARKER");
	}

	onMouseLeave() {
		// console.log("BYE FROM FEATURE MARKER");
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}
}

export default FeatureMarker;
