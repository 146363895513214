import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Entity from '../Entity';

class M4Marker extends Entity {
	constructor() {
		super();

		return new Promise((resolve, reject) => {
			const loader = new GLTFLoader();
			loader.load('models/m4marker.glb', (data) => {
				this.marker = data.scene;
				this.marker.position.set(-4.5, 0, -87);
				this.marker.scale.set(0.25, 0.25, 0.25);

				resolve(this.marker);
			});
		});
	}
}

export default M4Marker;
