import React, { Component } from "react";
import anime from "animejs";
import G from "../globals";

class Intro extends Component {
  constructor() {
    super();
    this.OutroAnim = this.OutroAnim.bind(this);
    this.IntroAnim = this.IntroAnim.bind(this);
  }

  componentDidMount() {
    document.addEventListener("stateWillChange", this.OutroAnim);
    document.addEventListener("loadDone", this.IntroAnim);
  }

  componentWillUnmount() {
    document.removeEventListener("stateWillChange", this.OutroAnim);
    document.removeEventListener("loadDone", this.IntroAnim);
  }

  OutroAnim(e) {
    if (e.detail.newState.name === "INTRO") return;
    const timeline = new anime.timeline();

    timeline
      .add(
        {
          targets: this.introLogo,
          opacity: [1, 0],
          easing: "easeInOutQuad",
          duration: 400,
        },
        0
      )
      .add(
        {
          targets: this.introText,
          opacity: [1, 0],
          easing: "easeInOutQuad",
          duration: 400,
        },
        0
      )
      .add({
        targets: this.introBg,
        opacity: [1, 0],
        easing: "easeInOutQuad",
        duration: 400,
      });
  }

  IntroAnim() {}

  render() {
    return (
      <div className="slide clickable">
        <div className="content full-width-content intro-content">
          <div ref={(ref) => (this.introBg = ref)} className="intro-bg" />
          <div ref={(ref) => (this.introLogo = ref)} className="intro-logo" />

          <div
            ref={(ref) => (this.introText = ref)}
            onClick={() => this.props.NextState()}
            className="slide-nav-wrapper intro-nav-wrapper"
          >
            <div className="intro-group">
              <p className="intro-marker intro-marker-highlight">
                PART FIRST FLOOR AVAILABLE
              </p>
              <p className="intro-marker">
                Spaces available from 7,900 – 17,075 sq ft on first floor
              </p>
              <div className="intro-text">
                A stunning, newly refurbished office HQ
              </div>
            </div>

            <div className="slide-nav-text slide-intro-text clickable">
              Enter
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
