import * as THREE from "three";
import { G } from "../globals";
import Entity from "./Entity";
import StreetMarker from "./markers/StreetMarker";
import FeatureMarker from "./markers/FeatureMarker";
import HighlightMarker from "./markers/HighlightMarker";
import OccupierMarker from "./markers/OccupierMarker";
import DirectionMarker from "./markers/DirectionMarker";
import M4Marker from "./markers/M4Marker";
import IconMarker from "./markers/IconMarker";
import {
	streetMarkerData,
	featureMarkerData,
	busMarkers,
	parkMarkers,
	buildingMarkers,
	amenityMarkers,
	connectionsHighlightMarkers,
	m4Markers,
	connectionRadiusMarker,
	connectionsMapMarkers,
	occBuildingMarkers,
	uspMarker,
	connectionsRoadMapMarkers,
	connectionDirectionMarkers,
	connectionMapDirectionMarkers,
	groundFloorImageMarkers,
	firstFloorImageMarkers,
	secondFloorImageMarkers,
	exploreFloorMarker,
} from "../data/markerData";
import { fitnessEventsData, starEventsData, foodEventsData } from "../data/eventData";
import GroundMarker from "./markers/GroundMarker";
import ImageMarker from "./markers/ImageMarker";
import GalleryMarker from "./markers/GalleryMarker";
import EventMarker from "./markers/EventMarker";

class MarkerController extends Entity {
	constructor() {
		super();

		const textureLoader = new THREE.TextureLoader();
		const fontLoader = new THREE.FontLoader();

		this.ToggleMarkers = this.ToggleMarkers.bind(this);

		G.ToggleMarkers = this.ToggleMarkers;

		this.markersToCreate = [
			{
				name: "connectionM4Marker",
				data: null,
				marker: M4Marker,
			},
			{
				name: "buildingMarkers",
				data: buildingMarkers,
				marker: GalleryMarker,
				icon: "imageMarkerIcon",
				iconSize: { width: 4, height: 4 },
				forceScale: 0.42,
			},
			{
				name: "amenityMarkers",
				data: amenityMarkers,
				color: "#CD7A60",
				textColor: "#003E34",
				activeColor: "#003E34",
				activeTextColor: "#FFFFFF",
				marker: HighlightMarker,
			},
			{
				name: "busMarkers",
				data: busMarkers,
				icon: "busIcon",
				iconSize: { width: 1.75, height: 2.25 },
				forceScale: 3.5,
				marker: IconMarker,
			},

			{
				name: "connectionsHighlightMarkers",
				data: connectionsHighlightMarkers,
				color: "#E1DC75",
				textColor: "#535B5A",
				activeColor: "#E18E75",
				activeTextColor: "#FFFFFF",
				marker: HighlightMarker,
			},
			{
				name: "m4Markers",
				data: m4Markers,
				color: "#6EA9DC",
				marker: HighlightMarker,
			},
			{ name: "connectionsMapMarker", data: connectionRadiusMarker, marker: GroundMarker },
			{
				name: "connectionsMapMarkers",
				data: connectionsMapMarkers,
				marker: FeatureMarker,
			},
			{
				name: "connectionsDirectionMarkers",
				data: connectionDirectionMarkers,
				marker: DirectionMarker,
				icon: "dirArrow",
				iconSize: { width: 1.5, height: 3 },
			},
			{
				name: "connectionsMapDirectionMarkers",
				data: connectionMapDirectionMarkers,
				marker: DirectionMarker,
				icon: "dirArrow",
				iconSize: { width: 1.5, height: 3 },
			},
			{
				name: "connectionsRoadMapMarkers",
				data: connectionsRoadMapMarkers,
				marker: FeatureMarker,
			},
			{
				name: "uspMarker",
				icon: "uspLogo",
				iconSize: { width: 25 * 0.75, height: 5.5 * 0.75 }, //25 5
				margin: { x: 3, y: 2.0 },
				color: "#7FDEAE",
				data: uspMarker,
				marker: IconMarker,
				forceScale: 13,
			},
			{
				name: "uspMarkerSmall",
				icon: "uspLogo",
				iconSize: { width: 25, height: 5.5 },
				margin: { x: 3, y: 2.0 },
				height: 12,
				color: "#7FDEAE",
				data: uspMarker,
				marker: IconMarker,
				forceScale: 1.3,
			},
			{
				name: "occBuildingMarkers",
				data: occBuildingMarkers,
				marker: OccupierMarker,
				height: 5,
				color: "#EDF0EF",
				textColor: "#003E34",
				activeColor: "#003E34",
				activeTextColor: "#FFFFFF",
				forceScale: 1.45,
			},
			{
				name: "groundFloorImageMarkers",
				icon: "imageMarkerIcon",
				iconSize: { width: 4, height: 4 },
				data: groundFloorImageMarkers,
				marker: GalleryMarker,
				forceScale: 0.15,
			},
			{
				name: "firstFloorImageMarkers",
				icon: "imageMarkerIcon",
				iconSize: { width: 4, height: 4 },
				data: firstFloorImageMarkers,
				marker: GalleryMarker,
				forceScale: 0.15,
			},
			{
				name: "secondFloorImageMarkers",
				icon: "imageMarkerIcon",
				iconSize: { width: 4, height: 4 },
				data: secondFloorImageMarkers,
				marker: GalleryMarker,
				forceScale: 0.15,
			},
			{
				name: "exploreFloorImageMarkers",
				icon: "imageMarkerIcon",
				iconSize: { width: 4, height: 4 },
				data: exploreFloorMarker,
				marker: ImageMarker,
				forceScale: 0.3,
			},
			{
				name: "fitnessEventsMarkers",
				icon: "fitnessEventIcon",
				activeIcon: "fitnessEventIconActive",
				iconSize: { width: 6, height: 7 },
				data: fitnessEventsData,
				marker: EventMarker,
				forceScale: 1,
			},
			{
				name: "starEventsMarkers",
				icon: "starEventIcon",
				activeIcon: "starEventIconActive",
				iconSize: { width: 6, height: 7 },
				data: starEventsData,
				marker: EventMarker,
				forceScale: 1,
			},
			{
				name: "foodEventsMarkers",
				icon: "foodEventIcon",
				activeIcon: "foodEventIconActive",
				iconSize: { width: 6, height: 7 },
				data: foodEventsData,
				marker: EventMarker,
				forceScale: 1,
			},
		];

		this.markers = {};

		this.assetsManifest = [
			{ name: "font", loader: fontLoader, path: "fonts/favoritRegular2.json" },
			{ name: "busIcon", loader: textureLoader, path: "images/busIcon.png" },
			{ name: "parkIcon", loader: textureLoader, path: "images/parkingIcon.png" },
			{
				name: "fitnessEventIcon",
				loader: textureLoader,
				path: "images/fitness-event-icon.png",
			},
			{
				name: "fitnessEventIconActive",
				loader: textureLoader,
				path: "images/fitness-event-icon-active.png",
			},
			{ name: "foodEventIcon", loader: textureLoader, path: "images/food-event-icon.png" },
			{
				name: "foodEventIconActive",
				loader: textureLoader,
				path: "images/food-event-icon-active.png",
			},
			{ name: "starEventIcon", loader: textureLoader, path: "images/star-event-icon.png" },
			{
				name: "starEventIconActive",
				loader: textureLoader,
				path: "images/star-event-icon-active.png",
			},
			{
				name: "connectionsMapMarker",
				loader: textureLoader,
				path: "images/connection-map-marker.png",
			},
			{ name: "tubeIcon", loader: textureLoader, path: "images/tube-icon.png" },
			{ name: "railIcon", loader: textureLoader, path: "images/rail-icon.png" },
			{ name: "tubeRailIcon", loader: textureLoader, path: "images/tube-rail.png" },
			{ name: "planeIcon", loader: textureLoader, path: "images/plane-icon.png" },
			{
				name: "uspLogo",
				loader: textureLoader,
				path: "images/usp-icon.png",
			},
			{
				name: "imageMarkerIcon",
				loader: textureLoader,
				path: "images/image-marker-icon.png",
			},
			{
				name: "dirArrow",
				loader: textureLoader,
				path: "images/dir-arrow.png",
			},
			{
				name: "logo-alexion",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-alexion.png",
			},
			{
				name: "logo-apple",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-apple.png",
			},
			{
				name: "logo-canon",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-canon.png",
			},
			{
				name: "logo-cargologicmanagement",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-cargologicmanagement.png",
			},
			{
				name: "logo-celgene",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-celgene.png",
			},
			{
				name: "logo-coats",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-coats.png",
			},
			{
				name: "logo-gilead",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-gilead.png",
			},
			{
				name: "logo-hasbro",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-hasbro.png",
			},
			{
				name: "logo-havi",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-havi.png",
			},
			{
				name: "logo-hikvision",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-hikvision.png",
			},
			{
				name: "logo-img",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-img.png",
			},
			{
				name: "logo-kuehne",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-kuehne.png",
			},
			{
				name: "logo-lucozade",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-lucozade.png",
			},
			{
				name: "logo-ms",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-ms.png",
			},
			{
				name: "logo-mitsubishi",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-mitsubishi.png",
			},
			{
				name: "logo-msc",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-msc.png",
			},
			{
				name: "logo-nobel",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-nobel.png",
			},
			{
				name: "logo-orega",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-orega.png",
			},
			{
				name: "logo-regus",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-regus.png",
			},
			{
				name: "logo-samsonite",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-samsonite.png",
			},
			{
				name: "logo-sharp",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-sharp.png",
			},
			{
				name: "logo-spaces",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-spaces.png",
			},
			{
				name: "logo-stryker",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-stryker.png",
			},
			{
				name: "logo-toshiba",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-toshiba.png",
			},
			{
				name: "logo-verifone",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-verifone.png",
			},
			{
				name: "logo-worldvision",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-worldvision.png",
			},
			{
				name: "logo-fiserv",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-fiserv.png",
			},
			{
				name: "logo-clm",
				loader: textureLoader,
				path: "images/occupierLogos/occ-logo-clm.png",
			},
		];

		//add image marker thumbnails to loaded assets
		[
			...buildingMarkers,
			...groundFloorImageMarkers,
			...firstFloorImageMarkers,
			...secondFloorImageMarkers,
			...exploreFloorMarker,
		].map((m) => {
			if (m.thumbImg)
				this.assetsManifest.push({
					name: m.thumbImg,
					loader: textureLoader,
					path: `images/${m.thumbImg}`,
				});
		});

		this.CreateMarkers();
		setTimeout(() => {
			this.HideMarkers();
		}, 400);
	}

	CreateMarkers() {
		this.LoadAssets().then((loadedAssets) => {
			this.loadedAssets = loadedAssets;
			this.markersToCreate.map((newMarker) => {
				newMarker.groupName = `${newMarker.name}Group`;
				newMarker.group = new THREE.Group();
				this.markers[newMarker.groupName] = newMarker;

				if (newMarker.data && Array.isArray(newMarker.data)) {
					newMarker.m = newMarker.data.map((entry, i) => {
						const m = new newMarker.marker({
							font: loadedAssets.font ? loadedAssets.font : null,
							name: newMarker.name,
							markerData: entry,
							image: loadedAssets[entry.image],
							margin: newMarker.margin,
							height: newMarker.height
								? newMarker.height
								: entry.height
								? entry.height
								: 6,
							iconSize: newMarker.iconSize,
							icon: loadedAssets[newMarker.icon]
								? loadedAssets[newMarker.icon]
								: null,
							thumbImg: entry.thumbImg ? loadedAssets[entry.thumbImg] : null,
							activeIcon: loadedAssets[newMarker.activeIcon],
							logos: entry.logos
								? entry.logos.map((l) => {
										const img = loadedAssets[l.img];
										if (!img) console.error("CAN'T LOAD ASSET " + l.img);
										return img;
								  })
								: null,
							color: newMarker.color,
							textColor: newMarker.textColor,
							activeColor: newMarker.activeColor,
							activeTextColor: newMarker.activeTextColor,
							forceScale: newMarker.forceScale
								? newMarker.forceScale
								: entry.forceScale
								? entry.forceScale
								: 1,
						});
						newMarker.group.add(m.marker);
						return m;
					});
				} else {
					// Check if marker is a promise by seeing if it has a then function (eg if we are loading models);
					newMarker.m = new newMarker.marker({
						font: loadedAssets.font ? loadedAssets.font : null,
						name: newMarker.name,
					}).then((loadedMarker) => {
						newMarker.group.add(loadedMarker);
						return loadedMarker;
					});
				}

				G.markers[newMarker.name] = newMarker;
				this.Instantiate(newMarker.group, newMarker.groupName);
				return null;
			});
		});
	}

	LoadAssets() {
		const promiseArray = this.assetsManifest.map((asset) => {
			return new Promise((resolve, reject) => {
				asset.loader.load(asset.path, (data) => {
					resolve(data);
				});
			});
		});

		return Promise.all(promiseArray).then((loadedAssets) => {
			const assets = {};
			this.assetsManifest.map((asset, i) => {
				assets[asset.name] = loadedAssets[i];
				return null;
			});

			return assets;
		});
	}

	onStateChange(newState) {
		this.HideMarkers();
	}

	onCamDone(newState) {
		if (newState.markers) {
			this.ToggleMarkers(newState.markers, true);
		}
	}

	HideMarkers() {
		[...Object.values(this.markers)].map((marker) => {
			if (Array.isArray(G.markers[marker.name].m))
				G.markers[marker.name].m.map((m) => (m.isVisible = false));
			G.markers[marker.name].group.visible = false;
			return null;
		});
	}

	ToggleMarkers(markersToToggle, bool = true) {
		if (Array.isArray(markersToToggle)) {
			markersToToggle.map((marker) => {
				if (G.markers[marker]) {
					G.markers[marker].group.visible = bool;
					if (Array.isArray(G.markers[marker].m))
						G.markers[marker].m.map((m) => (m.isVisible = bool));
				} else console.error("UNABLE TO FIND MARKER WITH STRING - " + marker);
				return null;
			});
		} else {
			if (G.markers[markersToToggle]) {
				G.markers[markersToToggle].group.visible = bool;
				if (Array.isArray(G.markers[markersToToggle].m))
					G.markers[markersToToggle].m.map((m) => (m.isVisible = bool));
			} else console.error("UNABLE TO FIND MARKER WITH STRING - " + markersToToggle);
			return null;
		}
	}
}

export default MarkerController;
