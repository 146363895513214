import React, { Component } from "react";
import anime from "animejs";
import Check from "./Check";
import { G } from "../globals";
import U from "../utils";
import { imageData } from "../data/imageData";

class Building extends Component {
  constructor() {
    super();

    this.OutroAnim = this.OutroAnim.bind(this);
    this.IntroAnim = this.IntroAnim.bind(this);
    this.ScrollFade = this.ScrollFade.bind(this);
  }

  componentDidMount() {
    document.addEventListener("stateWillChange", this.OutroAnim);
    document.addEventListener("camDone", this.IntroAnim);
    document.addEventListener("scroll", this.ScrollFade);
  }

  componentWillUnmount() {
    document.removeEventListener("stateWillChange", this.OutroAnim);
    document.removeEventListener("camDone", this.IntroAnim);
    document.removeEventListener("scroll", this.ScrollFade);
  }

  OutroAnim(e) {
    if (e.detail.newState.name === "BUILDING") return;

    const timeline = new anime.timeline();
    timeline.add({
      targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
      opacity: 0,
      easing: "easeInOutQuad",
      duration: 400,
    });
  }

  IntroAnim() {
    const timeline = new anime.timeline();
    timeline.add({
      targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
      opacity: U.GetResponsiveMode() === "desktop" ? 1 : 0.9,
      easing: "easeInOutQuad",
      duration: 800,
    });
  }

  ScrollFade(e) {
    if (!this.scrollFadeDiv) return;
    if (window.scrollY > 50) this.scrollFadeDiv.classList.add("scrolled-fade");
    else this.scrollFadeDiv.classList.remove("scrolled-fade");
  }

  render() {
    return (
      <div ref={(ref) => (this.slideDiv = ref)} className="slide">
        <div ref={(ref) => (this.content = ref)} className="content auto-fade">
          <div className="content-side">
            <div className="section-graphic-text the-detail" />
            <div className="section-graphic detail-graphic" />
          </div>
          <div className="content-main">
            <div className="section-header-wrapper">
              <div className="section-header built-for-purpose" />
              <div className="building-available-note">
                <strong>Part of first floor available</strong>
                <br />
                Suites available from 7,900 sq ft to 17,075 sq ft on first
                floor.
              </div>
              <div className="copy clickable">
                Union has been re-positioned to deliver a market-leading product
                with generous internal common spaces and a large west facing
                terrace.
                <br />
                <br />
                The property also provides the highest levels of sustainability
                criteria in the Thames Valley office market, including an EPC of
                A18 and, uniquely, has been designated{" "}
                <strong>Carbon Neutral Capable</strong>.
              </div>
            </div>
            <div className="section-bottom-wrapper clickable">
              <ul className="section-nav-list w-list-unstyled">
                <li
                  className="section-nav-item"
                  onClick={() => this.props.GoToState("GROUND")}
                >
                  Explore Floors
                </li>
                <li
                  className="section-nav-item"
                  onClick={() => this.props.ShowSchedulePopup(true)}
                >
                  Schedule of Areas
                </li>
                <li
                  className="section-nav-item"
                  onClick={() => this.props.ShowSpecification(true)}
                >
                  Specification
                </li>
                <li
                  className="section-nav-item "
                  onClick={() => this.props.GoToState("VIRTUAL_GROUNDOFFICE")}
                >
                  Take the Virtual Fitted Tour
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="usp-logo-wrapper">
          <div className="usp-logo"></div>
        </div>
        <div className="content-fade auto-fade" />
        <div
          ref={(ref) => (this.scrollFadeDiv = ref)}
          className="content-scroll-fade"
        ></div>
        <div className="content-top-fade auto-fade" />
        <div className="content-right-fade auto-fade" />
        <div className="content-bottom-fade auto-fade" />
      </div>
    );
  }
}

export default Building;
