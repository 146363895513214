import React, { Component } from "react";

class ExpandableConnectionsTab extends Component {
	constructor(props) {
		super();

		this.isOpen = props.defaultValue ? props.defaultValue : false;
	}

	componentDidMount() {
		this.container.style.height = this.container.scrollHeight + "px";
		this.isOpen ? this.Open() : this.Close();
	}

	Open() {
		this.isOpen = true;
		this.container.classList.remove("expand-tab-closed");
		this.icon.classList.add("expand-icon-open");
	}

	Close() {
		this.isOpen = false;
		this.container.classList.add("expand-tab-closed");
		this.icon.classList.remove("expand-icon-open");
	}

	Toggle() {
		if (this.isOpen) this.Close();
		else this.Open();
	}

	render() {
		return (
			<div ref={(ref) => (this.container = ref)} className="connection-category">
				<div className="connection-category-title-wrapper" onClick={() => this.Toggle()}>
					<div className="connection-category-title">{this.props.label}</div>
					<div ref={(ref) => (this.icon = ref)} className="expand-icon"></div>
				</div>
				<ul role="list" className="connection-category-list w-list-unstyled">
					{this.props.children}
				</ul>
			</div>
		);
	}
}

export default ExpandableConnectionsTab;
