import React, { Component } from "react";
import anime from "animejs";
import U from "../utils";

class Connections extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
		this.IntroAnim = this.IntroAnim.bind(this);
		this.ScrollFade = this.ScrollFade.bind(this);
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.OutroAnim);
		document.addEventListener("camDone", this.IntroAnim);
		document.addEventListener("scroll", this.ScrollFade);
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.OutroAnim);
		document.removeEventListener("camDone", this.IntroAnim);
		document.removeEventListener("scroll", this.ScrollFade);
	}

	OutroAnim(e) {
		if (e.detail.newState.name === "LOCATION") return;

		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".fade")],
			opacity: 0,
			easing: "easeInOutQuad",
			duration: 400,
		});
	}

	IntroAnim() {
		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".fade")],
			opacity: U.GetResponsiveMode() === "desktop" ? 1 : 0.9,
			easing: "easeInOutQuad",
			duration: 800,
		});
	}

	ScrollFade(e) {
		if (!this.scrollFadeDiv) return;
		if (window.scrollY > 50) this.scrollFadeDiv.classList.add("scrolled-fade");
		else this.scrollFadeDiv.classList.remove("scrolled-fade");
	}

	render() {
		return (
			<div ref={(ref) => (this.slideDiv = ref)} className="slide">
				<div className="content fade">
					<div className="content-side">
						<div className="section-graphic-text the-community" />
						<div className="section-graphic community-graphic" />
					</div>
					<div className="content-main">
						<div className="section-header-wrapper">
							<div className="section-header commute-happy" />
							<div className="copy clickable">
								Stockley Park is more than just a car-borne destination. Recent
								additions to alternative modes of transport mean you can leave the
								car at home, if you want. Numerous bus services pass through the
								site, linking UNION Stockley Park (or USP) to the towns centres and
								train stations in close proximity.
							</div>
						</div>
						<div className="section-bottom-wrapper">
							<ul className="section-nav-list w-list-unstyled clickable">
								<li className="section-nav-item hidden">Travel times</li>
								<li
									className="section-nav-item"
									onClick={() => this.props.GoToState("CONNECTIONS_MAP")}
								>
									Connections map
								</li>
								<li
									className="section-nav-item"
									onClick={() => this.props.ShowEasitPage(true)}
								>
									easitStockleyPark
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="usp-logo-wrapper">
					<div className="usp-logo"></div>
				</div>
				<div className="content-fade fade" />
				<div className="content-top-fade fade" />
				<div className="content-right-fade fade" />
				<div className="content-bottom-fade fade" />
			</div>
		);
	}
}

export default Connections;
