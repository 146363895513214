import U from "../utils";
import { amenityPopups, connectionPopups } from "./popupData";
import {
  entranceGalleryData,
  atriumGalleryData,
  secOfficeGalleryData,
  coffeeGalleryData,
  receptionGalleryData,
  showerGalleryData,
  terraceGalleryData,
  firOfficeGalleryData,
} from "../data/imageData";

export const streetMarkerData = [
  {
    text: "test",
    location: U.getPosByLatLon(51.508719, -0.444058),
    height: 1,
    rotation: 40,
  },
];

export const occBuildingMarkers = [
  {
    text: "3 Furzeground Way",
    location: { x: 29.945, y: 0, z: -8.116 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 4, h: 1.15 },
        img: "logo-havi",
        name: "Havi Global Solutions",
      },
      {
        size: { w: 4.5, h: 1.15 },
        img: "logo-alexion",
        name: "Alexion Pharma UK",
      },
      {
        size: { w: 4.5, h: 1.35 },
        img: "logo-nobel",
        name: "Nobel Biocare",
      },
      {
        size: { w: 4, h: 0.6 },
        img: "logo-toshiba",
        name: "Toshiba",
      },
    ],
  },
  {
    text: "2 Furzeground Way",
    location: { x: 16.293, y: 0, z: -47.043 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: {
          w: 1.55,
          h: 1.75,
        },
        img: "logo-apple",
        name: "Toshiba",
      },
    ],
  },
  {
    text: "4 Furzeground Way",
    location: { x: 31.119, y: 0, z: -56.2 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 4, h: 0.75 },
        img: "logo-sharp",
        name: "Sharp Electronics",
      },
    ],
  },
  // {
  // 	text: "6 Roundwood Ave",
  // 	location: { x: -0.959, y: 0, z: -43.561 },
  // 	height: 8,
  // 	// forceScale: 0.4,
  // 	logos: [
  // 		{
  // 			size: { w: 4, h: 0.75 },
  // 			img: "logo-canon",
  // 			name: "Canon",
  // 		},
  // 	],
  // },
  {
    text: "5 Roundwood Ave",
    location: { x: -41.806, y: 0, z: -46.025 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 4.5, h: 1.75 },
        img: "logo-msc",
        name: "MSC Cruise Management",
      },
    ],
  },
  {
    text: "1 Roundwood Ave",
    location: { x: -46.254, y: 0, z: -89.598 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 7, h: 0.75 },
        img: "logo-kuehne",
        name: "Kuehne + Nagel",
      },
      {
        size: { w: 5, h: 2.5 },
        img: "logo-worldvision",
        name: "World Vision International",
      },
    ],
  },
  {
    text: "2 Roundwood Ave",
    location: { x: 3.328, y: 0, z: -104.7 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 5.75, h: 1.75 },
        img: "logo-gilead",
        name: "Gilead Sciences Europe",
      },
    ],
  },
  {
    text: "3 Roundwood Ave",
    location: { x: -43.41, y: 0, z: -71.016 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 5, h: 1.25 },
        img: "logo-verifone",
        name: "Verifone",
      },
      {
        size: { w: 5, h: 2 },
        img: "logo-clm",
        name: "CargoLogicManagement",
      },
    ],
  },
  {
    text: "4 Roundwood Ave",
    location: { x: 27.523, y: 0, z: -78.276 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 4, h: 0.75 },
        img: "logo-canon",
        name: "Canon",
      },
    ],
  },
  // {
  // 	text: "7 Roundwood Ave",
  // 	location: { x: -40.669, y: 0, z: -30.009 },
  // 	height: 8,
  // 	// forceScale: 0.4,
  // 	logos: [
  // 		{
  // 			size: { w: 4, h: 1.5 },
  // 			img: "logo-fiserv",
  // 			name: "Fiserv",
  // 		},
  // 	],
  // },
  {
    text: "4 Longwalk Rd",
    location: { x: -69.999, y: 0, z: -17.071 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 2.5, h: 2.5 },
        img: "logo-coats",
        name: "Coats",
      },
      {
        size: { w: 2.5, h: 1.5 },
        img: "logo-img",
        name: "IMG Productions",
      },
      {
        size: { w: 4.5, h: 1.75 },
        img: "logo-orega",
        name: "Orega",
      },
    ],
  },
  {
    text: "5 Longwalk Rd",
    location: { x: -42.827, y: 0, z: -1.724 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 2.5, h: 1.5 },
        img: "logo-img",
        name: "IMG Productions",
      },
    ],
  },
  {
    text: "1 Longwalk Rd",
    location: { x: -84.753, y: 0, z: -89.061 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 3.75, h: 2.75 },
        img: "logo-celgene",
        name: "Celgene",
      },
    ],
  },
  {
    text: "2 Longwalk Rd",
    location: { x: -82.321, y: 0, z: -64.719 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 4, h: 3 },
        img: "logo-lucozade",
        name: "Lucozade Ribena Suntory",
      },
    ],
  },
  {
    text: "3 Longwalk Rd",
    location: { x: -78.013, y: 0, z: -41.021 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 4.25, h: 2 },
        img: "logo-ms",
        name: "Marks and Spencer",
      },
    ],
  },
  // {
  // 	text: "1 The Square",
  // 	location: { x: 77.355, y: 0, z: -33.96 },
  // 	height: 8,
  // 	// forceScale: 0.4,
  // 	logos: [
  // 		{
  // 			size: { w: 4.75, h: 1 },
  // 			img: "logo-samsonite",
  // 			name: "Samsonite",
  // 		},
  // 	],
  // },
  // {
  // 	text: "2 The Square",
  // 	location: { x: 77.932, y: 0, z: -54.881 },
  // 	height: 8,
  // 	// forceScale: 0.4,
  // 	logos: [
  // 		{
  // 			size: { w: 2, h: 2 },
  // 			img: "logo-coats",
  // 		},
  // 	],
  // },
  {
    text: "3 The Square",
    location: { x: 106.858, y: 0, z: -55.703 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      // {
      // 	size: { w: 4, h: 0.75 },
      // 	img: "logo-canon",
      // 	name: "Canon",
      // },
    ],
  },
  {
    text: "6 / 9 The Square",
    location: { x: 102.493, y: 0, z: -22.388 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 3.5, h: 1.5 },
        img: "logo-regus",
        name: "Regus",
      },
    ],
  },
  {
    text: "5 The Square",
    location: { x: 122.174, y: 0, z: -30.933 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 4.75, h: 1 },
        img: "logo-samsonite",
        name: "Samsonite",
      },
      {
        size: { w: 6.25, h: 1.5 },
        img: "logo-mitsubishi",
        name: "Mitsubishi Heavy Industries Air-Conditioning Europe",
      },
    ],
  },
  {
    text: "4 The Square",
    location: { x: 143.304, y: 0, z: -48.239 },
    height: 8,
    // forceScale: 0.4,
    logos: [
      {
        size: { w: 3.75, h: 2.5 },
        img: "logo-hasbro",
        name: "Hasbro",
      },
      {
        size: { w: 5, h: 1 },
        img: "logo-hikvision",
        name: "HIKVISION",
      },
    ],
  },
];

export const buildingMarkers = [
  {
    text: "Get The Arrival Experience",
    location: { x: -0.313, y: 0, z: -10.654 },
    thumbImg: "g-entrance-thumb.png",
    height: 12,
    rotation: 0,
    forceScale: 0.4,
    data: entranceGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
  },
  {
    text: "Go Straight to The Atrium",
    location: { x: 0.97, y: 0, z: 0.09 },
    thumbImg: "g-atrium-thumb.png",
    height: 15,
    rotation: 0,
    forceScale: 0.4,
    data: atriumGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
  },
  {
    text: "Terrace",
    location: { x: -7.29, y: 0, z: -3.455 },
    thumbImg: "g-terrace-thumb.png",
    height: 5,
    rotation: 0,
    forceScale: 0.4,
    data: terraceGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
  },
];

export const groundFloorImageMarkers = [
  {
    text: "Atrium",
    location: { x: 0, y: 0, z: 0 },
    height: 9,
    data: atriumGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-atrium-thumb.png",
  },
  {
    text: "Reception",
    location: { x: -2.374, y: 0, z: -5.55 },
    height: 9,
    data: receptionGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-reception-thumb.png",
  },
  {
    text: "Terrace",
    location: { x: -5.748, y: 0, z: -4.293 },
    height: 9,
    data: terraceGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-terrace-thumb.png",
  },
  {
    text: "Coffee bar",
    location: { x: -1.485, y: 0, z: -3.048 },
    height: 9,
    data: coffeeGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-cafe-thumb.png",
  },
  // {
  // 	text: "Ground office",
  // 	location: { x: -3.39, y: 0, z: 3.539 },
  // 	height: 9,
  // 	data: "VIRTUAL_GROUNDOFFICE",
  // 	thumbImg: "v-ground-thumb.png",
  // },
  {
    text: "Entrance",
    location: { x: -0.948, y: 0, z: -10.154 },
    height: 9,
    data: entranceGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-entrance-thumb.png",
  },
  // {
  // 	text: "WC",
  // 	location: { x: 7.118, y: 0, z: -1.29 },
  // 	height: 9,
  // 	data: showerGalleryData.map((i) => {
  // 		return { img: i.img, caption: i.txt };
  // 	}),
  // 	thumbImg: "g-showers-thumb.png",
  // },
  {
    text: "WC",
    location: { x: -4.63, y: 0, z: 0.992 },
    height: 9,
    data: showerGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-showers-thumb.png",
  },
];

export const firstFloorImageMarkers = [
  {
    text: "Office",
    location: { x: 4.222, y: 0, z: 4.056 },
    height: 14,
    data: firOfficeGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-first-thumb.png",
  },
  {
    text: "WC",
    location: { x: 5.345, y: 15, z: -2.372 },
    height: 14,
    data: showerGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-showers-thumb.png",
  },
];

export const secondFloorImageMarkers = [
  {
    text: "Office",
    location: { x: 4.222, y: 0, z: 4.056 },
    height: 23,
    data: secOfficeGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-second-thumb.png",
  },
  {
    text: "WC",
    location: { x: -4.63, y: 20, z: 0.992 },
    height: 23,
    data: showerGalleryData.map((i) => {
      return { img: i.img, caption: i.txt };
    }),
    thumbImg: "g-showers-thumb.png",
  },
];

export const exploreFloorMarker = [
  {
    text: "Explore floors",
    location: { x: 0, y: 0, z: 0 },
    height: 17,
    data: "GROUND",
    thumbImg: "v-floor-thumb.png",
  },
];

export const amenityMarkers = [
  {
    text: "The Lake",
    location: { x: -14.815, y: 0, z: -5.539 },
    height: 9,
    rotation: 0,
    data: amenityPopups.lakeside,
  },
  {
    text: "Borrow Bikes",
    location: { x: -40.248, y: 0, z: -14.723 },
    height: 9,
    rotation: 0,
    data: amenityPopups.borrowBikes,
  },
  {
    text: "Walking Routes",
    location: { x: -91.899, y: 0, z: -5.935 },
    height: 9,
    rotation: 0,
    data: amenityPopups.walkingRoutes,
  },
  {
    text: "Fitness Bootcamp",
    location: { x: -19.84, y: 0, z: -71.079 },
    height: 9,
    rotation: 0,
    data: amenityPopups.fitnessBootcamp,
  },
  {
    text: "Food Market",
    location: { x: 8.165, y: 0, z: -69.477 },
    height: 9,
    rotation: 0,
    data: amenityPopups.foodMarket,
  },
  {
    text: "Running Club",
    location: { x: 32.169, y: 0, z: -107.277 },
    height: 9,
    rotation: 0,
    data: amenityPopups.runningClub,
  },
  {
    text: "The Arena",
    location: { x: -20.792, y: 0, z: -119.31 },
    height: 9,
    rotation: 0,
    data: amenityPopups.arena,
  },
  {
    text: "Trim Trail",
    location: { x: -71.88, y: 0, z: -128.361 },
    height: 9,
    rotation: 0,
    data: amenityPopups.trimTrail,
  },
  {
    text: "Stockley Park Golf Club",
    location: { x: -52.564, y: 0, z: -177.945 },
    height: 13,
    rotation: 0,
    data: amenityPopups.golfClub,
  },
  {
    text: "Meeting Pods",
    location: { x: -15.941, y: 0, z: -53.369 },
    height: 9,
    rotation: 0,
    data: amenityPopups.meetingPods,
  },
  {
    text: "The Set Cafe",
    location: { x: 112.468, y: 0, z: -42.486 },
    height: 9,
    rotation: 0,
    data: amenityPopups.thesetcafe,
  },
  {
    text: "Canal Towpath",
    location: { x: 34.908, y: 0, z: 17.689 },
    height: 9,
    rotation: 0,
    data: amenityPopups.towpath,
  },
];

export const busMarkers = [
  {
    location: { x: -70.121, y: 0, z: -105.181 },
    height: 1,
  },
  {
    location: { x: -66.135, y: 0, z: -68.513 },
    height: 1,
  },
  {
    location: { x: -21.033, y: 0, z: -38.538 },
    height: 1,
  },
  {
    location: { x: 20.683, y: 0, z: -34.167 },
    height: 1,
  },
  {
    location: { x: 70.733, y: 0, z: -45.378 },
    height: 1,
  },
];

export const parkMarkers = [
  {
    location: { x: 12.628, y: 0, z: -10.424 },
    height: 6,
    forceScale: 1.75,
  },
  {
    location: { x: 29.825, y: 0, z: -26.071 },
    height: 6,
    forceScale: 1.75,
  },
];

export const connectionsHighlightMarkers = [
  {
    text: "Heathrow Shuttle",
    location: { x: -66.04, y: 0, z: -76.92 },
    height: 6,
    rotation: 0,
    forceScale: 1.75,
    data: connectionPopups.heathrowShuttle,
  },
  {
    text: "Bus Services",
    location: { x: 22.622, y: 0, z: -43.553 },
    height: 6,
    rotation: 0,
    forceScale: 1.75,
    data: connectionPopups.busServices,
  },
  // {
  // 	text: "Parking",
  // 	location: { x: 14.173, y: 0, z: -9.088 },
  // 	height: 6,
  // 	rotation: 0,
  // 	forceScale: 1.75,
  // 	data: connectionPopups[2],
  // },
  {
    text: "easitShuttle",
    location: { x: -22.938, y: 0, z: -70.266 },
    height: 6,
    rotation: 0,
    forceScale: 1.75,
    data: connectionPopups.easitShuttle,
  },
];

export const m4Markers = [
  {
    text: "Stockley Rd A408 to M4",
    location: { x: -112.577, y: 0, z: -42.04 },
    height: 6,
    rotation: 0,
    forceScale: 1.75,
  },
];

export const connectionRadiusMarker = [
  {
    image: "connectionsMapMarker",
    location: { x: 0, y: 0, z: 0 },
    rotation: 0,
    imageSize: { width: 2500, height: 2500 },
  },
];

export const uspMarker = [
  {
    location: { x: 0.998, y: 0, z: -10.85 },
    height: 6,
  },
];

export const connectionsMapMarkers = [
  {
    text: "Uxbridge",
    image: "tubeIcon",
    location: { x: -565, y: 0, z: -1033 },
    imageSize: { width: 2.4, height: 2 },

    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Hatton Cross",
    image: "tubeIcon",
    location: { x: 457.602, y: 0, z: 1084.372 },
    imageSize: { width: 2.4, height: 2 },
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Heathrow Terminal 4",
    image: "tubeIcon",
    location: { x: 18.021, y: 0, z: 1340.611 },
    imageSize: { width: 2.4, height: 2 },
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Heathrow Terminal 5",
    image: "tubeIcon",
    location: { x: -808.41, y: 0, z: 1038.848 },
    imageSize: { width: 2.4, height: 2 },
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Heathrow Terminals 2 and 3",
    image: "tubeIcon",
    location: { x: -164.363, y: 0, z: 1062.292 },
    imageSize: { width: 2.4, height: 2 },
    noPin: true,
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Heathrow Express",
    image: "railIcon",
    location: { x: -313.178, y: 0, z: 1000.946 },
    imageSize: { width: 2.4, height: 2 },
    noPin: true,
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "West Drayton",
    image: "railIcon",
    location: { x: -515.803, y: 0, z: -51.339 },
    imageSize: { width: 2.8, height: 2 },
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Hayes and Harlington",
    image: "railIcon",
    location: { x: 332.328, y: 0, z: 181.272 },
    imageSize: { width: 2.8, height: 2 },
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Southall",
    image: "railIcon",
    location: { x: 1120.061, y: 0, z: 57.673 },
    imageSize: { width: 2.8, height: 2 },
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "Heathrow",
    image: "planeIcon",
    location: { x: -148.637, y: 0, z: 802.809 },
    imageSize: { width: 2.5, height: 2.5 },
    rotation: 0,
    height: 6,
    forceScale: 13,
  },
  {
    text: "350",
    location: { x: -266.951, y: 0, z: -50.813 },
    color: "#727FBE",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 13,
  },
  {
    text: "A10",
    location: { x: -20.107, y: 0, z: -323.447 },
    color: "#34FF54",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 13,
  },
  {
    text: "U5",
    location: { x: -231.755, y: 0, z: 310.62 },
    color: "#65BACE",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 13,
  },
];

export const connectionsRoadMapMarkers = [
  {
    text: "M4",
    location: { x: -154.166, y: 0, z: 615.35 },
    color: "#4579A5",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "M4",
    location: { x: 307.097, y: 0, z: 446.131 },
    color: "#4579A5",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "M25",
    location: { x: -1017.807, y: 0, z: 886.405 },
    color: "#4579A5",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "M25",
    location: { x: -986.61, y: 0, z: -82.497 },
    color: "#4579A5",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "M25",
    location: { x: -1282.946, y: 0, z: -1078.797 },
    color: "#4579A5",
    textColor: "#ffffff",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "A40",
    location: { x: 218.198, y: 0, z: -1105.606 },
    color: "#ffffff",
    textColor: "#6EA9DC",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "A312",
    location: { x: 685.169, y: 0, z: -9.795 },
    color: "#ffffff",
    textColor: "#6EA9DC",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "A408",
    location: { x: -608.782, y: 0, z: -304.123 },
    color: "#ffffff",
    textColor: "#6EA9DC",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
  {
    text: "A408",
    location: { x: -151.729, y: 0, z: 165.639 },
    color: "#ffffff",
    textColor: "#6EA9DC",
    noPin: true,
    rotation: 0,
    height: 3,
    forceScale: 14,
  },
];

export const connectionDirectionMarkers = [
  {
    text: "Uxbridge",
    subText: null,
    direction: U.convertDegreesToRadians(-30),
    location: { x: -101.85, y: 0, z: -163.087 },
    height: 3,
    color: "#ffffff",
    textColor: "#003E34",
    forceScale: 1.5,
  },
  {
    text: "Hayes and Harlington",
    subText: null,
    direction: U.convertDegreesToRadians(120),
    location: { x: 58.123, y: 0, z: 12.221 },
    height: 3,
    color: "#ffffff",
    textColor: "#003E34",
    forceScale: 1.5,
  },
];

export const connectionMapDirectionMarkers = [
  {
    text: "London",
    subText: "16.3 miles",
    direction: U.convertDegreesToRadians(90),
    location: { x: 752.923, y: 0, z: 495.088 },
    height: 3,
    color: "#ffffff",
    textColor: "#003E34",
    forceScale: 14,
  },
  {
    text: "Reading",
    subText: "27.8 miles",
    direction: U.convertDegreesToRadians(-90),
    location: { x: -609.525, y: 0, z: 267.336 },
    height: 3,
    color: "#ffffff",
    textColor: "#003E34",
    forceScale: 14,
  },
  {
    text: "Slough",
    subText: "7.8 miles",
    direction: U.convertDegreesToRadians(-90),
    location: { x: -618.022, y: 0, z: 383.395 },
    height: 3,
    color: "#ffffff",
    textColor: "#003E34",
    forceScale: 14,
  },
];
