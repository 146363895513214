import React, { Component } from 'react';
import { G } from '../globals';
import U from '../utils';
import Loop from '../viz/Loop';
import { CONFIG } from '../config';

class Compass extends Component {
	constructor() {
		super();

		this.SetRotation = this.SetRotation.bind(this);
		this.angle = 0;
	}

	componentDidMount() {
		this.loop = new Loop(this.SetRotation).start();
	}

	SetRotation() {
		if (G.cam && G.cam.camera.getAzimuthalAngle && this.arrow) {
			this.angle = U.MapRange(G.cam.camera.getAzimuthalAngle(), -Math.PI, Math.PI, -180, 180);
			this.arrow.setAttribute(
				'transform',
				`translate(8, 15.5) rotate(${this.angle}) translate(-8.000000, -15.500000)`
			);
		}
	}

	render() {
		const willShow =
			this.props.currentState && this.props.currentState.options && this.props.currentState.options.showCompass
				? true
				: false;
		return (
			<div className={`compass ${willShow ? '' : 'hidden'}`}>
				<svg
					width="31px"
					height="31px"
					viewBox="0 0 31 31"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlnsXlink="http://www.w3.org/1999/xlink"
				>
					<g id="PROTOTYPE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g id="Group-6" ref={(ref) => (this.arrow = ref)}>
							<polygon
								id="Triangle"
								fill="#E18E75"
								transform="translate(8.000000, 24.000000) rotate(-180.000000) translate(-8.000000, -24.000000) "
								points="8 17 16 31 0 31"
							/>
							<polygon id="Triangle-Copy" fill="#6EA9DC" points="8 -5.85087534e-14 16 14 0 14" />
							<polygon
								id="N"
								stroke="#FFFFFF"
								strokeWidth="0.2"
								fill="#FFFFFF"
								fillRule="nonzero"
								strokeLinejoin="round"
								points="7.13880002 11.2 7.13880002 7.90399995 8.89880005 11.2 10.0188001 11.2 10.0188001 6.71999993 9.15480005 6.71999993 9.15480005 10.0032 7.39480002 6.71999993 6.27480001 6.71999993 6.27480001 11.2"
							/>
						</g>
					</g>
				</svg>
			</div>
		);
	}
}

export default Compass;
