import * as THREE from "three";
import { G } from "../globals";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import Entity from "./Entity";
import U from "../utils";
import Loop from "./Loop";

class Ground extends Entity {
  constructor() {
    super();

    // this.CreateDebugPoint(U.getPosByLatLon(51.511795, -0.444631, 1), "latlonbox", "red");
    // this.CreateDebugPoint(U.getPosByLatLon(51.509318, -0.446481, 1), "latlonbox2", "red");

    // this.CreateDebugPoint(U.getPosByLatLon(51.509446, -0.444312, 1), "aPointTestLatLong", "red");
    // this.CreateDebugPoint(U.getPosByLatLon(51.508701, -0.444071, 1), "bPointTestLatLong", "red");

    // this.CreateDebugPoint(new THREE.Vector3(-20.736, 1, -38.186), "aPointTestWorld", "yellow");
    // this.CreateDebugPoint(new THREE.Vector3(-17.01, 1, -17.567), "bPointTestWorld", "yellow");

    // this.CreateDebugPoint(U.getPosByLatLon(51.50808, -0.4427, 1), "originLatLong", "red");
    // this.CreateDebugPoint(new THREE.Vector3(0, 1, 0), "originWorld", "yellow");

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(10000, 10000),
      new THREE.MeshBasicMaterial({
        color: new THREE.Color("#DCE4E3").convertGammaToLinear(2.2),
      })
    );

    plane.rotation.set(-Math.PI / 2, 0, 0);
    plane.position.set(0, -2, 0); //defauly y is -0.5

    plane.receiveShadow = true;

    // this.Instantiate(plane, "ground");

    this.obj = null;

    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("models/draco/");
    loader.setDRACOLoader(dracoLoader);

    loader.load("models/map_v5.glb", (data) => {
      this.obj = data.scene;

      const trees = this.obj.children.find((c) => c.name === "TREES");
      const hedges = this.obj.children.find((c) => c.name === "HEDGES");

      trees.children.map((t) => {
        // console.log(t.material);
        t.material = new THREE.MeshPhongMaterial({
          color: "white",
          vertexColors: true,
        });
        t.material.roughness = 0.5;
        t.material.specular = new THREE.Color(0x545454).convertGammaToLinear(
          2.2
        );
      });

      hedges.material = new THREE.MeshPhongMaterial({
        color: "white",
        vertexColors: true,
      });
      hedges.material.roughness = 0.5;
      hedges.material.specular = new THREE.Color(0x545454).convertGammaToLinear(
        2.2
      );

      this.obj.scale.set(0.25, 0.25, 0.25);
      this.obj.position.set(-4.5, 0, -86);

      const allMats = [];
      this.obj.traverse((o) => {
        if (o.material) allMats.push(o);
      });

      allMats.map((o, i) => {
        o.castShadow = false;
        if (o.material && o.material.map)
          o.material.map.minFilter = THREE.LinearFilter;
        if (o.material.alphaTest > 0) o.material.alphaTest = 0;
      });

      this.Instantiate(this.obj, "map");

      // const debugLoop = new Loop(() => {
      // 	G.debug.TrackVar(
      // 		'mapPos',
      // 		`x: ${U.RoundNum(this.map.position.x, 3)}  //  y: ${U.RoundNum(
      // 			this.map.position.y,
      // 			3
      // 		)}  //  z: ${U.RoundNum(this.map.position.z, 3)}`
      // 	);
      // }).start();
    });

    loader.load("models/region-map_v3.glb", (data) => {
      this.regionObj = data.scene;
      this.regionObj.position.set(-2735.5, 0, -174.5);
      this.regionObj.scale.set(0.87, 0.87, 0.87);
      this.Instantiate(this.regionObj, "region-map");

      new Loop(() => {
        if (G.cam.camera.position.y > 1500) this.ToggleRegionMap(true);
        else this.ToggleRegionMap(false);
      }).start();
    });
  }

  ToggleRegionMap(bool) {
    if (this.obj === null || this.regionObj === null) return;
    if (this.regionObj.visible !== bool) {
      this.regionObj.visible = bool;
      if (bool) this.obj.visible = false;
      else this.obj.visible = true;
    }
  }

  // onStateChange(newState) {
  // 	if (newState.name === "CONNECTIONS_MAP" || newState.name === "DEBUG")
  // 		setTimeout(() => {
  // 			this.regionObj.visible = true;
  // 		}, 1000);
  // 	else this.regionObj.visible = false;
  // }
}

export default Ground;
