import * as THREE from "three";
import Entity from "../Entity";
import Loop from "../Loop";
import { G } from "../../globals";
import { CONFIG } from "../../config";
import U from "../../utils";

class DirectionMarker extends Entity {
	constructor(params) {
		super();

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();
		this.markerStaticGroup = new THREE.Group();

		this.params = params;

		this.isActive = false;

		this.scaleRate = 200;

		const textGeo = new THREE.TextGeometry(params.markerData.text, {
			font: params.font,
			size: 1.5,
			height: 0.01,
			curveSegments: 3,
		});

		this.textMesh = new THREE.Mesh(
			textGeo,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.markerData.textColor ? params.markerData.textColor : "#CD7A60"
				).convertGammaToLinear(2.2),
				depthTest: false,
			})
		);

		this.textMesh.renderOrder = 4;

		const subText = params.markerData.subText ? params.markerData.subText : "notext";

		const subTextGeo = new THREE.TextGeometry(subText, {
			font: params.font,
			size: 1.0,
			height: 0.01,
			curveSegments: 3,
		});

		this.subTextMesh = new THREE.Mesh(
			subTextGeo,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.markerData.textColor ? params.markerData.textColor : "#CD7A60"
				).convertGammaToLinear(2.2),
				depthTest: false,
			})
		);

		this.subTextMesh.renderOrder = 4;

		this.icon = new THREE.Mesh(
			new THREE.PlaneGeometry(params.iconSize.width, params.iconSize.height),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#ffffff"),
				map: params.icon,
				transparent: true,
				depthTest: false,
			})
		);

		this.icon.renderOrder = 4;

		let iconOffset = {
			width: params.iconSize ? params.iconSize.width + 1 : 0,
			height: params.iconSize ? params.iconSize.height : 0,
		};

		this.icon.material.map.encoding = THREE.sRGBEncoding;
		this.icon.material.map.anisotropy = CONFIG.r_anisotropy;
		this.icon.material.map.magFilter = THREE.LinearFilter;
		this.icon.material.map.minFilter = THREE.LinearMipMapLinearFilter;

		this.markerLabelGroup.add(this.icon);
		this.textMesh.position.set(0 + iconOffset.width, 0, 0);
		this.subTextMesh.position.set(
			0 + iconOffset.width,
			-subTextGeo.parameters.parameters.size * 2,
			0
		);
		this.markerLabelGroup.add(this.textMesh);
		if (subText !== "notext") this.markerLabelGroup.add(this.subTextMesh);

		this.planeMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(1, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.markerData.color ? params.markerData.color : "white"
				).convertGammaToLinear(2.2),
				depthTest: false,
			})
		);

		this.planeMesh.renderOrder = 3;

		this.textMesh.geometry.computeBoundingBox();
		this.subTextMesh.geometry.computeBoundingBox();

		const subTextGeometryOffset =
			subText !== "notext" ? subTextGeo.parameters.parameters.size * 1.5 : 0;

		const totalGeometry = {
			x:
				this.textMesh.geometry.boundingBox.max.x >=
				this.subTextMesh.geometry.boundingBox.max.x
					? this.textMesh.geometry.boundingBox.max.x
					: this.subTextMesh.geometry.boundingBox.max.x,
			y:
				this.textMesh.geometry.boundingBox.max.y +
				this.subTextMesh.geometry.boundingBox.max.y +
				subTextGeometryOffset,
			z:
				this.textMesh.geometry.boundingBox.max.z + subText !== "notext"
					? this.subTextMesh.geometry.boundingBox.max.z
					: 0,
		};

		this.icon.position.set(iconOffset.width * 0.1, 0, 0);
		this.icon.rotation.set(0, 0, -params.markerData.direction);

		let centerLabelPos;

		centerLabelPos = {
			x:
				(totalGeometry.x - this.textMesh.geometry.boundingBox.min.x) / 2 +
				iconOffset.width / 2,
			z: (totalGeometry.z - this.textMesh.geometry.boundingBox.min.z) / 2,
		};

		const planePadding = { x: 4, y: 2.5 };

		this.planeMesh.scale.set(
			totalGeometry.x + planePadding.x + iconOffset.width,
			totalGeometry.y + planePadding.y,
			totalGeometry.z
		);
		this.planeMesh.position.set(
			centerLabelPos.x,
			subText !== "notext"
				? -totalGeometry.y / 2 + subTextGeo.parameters.parameters.size * 1.75
				: 0.5,
			centerLabelPos.z + -0.1
		);

		this.markerLabelGroup.add(this.planeMesh);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);

		// this.markerLookGroup.add(this.markerLabelGroup);
		this.marker.add(this.markerStaticGroup);
		this.marker.add(this.markerLookGroup);

		if (params.markerData.data) {
			this.enableInteraction(this.planeMesh);
		}

		this.loop = new Loop(() => {
			// this.ScaleWithZoom();
			if (this.marker.visible) this.RotateToFaceCamera();
		}).start();

		setTimeout(() => {
			this.markerLabelGroup.position.set(
				-centerLabelPos.x,
				this.markerLabelGroup.position.y,
				-centerLabelPos.z
			);
			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height * params.forceScale,
				params.markerData.location.z
			);

			this.marker.scale.set(
				this.marker.scale.x * params.forceScale,
				this.marker.scale.y * params.forceScale,
				this.marker.scale.z * params.forceScale
			);
			// this.markerLookGroup.rotation.set(-Math.PI / 2, 0, 0);
		}, 300);
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}
}

export default DirectionMarker;
