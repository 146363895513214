import * as THREE from "three";
import { G } from "../../globals";
import Entity from "../Entity";
import Loop from "../Loop";
import U from "../../utils";

const markersThisType = [];

class HighlightMarker extends Entity {
	constructor(params) {
		super();

		this.ResetMarkerAppearance = this.ResetMarkerAppearance.bind(this);

		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();

		markersThisType.push(this);
		document.addEventListener("popupWillClose", HighlightMarker.ResetAllMarkers);

		this.params = params;

		this.isActive = false;

		this.scaleRate = 200;

		const textGeo = new THREE.TextGeometry(params.markerData.text, {
			font: params.font,
			size: 1.5,
			height: 0.01,
			curveSegments: 3,
		});

		this.textMesh = new THREE.Mesh(
			textGeo,
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.textColor ? params.textColor : "white"
				).convertGammaToLinear(2.2),
				depthTest: true,
			})
		);
		this.textMesh.renderOrder = 4;

		this.markerLabelGroup.add(this.textMesh);

		this.planeMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(1, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.color ? params.color : "#CD7A60"
				).convertGammaToLinear(2.2),
				depthTest: true,
			})
		);

		this.planeMesh.renderOrder = 3;

		this.planePinMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(1.5, 1.5),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(
					params.color ? params.color : "#CD7A60"
				).convertGammaToLinear(2.2),
			})
		);

		this.textMesh.geometry.computeBoundingBox();

		let centerLabelPos;

		centerLabelPos = {
			x:
				(this.textMesh.geometry.boundingBox.max.x -
					this.textMesh.geometry.boundingBox.min.x) /
				2,
			z:
				(this.textMesh.geometry.boundingBox.max.z -
					this.textMesh.geometry.boundingBox.min.z) /
				2,
		};

		const planePadding = { x: 4, y: 2.5 };

		this.planeMesh.scale.set(
			this.textMesh.geometry.boundingBox.max.x + planePadding.x,
			this.textMesh.geometry.boundingBox.max.y + planePadding.y,
			this.textMesh.geometry.boundingBox.max.z
		);
		this.planeMesh.position.set(
			centerLabelPos.x,
			this.textMesh.geometry.boundingBox.max.y / 2,
			centerLabelPos.z + -0.1
		);

		this.planePinMesh.position.set(
			centerLabelPos.x,
			-this.textMesh.geometry.boundingBox.max.y + 0.25,
			centerLabelPos.z
		);
		this.planePinMesh.rotation.set(0, 0, U.convertDegreesToRadians(45));

		this.markerLabelGroup.add(this.planePinMesh);
		this.markerLabelGroup.add(this.planeMesh);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerLookGroup.add(this.markerVLookGroup);
		this.marker.add(this.markerLookGroup);

		if (params.markerData.data) this.enableInteraction(this.planeMesh);

		this.loop = new Loop(() => {
			// this.ScaleWithZoom();
			if (this.marker.visible) this.RotateToFaceCamera();
		}).start();

		setTimeout(() => {
			this.markerLabelGroup.position.set(
				-centerLabelPos.x,
				this.markerLabelGroup.position.y,
				-centerLabelPos.z
			);
			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height * params.forceScale,
				params.markerData.location.z
			);

			this.marker.scale.set(
				this.marker.scale.x * params.forceScale,
				this.marker.scale.y * params.forceScale,
				this.marker.scale.z * params.forceScale
			);
		}, 300);
	}

	ScaleWithZoom() {
		if (G.cam.camera.zoomLevel > 160) {
			this.markerLookGroup.scale.set(
				(1 * G.cam.camera.zoomLevel) / this.scaleRate,
				(1 * G.cam.camera.zoomLevel) / this.scaleRate,
				(1 * G.cam.camera.zoomLevel) / this.scaleRate
			);
		} else this.markerLookGroup.scale.set(1, 1, 1);
	}

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}

	onClick() {
		console.log("YOU CLICKED ME!!!");
		if (this.params.markerData.data && this.isVisible && G.ShowAmenityPopup) {
			console.log("AND I DO STUFF");
			if (!this.isActive) {
				G.ShowAmenityPopup(true, this.params.markerData.data);
				HighlightMarker.ResetAllMarkers();
				this.SetActiveAppearance();
			} else {
				G.ShowAmenityPopup(false);
				this.ResetMarkerAppearance();
			}
		}
	}

	onMouseEnter() {
		if (this.isVisible) {
			document.body.style.cursor = "pointer";
			this.planeMesh.material.color = new THREE.Color('#003E34').convertGammaToLinear(2.2);
			this.planePinMesh.material.color = new THREE.Color('#003E34').convertGammaToLinear(2.2);
			this.textMesh.material.color = new THREE.Color('white').convertGammaToLinear(2.2);
		}
	}

	onMouseLeave() {
		document.body.style.cursor = "auto";
		if (this.isActive) {
			this.SetActiveAppearance()
		} else {
			this.planeMesh.material.color = new THREE.Color(
				this.params.color ? this.params.color : "#CD7A60"
			).convertGammaToLinear(2.2);
			this.textMesh.material.color = new THREE.Color(
				this.params.textColor ? this.params.textColor : "white"
			).convertGammaToLinear(2.2);
			this.planePinMesh.material.color = new THREE.Color(
				this.params.color ? this.params.color : "#CD7A60"
			).convertGammaToLinear(2.2);
		}
	}

	SetActiveAppearance() {
		this.isActive = true;
		if (this.params.activeColor) {
			this.planeMesh.material.color = new THREE.Color(
				this.params.activeColor
			).convertGammaToLinear(2.2);

			this.planePinMesh.material.color = new THREE.Color(
				this.params.activeColor
			).convertGammaToLinear(2.2);
		}

		if (this.params.activeTextColor)
			this.textMesh.material.color = new THREE.Color(
				this.params.activeTextColor
			).convertGammaToLinear(2.2);
	}

	ResetMarkerAppearance() {
		this.isActive = false;

		this.planeMesh.material.color = new THREE.Color(this.params.color).convertGammaToLinear(
			2.2
		);

		this.planePinMesh.material.color = new THREE.Color(this.params.color).convertGammaToLinear(
			2.2
		);

		this.textMesh.material.color = new THREE.Color(this.params.textColor).convertGammaToLinear(
			2.2
		);
	}

	static ResetAllMarkers() {
		markersThisType.map((m) => m.ResetMarkerAppearance());
	}
}

export default HighlightMarker;
