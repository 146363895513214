import React, { Component } from "react";
import Instruction from "./Instruction";
import U from "../utils";
import { G } from "../globals";

class Instructions extends Component {
	constructor() {
		super();

		document.addEventListener("camDone", (e) => {
			this.forceUpdate();
			setTimeout(() => {
				this.IntroAnim();
			}, 100);
		});

		document.addEventListener("stateWillChange", () => {
			this.OutroAnim();
		});
	}

	IntroAnim() {
		const instructions = [...this.instructions.children, this.resetButton].reverse();
		instructions.map((inst, i) => {
			setTimeout(() => {
				inst.classList.remove("instruction-hide");
			}, 200 * i);
		});
	}

	OutroAnim() {
		const instructions = [this.resetButton, ...this.instructions.children];
		instructions.map((inst) => inst.classList.add("instruction-hide"));
	}

	ShowInstructions() {
		let result = { orbit: false, pan: false, zoom: false };
		if (
			this.props.currentState !== undefined &&
			this.props.currentState &&
			this.props.currentState.camData !== undefined &&
			this.props.currentState.camData.controls !== undefined
		) {
			this.props.currentState.camData.controls.map((c) => {
				if (c === "all") result = { orbit: true, pan: true, zoom: true };
				else if (c === "orbit" || c === "rotate") result.orbit = true;
				else if (c === "pan") result.pan = true;
				else if (c === "zoom") result.zoom = true;
				return null;
			});
		}
		return result;
	}

	GetInstruction(type) {
		const button = G.cam && G.cam.controls.controlPriority.PRIMARY === type ? "LEFT" : "RIGHT";
		switch (type) {
			case "orbit":
			case "rotate":
				return U.GetResponsiveMode().isTouch
					? {
							icon: "touch-orbit-icon",
							text: "Use two fingers to rotate camera",
					  }
					: {
							icon: button === "RIGHT" ? "inst-right-click" : "inst-left-click",
							text: `${
								button === "RIGHT" ? "Right" : "Left"
							} click and drag to rotate camera`,
					  };
			case "pan":
				return U.GetResponsiveMode().isTouch
					? {
							icon: "touch-pan-icon",
							text: "Touch and drag to look around",
					  }
					: {
							icon: button === "RIGHT" ? "inst-right-click" : "inst-left-click",
							text: `${
								button === "RIGHT" ? "Right" : "Left"
							} click and drag to pan around the area`,
					  };
			case "zoom":
				return U.GetResponsiveMode().isTouch
					? {
							icon: "touch-zoom-icon",
							text: "Pinch to zoom in and out",
					  }
					: {
							icon: "inst-middle-scroll",
							text: "Scroll to zoom in and out",
					  };
			default:
				break;
		}
	}

	render() {
		const s = this.ShowInstructions();
		const showReset = s.orbit || s.pan || s.zoom;
		return (
			<div
				className={`instructions-wrapper ${
					this.props.currentState.name === "CONTACT" ? "hidden" : ""
				}`}
			>
				<div className="instructions-container clickable">
					<ul
						ref={(ref) => (this.instructions = ref)}
						className="intructions-list w-list-unstyled"
					>
						<Instruction
							index={0}
							show={s.orbit}
							icon={this.GetInstruction("orbit").icon}
						>
							{this.GetInstruction("orbit").text}
						</Instruction>
						<Instruction index={1} show={s.pan} icon={this.GetInstruction("pan").icon}>
							{this.GetInstruction("pan").text}
						</Instruction>
						<Instruction
							index={2}
							show={s.zoom}
							icon={this.GetInstruction("zoom").icon}
						>
							{this.GetInstruction("zoom").text}
						</Instruction>
					</ul>
					<div
						className={`instruction-reset-button instruction-hide ${
							showReset ? "" : "hidden"
						}`}
						ref={(ref) => (this.resetButton = ref)}
						onClick={() => G.cam.Reset()}
					>
						Reset view
					</div>
				</div>
			</div>
		);
	}
}

export default Instructions;
