import React, { Component } from "react";
import anime from "animejs";
import Check from "./Check";
import { G } from "../globals";
import U from "../utils";
import OverviewGalleryThumb from "./OverviewGalleryThumb";
import { imageData } from "../data/imageData";

class Overview extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
		this.IntroAnim = this.IntroAnim.bind(this);
		this.ScrollFade = this.ScrollFade.bind(this);
	}

	componentDidMount() {
		document.addEventListener("stateWillChange", this.OutroAnim);
		document.addEventListener("camDone", this.IntroAnim);
		document.addEventListener("scroll", this.ScrollFade);
	}

	componentWillUnmount() {
		document.removeEventListener("stateWillChange", this.OutroAnim);
		document.removeEventListener("camDone", this.IntroAnim);
		document.removeEventListener("scroll", this.ScrollFade);
	}

	OutroAnim(e) {
		if (e.detail.newState.name === "BUILDING") return;

		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
			opacity: 0,
			easing: "easeInOutQuad",
			duration: 400,
		});
	}

	IntroAnim() {
		const timeline = new anime.timeline();
		timeline.add({
			targets: [...this.slideDiv.querySelectorAll(".auto-fade")],
			opacity: U.GetResponsiveMode() === "desktop" ? 1 : 0.9,
			easing: "easeInOutQuad",
			duration: 800,
		});
	}

	ScrollFade(e) {
		if (!this.scrollFadeDiv) return;
		if (window.scrollY > 50) this.scrollFadeDiv.classList.add("scrolled-fade");
		else this.scrollFadeDiv.classList.remove("scrolled-fade");
	}

	render() {
		return (
			<div ref={(ref) => (this.slideDiv = ref)} className="slide">
				<div ref={(ref) => (this.content = ref)} className="content auto-fade">
					<div className="content-side">
						<div className="section-graphic-text the-detail" />
						<div className="section-graphic detail-graphic" />
					</div>
					<div className="content-main">
						<div className="section-header-wrapper">
							<div className="section-header fulfillment-at-work" />
							<div className="copy clickable">
								Union Stockley Park offers modern office space with all of the
								latest amenities, in an award-winning location designed to offer the
								best work-life balance and market-leading sustainability
								credentials.
							</div>
							
						</div>
						<div className="section-bottom-wrapper clickable">
						<OverviewGalleryThumb
								ShowGallery={this.props.ShowGallery}
								content={Object.values(imageData).map((i) => {
									return { img: i.img, caption: i.txt };
								})}
							/>
						</div>
					</div>
				</div>
				<div className="usp-logo-wrapper">
					<div className="usp-logo"></div>
				</div>
				<div className="content-fade auto-fade" />
				<div
					ref={(ref) => (this.scrollFadeDiv = ref)}
					className="content-scroll-fade"
				></div>
				<div className="content-top-fade auto-fade" />
				<div className="content-right-fade auto-fade" />
				<div className="content-bottom-fade auto-fade" />
			</div>
		);
	}
}

export default Overview;
