import React, { Component } from "react";

class SlideNav extends Component {
	constructor() {
		super();

		this.slideCheck = "hidden";
	}

	componentDidUpdate() {
		switch (this.props.currentState.name) {
			case "OVERVIEW":
			case "BUILDING":
			case "LOCATION":
			case "CONNECTIONS":
				// this.slideCheck = "";
				this.slideNavWrapper.classList.remove("hidden");
				break;
			default:
				// this.slideCheck = "hidden";
				this.slideNavWrapper.classList.add("hidden");
				break;
		}
	}

	render() {
		// const text = this.props.currentState.name === "INTRO" ? "Begin" : "Next";

		return (
			<div
				ref={(ref) => (this.slideNavWrapper = ref)}
				className={`slide-nav-wrapper clickable ${this.slideCheck} ${
					this.props.currentState.name === "INTRO" ? "hidden" : ""
				}`}
				onClick={() => this.props.NextState()}
			>
				<div className={`slide-nav-text`}>
					{this.props.GetNextState() && this.props.GetNextState().labelName
						? this.props.GetNextState().labelName
						: ""}
				</div>
			</div>
		);
	}
}

export default SlideNav;
