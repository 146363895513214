import React, { Component } from "react";
import anime from "animejs";
import { imageData } from "../data/imageData";

class Burger extends Component {
	constructor() {
		super();

		this.inTransition = false;

		this.state = {
			isOpen: false,
		};
	}

	componentDidUpdate() {
		if (this.state.isOpen) this.OpenBurgerAnim();
		else this.CloseBurgerAnim();
	}

	ToggleBurger(force = null) {
		this.setState({ isOpen: force ? force : this.state.isOpen ? false : true });
	}

	GoToStateHandler(dest) {
		this.props.CloseAllOverlayPages();
		this.ToggleBurger(false);
		this.props.GoToState(dest);
	}

	GoToPageHandler(func, options = true) {
		this.props.CloseAllOverlayPages();
		this.ToggleBurger(false);
		func(options);
	}

	OpenBurgerAnim() {
		this.burgerBg.classList.add("burger-overlay-show");

		setTimeout(() => {
			const items = [...this.burgerList.querySelectorAll(".burger-item")];
			items.map((el, i) => {
				setTimeout(() => {
					el.classList.add("burger-item-show");
				}, 100 * i);
			});
		}, 300);
	}

	CloseBurgerAnim() {
		const items = [...this.burgerList.querySelectorAll(".burger-item")];
		items.map((el) => {
			el.classList.remove("burger-item-show");
		});

		setTimeout(() => {
			this.burgerBg.classList.remove("burger-overlay-show");
		}, 200);
	}

	render() {
		return (
			<div className="burger-button-wrapper ">
				<div ref={(ref) => (this.burgerBg = ref)} className="burger-overlay">
					<ul
						ref={(ref) => (this.burgerList = ref)}
						role="list"
						className="burger-list w-list-unstyled"
					>
						<li className="burger-item" onClick={() => this.GoToStateHandler("INTRO")}>
							Home
						</li>
						<li className="burger-item">
							<ul role="list" className="burger-group w-list-unstyled">
								<li
									className="burger-group-item"
									onClick={() => this.GoToStateHandler("OVERVIEW")}
								>
									Overview
								</li>
								<li
									className="burger-group-item sub-burger-item"
									onClick={() =>
										this.GoToPageHandler(
											this.props.ShowGallery,
											Object.values(imageData).map((i) => {
												return { img: i.img, caption: i.txt };
											})
										)
									}
								>
									View Gallery
								</li>
								
							</ul>
						</li>
						<li className="burger-item">
							<ul role="list" className="burger-group w-list-unstyled">
								<li
									className="burger-group-item"
									onClick={() => this.GoToStateHandler("LOCATION")}
								>
									Stockley Park
								</li>
								<li
									className="burger-group-item sub-burger-item"
									onClick={() =>
										this.GoToPageHandler(this.props.ShowOccupierPage)
									}
								>
									Stockley Park Occupiers
								</li>
								<li
									className="burger-group-item sub-burger-item"
									onClick={() => this.GoToStateHandler("ARENA")}
								>
									The Arena
								</li>
							</ul>
						</li>
						<li className="burger-item">
							<ul role="list" className="burger-group w-list-unstyled">
								<li
									className="burger-group-item"
									onClick={() => this.GoToStateHandler("CONNECTIONS")}
								>
									Connections
								</li>
								<li
									className="burger-group-item sub-burger-item"
									onClick={() => this.GoToStateHandler("CONNECTIONS_MAP")}
								>
									Connections map
								</li>
								<li
									className="burger-group-item sub-burger-item"
									onClick={() => this.GoToPageHandler(this.props.ShowEasitPage)}
								>
									easitStockleyPark
								</li>
							</ul>
						</li>
						<li className="burger-item">
							<ul role="list" className="burger-group w-list-unstyled">
								<li
									className="burger-group-item"
									onClick={() => this.GoToStateHandler("BUILDING")}
								>
									The Building
								</li>
								<li
									className="burger-group-item sub-burger-item"
									onClick={() => this.GoToStateHandler("GROUND")}
								>
									Explore floors
								</li>

								<li
									className="burger-group-item sub-burger-item"
									onClick={() => this.GoToStateHandler("VIRTUAL_GROUNDOFFICE")}
								>
									Virtual Fitted Tour
								</li>

								<li
									className="burger-group-item sub-burger-item"
									onClick={() =>
										this.GoToPageHandler(this.props.ShowSchedulePopup)
									}
								>
									Schedule of areas
								</li>
								<li
									className="burger-group-item sub-burger-item"
									onClick={() =>
										this.GoToPageHandler(this.props.ShowSpecification)
									}
								>
									Specification
								</li>
							</ul>
						</li>
						<li
							className="burger-item burger-item-last"
							onClick={() => this.GoToStateHandler("CONTACT")}
						>
							Contact
						</li>
					</ul>
				</div>
				<div
					className={`burger-icon clickable ${this.state.isOpen ? "close-icon" : ""}`}
					onClick={() => this.ToggleBurger()}
				></div>
			</div>
		);
	}
}

export default Burger;
