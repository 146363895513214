import * as THREE from 'three';
import { G } from '../globals';
import Loop from './Loop';
import U from '../utils';

class Entity {
	constructor() {
		this.onStateChange = this.onStateChange.bind(this);
		this.onConfigChange = this.onConfigChange.bind(this);
		this.onCamDone = this.onCamDone.bind(this);
		this.update = this.update.bind(this);

		this.debugLoop = null;
		this.objects = [];
		this.debugSelectedObject = null;

		document.addEventListener('stateChange', (e) => {
			this.onStateChange(e.detail.state);
		});

		document.addEventListener('configChange', (e) => {
			this.onConfigChange(e.detail);
		});

		document.addEventListener('camDone', (e) => {
			this.onCamDone(e.detail.state);
		});

		this.updateLoop = new Loop(this.update).start();

		this.uuid = null;
		this.isHover = false;
		this.isClicked = false;
		this.clickDown = false;
	}

	update() {
		//reserved function - THIS COULD CAUSE PERFORMANCE ISSUES!!
	}

	onStateChange(state) {
		//reserved function
	}

	onConfigChange(config) {
		//reserved function
	}

	onCamDone(state) {
		//reserved function
	}

	onMouseEnterHandler(e) {
		if (e.uuid !== this.uuid) return;
		if (this.isHover) return;
		this.isHover = true;
		this.onMouseEnter();
	}

	onMouseLeaveHandler(e) {
		// if (e.uuid !== this.uuid) return;
		if (!this.isHover) return;
		this.isHover = false;
		this.onMouseLeave();
	}

	onMouseOverHandler(e) {
		G.interactionInUse = true;
		this.onMouseOver(e);
	}

	onMouseDownHandler(e) {
		if (this.clickDown) return;
		this.clickDown = true;
		// console.log("MOUSE DOWN");
		this.onMouseDown(e);
	}

	onMouseUpHandler(e) {
		if (!this.clickDown) return;
		this.clickDown = false;
		// console.log("MOUSE UP");
		this.onMouseUp();
	}

	onClickHandler(e) {
		if (e.uuid !== this.uuid) return;
		if (this.isClicked) return;
		this.isClicked = true;
		this.onClick(e);
	}

	onClick(e) {
		//reserved function
	}

	onMouseDown(e) {
		//reserved function
	}

	onMouseUp(e) {
		//reserved function
	}

	onMouseEnter(e) {
		//reserved function
	}

	onMouseLeave(e) {
		//reserved function
	}

	onMouseOver(e) {
		//reserved function
	}

	enableInteraction(obj, _layerOrder = 0) {
		//check object is THREE
		if (obj.uuid) {
			// console.log('ADDING OBJ TO RAYCAST LAYER: ' + obj.uuid);
			obj.entity = this;
			//if object has children (if it's a group, add entity bindings to them as well)
			if (obj.children.length > 0) obj.children.map((c) => (c.entity = this));
			G.raycastLayer.push(obj);
			this.uuid = obj.uuid;
			obj.raycastLayerOrder = _layerOrder;
		} else console.error('ATTEMPTING TO ADD INVALID OBJECT TO RAYCAST LAYER');
	}

	disableInteraction() {
		// console.log('ATTEMPTING TO REMOVE OBJ FROM RAYCAST LAYER: ' + this.uuid);
		if (this.uuid) G.raycastLayer = G.raycastLayer.filter((l) => l.uuid !== this.uuid);
	}

	set canInteract(bool) {
		// this.canInteract = bool;
		if (bool) console.log('INTERACTABLE - WOULD ADD TO ARRAY', this);
		else if (!bool) console.log('NOT INTERACTABLE - WOULD REMOVE FROM ARRAY');
	}

	onDebugSelected(_name) {
		// console.log("I'M SELECTED!");
		const obj = this.objects.find((o) => o.name === _name);

		if (obj) {
			this.debugSelectedObject = obj;
			console.log('I EXIST!');
			this.debugLoop = new Loop(() => {
				G.debug.TrackSelectedVar(
					`Position`,
					`x: ${U.RoundNum(obj.position.x, 3)}  //  y: ${U.RoundNum(obj.position.y, 3)}  //  z: ${U.RoundNum(
						obj.position.z,
						3
					)}`
				);

				G.debug.TrackSelectedVar(
					`Rotation`,
					`x: ${U.RoundNum(obj.rotation.x, 3)}  //  y: ${U.RoundNum(obj.rotation.y, 3)}  //  z: ${U.RoundNum(
						obj.rotation.z,
						3
					)}`
				);
			}).start();
		}
	}

	onDebugDeselected() {
		console.log("I'M DESELECTED");
		G.debug.RemoveTrackVar(`${this.debugSelectedObject.name}Pos`);
		G.debug.RemoveTrackVar(`${this.debugSelectedObject.name}Rot`);
		this.debugSelectedObject = null;
		if (this.debugLoop) this.debugLoop.stop();
		this.debugLoop = null;
	}

	CreateDebugPoint(_pos = new THREE.Vector3(0, 0, 0), _name = 'untitledDebugPoint', _color = 'magenta') {
		const debugPointObj = new THREE.Mesh(
			new THREE.BoxGeometry(1, 1, 1),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color(_color).convertGammaToLinear(2.2)
			})
		);

		debugPointObj.position.set(_pos.x, _pos.y, _pos.z);
		this.Instantiate(debugPointObj, _name);

		return debugPointObj;
	}

	Instantiate = (object, name = null, isHiddenFromGraph = false) => {
		//attach our class to three object
		object.class = this;
		object.name = name || 'untitled';
		object.Destroy = this.Destroy;
		//add this object to a pool of objects on this class
		this.objects.push(object);

		console.log('Instantiating ' + name);
		return new Promise((resolve, reject) => {
			if (G.objects[name]) {
				console.error(`Object ${name} already exists, will not instantiate!`);
				return;
			}

			if (name === null) {
				console.error('Instantiating object with no name!');
				console.log(object);
				name = 'untitled';
			}
			G.objects[name] = object;

			try {
				G.scene.add(object);
				resolve(object);

				//ADD TO ENTITIES LIST
				if (!isHiddenFromGraph) G.debug.TrackEntity({ name, object });
			} catch (error) {
				reject(error);
				console.error('Trying to instantiate object - not a valid WHS object!');
				console.error(error);
			}
		});
	};

	Destroy(object) {
		//check for valid three object
		if (object.uuid) {
			object.geometry.dispose();
			object.material.dispose();
			G.scene.remove(object);
			delete G.objects[object.name];
		}
	}
}

export default Entity;
