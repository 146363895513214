import React, { Component } from "react";

class AmenityPopup extends Component {
	constructor() {
		super();

		this.OutroAnim = this.OutroAnim.bind(this);
	}
	componentDidMount() {
		setTimeout(() => {
			this.IntroAnim();
		}, 100);

		document.addEventListener("popupWillClose", this.OutroAnim);
	}

	componentWillUnmount() {
		document.removeEventListener("popupWillClose", this.OutroAnim);
	}

	IntroAnim() {
		this.popupDiv.classList.remove("fade");
		this.popupDiv.classList.remove("anim-slide-up");
	}

	OutroAnim() {
		this.popupDiv.classList.add("fade");
	}

	CloseHandler() {
		this.OutroAnim();

		setTimeout(() => {
			this.props.ShowPopup(false);
		}, 200);
	}

	RenderOccupierContent() {
		return (
			<div>
				<h3 className="popup-title">
					<strong>{this.props.data.text}</strong>
				</h3>
				<ul className="occupiers-list  w-list-unstyled">
					{this.props.data.logos.map((logo, i) => {
						return (
							<li key={i} className="occupiers-item occupier-popup-item">
								<div className={`occupier-logo-area ${logo.img}`}></div>
							</li>
						);
					})}
				</ul>
				<div className="copy">
					<span
						className="copy-internal-link"
						onClick={() => this.props.ShowOccupierPage(true)}
					>
						View all occupiers
					</span>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div
				ref={(ref) => (this.popupDiv = ref)}
				className="popup-overlay clickable anim-all-200 fade anim-slide-up"
			>
				<div
					style={{ backgroundImage: `url(./images/${this.props.data.img})` }}
					className={`popup-overlay-image ${this.props.data.img ? "" : "hidden"}`}
				>
					{this.props.data.popupType === "event" ? (
						<div className="popup-event-banner">
							<div
								className={`popup-event-icon ${
									this.props.data.type === "fitness"
										? "fitness-event-icon"
										: this.props.data.type === "star"
										? "star-event-icon"
										: this.props.data.type === "food"
										? "food-event-icon"
										: null
								}`}
							></div>
							<div className="popup-event-text">{this.props.data.when}</div>
						</div>
					) : null}
				</div>
				<div className="popup-overlay-container popup-amenity-bg">
					{this.props.data && this.props.data.content
						? this.props.data.content
						: this.props.data.logos
						? this.RenderOccupierContent()
						: null}
					<br />
					<p>
						<span className="copy-internal-link" onClick={() => this.CloseHandler()}>
							Close
						</span>
					</p>
				</div>
				<div className="popup-close" onClick={() => this.CloseHandler()}></div>
			</div>
		);
	}
}

export default AmenityPopup;
