import React, { Component } from "react";
import VideoPlayer from "./components/VideoPlayer";
import App from "./App";
import { CONFIG } from "./config";

class Entry extends Component {
	constructor() {
		super();

		this.ToggleVideo = this.ToggleVideo.bind(this);

		this.state = {
			showVideo: CONFIG.d_skipVideo ? false : true,
		};
	}

	ToggleVideo(bool = false) {
		this.setState({ showVideo: bool });
	}

	render() {
		return this.state.showVideo ? (
			<VideoPlayer isShown={this.state.showVideo} ToggleVideo={this.ToggleVideo} />
		) : (
			<App />
		);
	}
}

export default Entry;
