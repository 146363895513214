import React, { Component } from "react";

import { G } from "./globals";
import { CONFIG, SetConfig } from "./config";
import { GetStateArray } from "./data/states";

class Debug extends Component {
	constructor() {
		super();

		this.TrackVar = this.TrackVar.bind(this);
		this.TrackSelectedVar = this.TrackSelectedVar.bind(this);
		this.TrackEntity = this.TrackEntity.bind(this);
		this.RemoveTrackVar = this.RemoveTrackVar.bind(this);
		G.debug.TrackVar = this.TrackVar;
		G.debug.TrackEntity = this.TrackEntity;
		G.debug.RemoveTrackVar = this.RemoveTrackVar;
		G.debug.TrackSelectedVar = this.TrackSelectedVar;

		this.state = {
			variables: {},
			entities: {},
			selected: null,
			selectedVars: null,
			showUI: true,
		};
	}

	componentDidMount() {
		this.DebugEditSelected();
	}

	TrackVar(name, value) {
		if (!CONFIG.d_enableDebugVar) return;
		// console.log('DEBUG VAR');
		// if (!this.state.variables[name]) {
		// 	console.log('MAKING A NEW VARIABLE');
		// 	this.AddVarToList({ name, value });
		// }
		const variables = this.state.variables;
		variables[name] = { name, value };
		this.setState({ variables: variables });
	}

	TrackSelectedVar(name, value) {
		if (!CONFIG.d_enableDebugVar) return;

		const selectedVars = this.state.selectedVars ? this.state.selectedVars : {};
		selectedVars[name] = { name, value };
		this.setState({ selectedVars: selectedVars });
	}

	RemoveTrackVar(name) {
		const variables = this.state.variables;
		console.log(name);
		delete variables[name];
		this.setState({ variables: variables });
	}

	TrackEntity(_entity) {
		if (!CONFIG.d_enableDebugVar) return;
		if (this.state.entities[_entity.name]) {
			_entity.name = `${_entity.name} copy`;
		}

		this.AddEntityToList(_entity);
	}

	AddEntityToList(_entity) {
		const entities = this.state.entities;
		entities[_entity.name] = _entity;
		this.setState({ entities: entities });
	}

	ToggleConfig(param) {
		SetConfig(param, CONFIG[param] ? false : true);
		this.setState({ configChanged: true });
	}

	SelectEntity(entity) {
		console.log("SELECTING ENTITY");
		console.log(entity);
		if (this.state.selected && this.state.selected.name !== entity.name) this.ResetSelection();
		else {
			entity.object.class.onDebugSelected(entity.name);
			this.setState({ selected: entity });
		}
	}

	ResetSelection() {
		console.log("RESET SELECTION");
		if (this.state.selected) this.state.selected.object.class.onDebugDeselected();
		this.setState({ selected: null, selectedVars: null });
	}

	DebugEditSelected() {
		document.addEventListener("keydown", (e) => {
			if (this.state.selected && this.state.selected.object) {
				const selected = this.state.selected;
				//special case for the camera look target - the debug target always follows the internal look target coordinates, hence we need to target that instead of normal position
				const pos = this.state.selected.name === "debugTargetBox" ? G.cam.controls.target : selected.name === "debugLookTargetBox" ? G.cam.controls.lookTarget : selected.object.position;

				//modifier
				let modifier = 1;
				if (e.shiftKey) modifier = 10;
				else modifier = 1;

				if (e.keyCode === 37) {
					pos.x = pos.x + 0.5 * modifier; // default 0.5
				} else if (e.keyCode === 39) {
					pos.x = pos.x - 0.5 * modifier;
				} else if (e.keyCode === 38) {
					pos.z = pos.z + 0.5 * modifier;
				} else if (e.keyCode === 40) {
					pos.z = pos.z - 0.5 * modifier;
				} else if (e.keyCode === 34) {
					pos.y = pos.y - 0.1 * modifier;
				} else if (e.keyCode === 33) {
					pos.y = pos.y + 0.1 * modifier;
				} else if (e.keyCode === 190) {
					selected.object.rotation.y = selected.object.rotation.y + 0.01 * modifier;
				} else if (e.keyCode === 188) {
					selected.object.rotation.y = selected.object.rotation.y - 0.01 * modifier;
				}
			} else if (e.keyCode === 32) {
				//hide UI
				this.setState({ showUI: this.state.showUI ? false : true });
			}
		});
	}

	render() {
		const variables = Object.values(this.state.variables);
		const entities = Object.values(this.state.entities);
		const selectedVars = this.state.selectedVars ? Object.values(this.state.selectedVars) : [];
		return (
			<div className={`debug clickable ${this.state.showUI ? "" : "hidden"}`}>
				{CONFIG.d_enableDebugUI ? (
					<div>
						<div className="reset-button" onClick={() => this.props.GoToState("DEBUG")} />
						<div className={`debug-wrap debug-floor-config ${this.props.currentState.tag === "floor" ? "" : "hidden"}`}>
							<div className="tracker-title">FLOOR SELECTOR</div>
							<div className="tracker-wrap config-items-wrap">
								<ul className="config-list w-list-unstyled">
									<li className={`config-list-item ${this.props.currentState.name === "SIXTH" ? "active" : ""}`} onClick={() => this.props.GoToState("SIXTH")}>
										SIXTH
									</li>
									<li className={`config-list-item ${this.props.currentState.name === "FIFTH" ? "active" : ""}`} onClick={() => this.props.GoToState("FIFTH")}>
										FIFTH
									</li>
									<li className={`config-list-item ${this.props.currentState.name === "FOURTH" ? "active" : ""}`} onClick={() => this.props.GoToState("FOURTH")}>
										FOURTH
									</li>
									<li className={`config-list-item ${this.props.currentState.name === "THIRD" ? "active" : ""}`} onClick={() => this.props.GoToState("THIRD")}>
										THIRD
									</li>
									<li className={`config-list-item ${this.props.currentState.name === "SECOND" ? "active" : ""}`} onClick={() => this.props.GoToState("SECOND")}>
										SECOND
									</li>
									<li className={`config-list-item ${this.props.currentState.name === "FIRST" ? "active" : ""}`} onClick={() => this.props.GoToState("FIRST")}>
										FIRST
									</li>
									<li className={`config-list-item ${this.props.currentState.name === "GROUND" ? "active" : ""}`} onClick={() => this.props.GoToState("GROUND")}>
										GROUND
									</li>
								</ul>
							</div>
						</div>
						<div className="debug-wrap debug-wrap-config">
							<div className="tracker-title">CONFIG</div>
							<div className="tracker-wrap config-items-wrap">
								<ul className="config-list w-list-unstyled">
									<li className={`config-list-item ${CONFIG.r_enableBuilding ? "active" : ""}`} onClick={() => this.ToggleConfig("r_enableBuilding")}>
										Show building
									</li>
									<li className={`config-list-item ${CONFIG.r_enableBuildings ? "active" : ""}`} onClick={() => this.ToggleConfig("r_enableBuildings")}>
										Show buildings
									</li>
									<li className={`config-list-item ${CONFIG.r_enableMapChunks ? "active" : ""}`} onClick={() => this.ToggleConfig("r_enableMapChunks")}>
										Show map chunks
									</li>
									<li className={`config-list-item ${CONFIG.r_enableMarkers ? "active" : ""}`} onClick={() => this.ToggleConfig("r_enableMarkers")}>
										Show markers
									</li>
									<li className={`config-list-item ${CONFIG.d_enableDebugVar ? "active" : ""}`} onClick={() => this.ToggleConfig("d_enableDebugVar")}>
										Track variables
									</li>
								</ul>
							</div>
						</div>
						<div className={`debug-wrap selected-vars-wrap ${this.state.selectedVars ? "" : "hidden"}`}>
							<p className="tracker-title selected-var-title">SELECTED</p>
							<ul className="w-list-unstyled">
								{this.state.selectedVars
									? selectedVars.map((v) => {
											return (
												<li className="selected-vars-item">
													<p className="entity-title">{v.name}</p>
													<p className="entity-value">{v.value}</p>
												</li>
											);
									  })
									: null}
							</ul>
						</div>

						<div className="debug-wrap ">
							<div className="tracker-wrap">
								<p className="tracker-title">VARIABLES</p>
								<ul className="tracker-list var-list w-list-unstyled">
									{variables.map((v, i) => {
										return (
											<li key={i} className="tracker-item variable-item">
												<p className="entity-title">{v.name}</p>
												<div className="entity-value">{v.value}</div>
											</li>
										);
									})}
								</ul>
							</div>
							<div className="tracker-wrap">
								<p className="tracker-title">ENTITIES</p>
								<ul className="tracker-list w-list-unstyled">
									{entities.map((e, i) => {
										return (
											<li key={i} className={`tracker-item entity-item ${this.state.selected && this.state.selected.name === e.name ? "active" : ""}`} onClick={() => this.SelectEntity(e)}>
												<p className="entity-title">{e.name}</p>
											</li>
										);
									})}
								</ul>
							</div>
						</div>
						<div className="state-nav-wrapper">
							<div className={`show-floor-button ${this.props.currentState.tag === "floor" ? "active" : ""}`} onClick={() => (this.props.currentState.tag === "floor" ? this.props.GoToState("DEBUG") : this.props.GoToState("SIXTH"))}>
								Show floor
							</div>
							<div className="state-label-wrapper">
								<ul className="state-selector-list w-list-unstyled">
									{GetStateArray().map((s, i) => {
										return (
											<li key={i} className="state-list-item" onClick={() => this.props.GoToState(s.name)}>
												{s.name}
											</li>
										);
									})}
								</ul>
								<div className="state-label">{this.props.currentState.name}</div>
							</div>
							<div className="state-button prev-button" onClick={() => this.props.PrevState()} />
							<div className="state-button next-button" onClick={() => this.props.NextState()} />
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default Debug;
