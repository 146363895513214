import U from "./utils";

export const CONFIG = {
  r_shadow: true,
  r_anisotropy: 16,
  r_pixelRatio:
    U.GetResponsiveMode().highDefMode >= 1 && window.devicePixelRatio
      ? window.devicePixelRatio
      : 1, //1 for standard definition (faster), 2 for double density (better graphics but worse peformance), window.devicePixelRatio to let the browser handle it automatically

  //DEBUG
  d_enableDebug: false,
  d_enableDebugUI: false,
  d_enableDebugVar: false,
  d_enableUI: true,
  d_skipVideo: true,
  d_disableViz: false,
  d_forceHires: false,
};

export function SetConfig(key, value) {
  console.log(`Setting ${key} to ${value}`);
  CONFIG[key] = value;
  FireEvent({ settingChanged: key, value: value });
}

function FireEvent(detail) {
  const eventDelegate = new CustomEvent("configChange", {
    bubbles: true,
    detail: detail,
  });
  document.dispatchEvent(eventDelegate);
}
