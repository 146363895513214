import * as THREE from "three";
import anime from "animejs";
import { G } from "../globals";
import { CONFIG } from "../config";
import Loop from "./Loop";
import U from "../utils";
import Entity from "./Entity";
import MapControls from "./MapControls";

class Camera extends Entity {
	constructor() {
		super();

		G.objects.camera = this;

		this.options = {
			enableDamping: true,
			dampingFactor: 0.25,
			panSpeed: 0.25,
			zoomSpeed: 1, //default 0.25
			rotateSpeed: 0.25,
			minDistance: 10, //10
			maxDistance: 7000, //default 300
			enableKeys: false,
			initFov: 40,
			cameraInitPos: { x: 72, y: 42, z: -25 },
			camInitTarget: { x: 0, y: 3, z: 0 },
			mouseCamLimit: { x: 0.01, y: 0.01 },
		};

		this.camera = new THREE.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			0.1,
			1000
		);

		G.inTransition = false;

		G.objects.camera = this;

		this.camera.setFocalLength(this.options.initFov);
		this.camera.Init = () => this.Init();

		this.camera.far = 7000;
		this.camera.near = 0.5;

		this.isFarView = false;

		//CAMERA POSITIION LIMIT FLAG
		this.isLimited = false;
		this.limitCoords = {
			min: { x: 0, z: 0 },
			max: { x: 0, z: 0 },
		};

		this.Init();
	}

	Init() {
		setTimeout(() => {
			this.controls = new MapControls(
				this.camera,
				document.querySelector(".render"),
				this.options.camInitTarget
			);
			this.controls.screenSpacePanning = false;
			this.controls.enableDamping = this.options.enableDamping;
			this.controls.dampingFactor = this.options.dampingFactor;
			this.controls.panSpeed = this.options.panSpeed;
			this.controls.zoomSpeed = this.options.zoomSpeed;
			this.controls.rotateSpeed = this.options.rotateSpeed;
			this.controls.minDistance = this.options.minDistance;
			this.controls.maxDistance = this.options.maxDistance;
			this.controls.enableKeys = this.options.enableKeys;
			this.controls.enableGyro = false;
			//aliases
			this.camera.getAzimuthalAngle = this.controls.getAzimuthalAngle;
			this.camera.getPolarAngle = this.controls.getPolarAngle;
			this.camera.direction = "n";

			this.controlsLoop = new Loop(() => {
				if (G.interactionInUse) this.controls.enabled = false;
				else this.controls.enabled = true;
				if (this.isLimited) this.LimitCamera();
				this.camera.zoomLevel = this.camera.position.distanceTo(this.controls.target);
				// if (this.camera.zoomLevel > 900 && !this.isFarView) {
				// 	this.camera.far = 7000;
				// 	this.isFarView = true;
				// 	this.camera.updateProjectionMatrix();
				// } else if (this.camera.zoomLevel < 900 && this.isFarView) {
				// 	this.camera.far = 1000;
				// 	this.isFarView = false;
				// 	this.camera.updateProjectionMatrix();
				// }
				G.debug.TrackVar("camera far clip", this.camera.far);
				this.controls.update();
				// this.newDirection = this.controls.getCompassDirection();
				// if (this.newDirection !== this.direction) {
				// 	this.direction = this.newDirection;
				// 	this.FireCameraAngleEvent();
				// }
				// this.direction = this.controls.getCompassDirection();
			}).start();

			if (CONFIG.d_enableDebug) {
				const debugTargetBox = new THREE.Mesh(
					new THREE.SphereGeometry(1),
					new THREE.MeshBasicMaterial({
						color: new THREE.Color("green"),
					})
				);
				// this.Instantiate(debugLookTargetBox, 'debugLookTargetBox');
				this.Instantiate(debugTargetBox, "debugTargetBox");

				const debugLookTgtLoop = new Loop(() => {
					debugTargetBox.position.x = this.controls.target.x;
					debugTargetBox.position.y = this.controls.target.y;
					debugTargetBox.position.z = this.controls.target.z;

					G.debug.TrackVar(
						"camPos",
						`x: ${U.RoundNum(this.camera.position.x, 3)}, y: ${U.RoundNum(
							this.camera.position.y,
							3
						)}, z: ${U.RoundNum(this.camera.position.z, 3)}`
					);

					G.debug.TrackVar("Zoom level", `${this.camera.zoomLevel}`);

					// G.debug.TrackVar('camera fov', `${this.camera.fov}`);

					G.debug.TrackVar(
						"camTargetPos",
						`x: ${U.RoundNum(this.controls.target.x, 3)}, y: ${U.RoundNum(
							this.controls.target.y,
							3
						)}, z: ${U.RoundNum(this.controls.target.z, 3)}`
					);
					G.debug.TrackVar(
						"Camera Rot",
						`az: ${this.controls.getAzimuthalAngle()} // po: ${this.controls.getPolarAngle()}`
					);

					G.debug.TrackVar("compass", this.controls.getCompassDirection());
				});
				debugLookTgtLoop.start();
			}
		}, 300);
	}

	onStateChange(newState) {
		this.controls.maxDistance = this.options.maxDistance;
		this.controls.minDistance =
			newState.options && newState.options.minDistance !== undefined
				? newState.options.minDistance
				: this.options.minDistance;
		if (newState.camData) {
			this.DisableMapControls();
			this.SetScene(newState.camData).then(() => {
				if (newState.camData.zoomLimit && newState.camData.zoomLimit.min)
					this.controls.minDistance = newState.camData.zoomLimit.min;
				if (newState.camData.zoomLimit && newState.camData.zoomLimit.max)
					this.controls.maxDistance = newState.camData.zoomLimit.max;
				this.SetMapControls(newState.camData.controls);
				this.FireEvent(newState);
			});
		}
	}

	onCamDone(state) {
		if (state.camData && state.camData.limit) this.controls.setLimitPos(state.camData.limit);
		else this.controls.setLimitPos(null);

		if (state.camData && state.camData.panSpeed)
			this.controls.setPanSpeed(state.camData.panSpeed);
		else this.controls.setPanSpeed(this.options.panSpeed);

		if (state.camData && state.camData.rotateSpeed)
			this.controls.setRotateSpeed(state.camData.rotateSpeed);
		else this.controls.setPanSpeed(this.options.rotateSpeed);
	}

	SetMapControls(params = []) {
		this.controls.enableZoom = false;
		this.controls.enablePan = false;
		this.controls.enableRotate = false;
		this.controls.enableRotateY = false;
		this.controls.enableGyro = false;

		//prioritise controls - which is bound to left mouse button, which is to right
		//map through params, get the order of the valid two actions - rotate and pan
		//send that to an array, then first entry goes to primary, second to secondary
		//primary = left click, secondary = right click
		//all or if undefined, do a default scheme
		const controls = params.filter((p) => p === "orbit" || p === "pan" || p === "rotate");
		if (controls.length > 0)
			this.controls.controlPriority = { PRIMARY: controls[0], SECONDARY: controls[1] };
		else this.controls.controlPriority = { PRIMARY: "pan", SECONDARY: "orbit" };

		params.map((p) => {
			switch (p) {
				case "all":
					this.controls.enableZoom = true;
					this.controls.enablePan = true;
					this.controls.enableRotate = true;
					this.controls.enableRotateY = true;
					// this.controls.enableGyro = true;
					break;
				case "zoom":
					this.controls.enableZoom = true;
					break;
				case "pan":
					this.controls.enablePan = true;
					break;
				case "gyro":
					// this.controls.setOrbitLookTarget(this.controls.getLookTargetPos());
					if (G.currentState.camData && G.currentState.camData.gyroLimit) {
						this.controls.gyroLimitX = G.currentState.camData.gyroLimit;
						this.controls.gyroLimitY = G.currentState.camData.gyroLimit;
					} else {
						this.controls.gyroLimitX = 0.01;
						this.controls.gyroLimitY = 0.01;
					}
					this.controls.UpdateGyroOrigin();
					this.controls.enableGyro = true;
					break;
				case "orbit":
				case "rotate":
					this.controls.enableRotate = true;
					break;
				case "rotateY":
					this.controls.enableRotateY = true;
					break;
				default:
					break;
			}
			return null;
		});

		this.EnableMapControls();
	}

	LimitCamera() {
		if (
			this.controls.target.x <= this.limitCoords.min.x ||
			this.controls.target.z <= this.limitCoords.min.z
		)
			this.controls.panSpeed = 0.01;
		else if (
			this.controls.target.x >= this.limitCoords.max.x ||
			this.controls.target.z >= this.limitCoords.max.z
		)
			this.controls.panSpeed = 0.01;
		else this.controls.panSpeed = 0.25;
	}

	EnableMapControls() {
		this.controlsLoop.start();
		this.controls.enabled = true;
	}

	DisableMapControls() {
		this.controlsLoop.stop();
		this.controls.enabled = false;
		this.controls.enableGyro = false;
	}

	Reset() {
		if (!G.currentState || !G.currentState.camData) return;
		this.SetScene(G.currentState.camData);
	}

	SetScene(_scene) {
		return new Promise((resolve, reject) => {
			if (G.inTransition) return;

			this.DisableMapControls();

			if (!_scene.target) {
				_scene.target = {
					pos: this.controls.target,
					duration: null,
					delay: null,
					easing: null,
				};
			}

			if (!_scene.lookTarget) _scene.lookTarget = _scene.target.pos;

			if (!_scene.camera) {
				_scene.camera = {
					pos: this.camera.position,
					duration: null,
					delay: null,
					easing: null,
				};
			}

			const scene = {
				target: {
					pos: {
						x:
							_scene.target.pos.x !== undefined
								? _scene.target.pos.x
								: this.controls.target.x,
						y:
							_scene.target.pos.y !== undefined
								? _scene.target.pos.y
								: this.controls.target.y,
						z:
							_scene.target.pos.z !== undefined
								? _scene.target.pos.z
								: this.controls.target.z,
					},
					duration: _scene.target.duration || 400,
					easing: _scene.target.easing || "easeInOutQuad",
					delay: _scene.target.delay || 0,
				},
				camera: {
					pos: {
						x:
							_scene.camera.pos.x !== undefined
								? _scene.camera.pos.x
								: this.camera.position.x,
						y:
							_scene.camera.pos.y !== undefined
								? _scene.camera.pos.y
								: this.camera.position.y,
						z:
							_scene.camera.pos.z !== undefined
								? _scene.camera.pos.z
								: this.camera.position.z,
					},
					duration: _scene.camera.duration || 400,
					easing: _scene.camera.easing || "easeInOutQuad",
					delay: _scene.camera.delay || 0,
					fov:
						U.GetResponsiveMode().orientation === "portrait" &&
						this.GetResponsiveOffsetValue(_scene, "fov") !== null
							? this.GetResponsiveOffsetValue(_scene, "fov")
							: _scene.camera.fov
							? _scene.camera.fov
							: this.camera.fov,
					filmOffset:
						U.GetResponsiveMode().orientation === "portrait" &&
						this.GetResponsiveOffsetValue(_scene, "filmOffset") !== null
							? this.GetResponsiveOffsetValue(_scene, "filmOffset")
							: _scene.camera.filmOffset
							? _scene.camera.filmOffset
							: 0,
					hOffset:
						U.GetResponsiveMode().orientation === "portrait" &&
						this.GetResponsiveOffsetValue(_scene, "hOffset") !== null
							? this.GetResponsiveOffsetValue(_scene, "hOffset")
							: _scene.camera.hOffset
							? _scene.camera.hOffset
							: 0,
				},
			};

			// console.log(scene);

			const timeline = anime.timeline();

			G.inTransition = true;

			let newFov = { val: this.camera.fov };
			let newFilmOffset = { val: this.camera.filmOffset };
			let hOffset = { val: this.camera.view ? this.camera.view.offsetY : 0 };

			timeline
				.add({
					targets: this.controls.target,
					x: scene.target.pos.x,
					y: scene.target.pos.y,
					z: scene.target.pos.z,
					duration: scene.target.duration,
					offset: 0,
					easing: scene.target.easing,
					delay: scene.target.delay,
				})
				.add(
					{
						targets: newFilmOffset,
						val: scene.camera.filmOffset,
						duration: scene.camera.duration,
						easing: scene.camera.easing,
						delay: scene.camera.delay,
						update: () => {
							this.camera.filmOffset = newFilmOffset.val;
						},
					},
					0
				)
				.add(
					{
						targets: hOffset,
						val: scene.camera.hOffset,
						duration: scene.camera.duration,
						easing: scene.camera.easing,
						delay: scene.camera.delay,
						update: () => {
							this.camera.setViewOffset(
								window.innerWidth,
								window.innerHeight,
								0,
								hOffset.val,
								window.innerWidth,
								window.innerHeight
							);
						},
					},
					0
				)
				.add(
					{
						targets: newFov,
						val: scene.camera.fov,
						duration: scene.camera.duration,
						easing: scene.camera.easing,
						delay: scene.camera.delay,
						begin: () => {
							this.camera.setFocalLength(this.camera.fov);
						},
						update: () => {
							this.camera.setFocalLength(newFov.val);
							this.camera.fov = newFov.val;
						},
					},
					0
				)
				.add(
					{
						targets: this.camera.position,
						x: scene.camera.pos.x,
						y: scene.camera.pos.y,
						z: scene.camera.pos.z,
						duration: scene.camera.duration,
						easing: scene.camera.easing,
						delay: scene.camera.delay,
						update: () => {
							this.camera.lookAt(this.controls.target);
							this.controls.update();
						},
						complete: () => {
							G.inTransition = false;
							this.gyroAngle = {
								x: this.controls.getAzimuthalAngle(),
								y: this.controls.getPolarAngle(),
							};
							this.EnableMapControls();
							resolve();
						},
					},
					0
				);
		});
	}

	GetResponsiveOffsetValue(scene, key) {
		if (!scene.camera.portraitMode) return null;

		const responsiveMode = U.GetResponsiveMode();
		const portraitModeValue = scene.camera.portraitMode[key];

		if (portraitModeValue === undefined) return null;
		if (typeof portraitModeValue === "object") {
			if (portraitModeValue[responsiveMode.mode])
				return portraitModeValue[responsiveMode.mode];
			if (responsiveMode.mode === "mobile" && portraitModeValue["tablet"])
				return portraitModeValue["tablet"];
			if (responsiveMode.mode === "mobile" && portraitModeValue["desktop"])
				return portraitModeValue["desktop"];
			if (responsiveMode.mode === "tablet" && portraitModeValue["desktop"])
				return portraitModeValue["desktop"];
		} else {
			return portraitModeValue;
		}
	}

	FireEvent(newState) {
		const eventDelegate = new CustomEvent("camDone", {
			bubbles: true,
			detail: { state: newState },
		});
		document.dispatchEvent(eventDelegate);
	}
}

export default Camera;
