import React, { Component } from "react";

class EasitStocklyPark extends Component {
	componentDidMount() {
		setTimeout(() => {
			this.IntroAnim();
		}, 100);
	}

	IntroAnim() {
		this.overlayBg.classList.remove("fade");
		setTimeout(() => {
			this.headerDiv.classList.remove("fade");
			setTimeout(() => {
				this.contentDiv.classList.remove("fade");
			}, 400);
		}, 400);
	}

	OutroAnim() {
		this.headerDiv.classList.add("fade");
		this.contentDiv.classList.add("fade");
		setTimeout(() => {
			this.overlayBg.classList.add("fade");
		}, 600);
	}

	CloseHandler() {
		this.OutroAnim();

		setTimeout(() => {
			this.props.ShowEasitPage(false);
		}, 1000);
	}

	render() {
		return (
			<div className="content-overlay clickable always-top easit-overlay">
				<div
					ref={(ref) => (this.overlayBg = ref)}
					className="content-overlay-bg community-bg anim-all-200 fade"
				></div>
				<div className="content-overlay-container">
					<div className="content-side"></div>
					<div
						ref={(ref) => (this.headerDiv = ref)}
						className="content-main anim-all-400 fade"
					>
						<div className="section-header-wrapper">
							<div className="section-header travel-better-header"></div>
						</div>
					</div>
					<div className="overlay-close-icon" onClick={() => this.CloseHandler()}></div>
					<div
						ref={(ref) => (this.contentDiv = ref)}
						className="content-page anim-all-400 fade"
					>
						<div className="easit-hero-img"></div>
						<div className="intro-para bottom-margin">
							easitSTOCKLEY-PARK is a sustainable transport initiative that opens more
							options to staff for their everyday and business journeys. Reducing car
							travel can save money, increase level of physical activity and help
							reduce congestion and carbon emissions. As an occupier at Union Stockley
							Park you are entitled to a free membership.
						</div>
						<div className="community-sub-title">Benefit to members</div>
						<ul className="easit-benefits-list w-list-unstyled">
							<li className="easit-benefits-item">
								<div className="easit-icon easit-bus-icon" />
								<div className="copy">
									Free easitSTOCKLEY PLUS+ Shuttle Bus between Hayes &amp;
									Harlington Station and Stockley Park
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-bus-icon" />
								<div className="copy">
									Free Lunchtime Shopper between Stockley Park and Uxbridge
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-discount-icon" />
								<div className="copy">
									Discount with Halfords on all in-store purchases{" "}
									<span className="copy-sub">(terms &amp; conditions apply)</span>
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-people-icon" />
								<div className="copy">
									Free membership to easitSHARE, enabling rideshare opportunities
									with your coworkers
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-bike-icon" />
								<div className="copy">
									Loan bikes - including electric and folding
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-moped-icon" />
								<div className="copy">
									25% discount on EcoMove Smart Electric Mopeds
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-electric-icon" />
								<div className="copy">
									Discounted electric vehicle charging units
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-car-icon" />
								<div className="copy">
									Savings and FREE driving credit with Enterprise Car Club
								</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-car-icon" />
								<div className="copy">Free Co-Wheels Car Club membership</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-leaf-icon" />
								<div className="copy">Carbon neutral car benefit scheme</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-bike-icon" />
								<div className="copy">Cycle-to-work scheme</div>
							</li>
							<li className="easit-benefits-item">
								<div className="easit-icon easit-parking-icon" />
								<div className="copy">Rotational staff parking schemes</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

export default EasitStocklyPark;
