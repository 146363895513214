import React from "react";
import ReactDOM from "react-dom";
import { CONFIG } from "./config";
import "./css/normalize.css";
import "./css/components.css";
import "./css/usp-0955d6.css";
import "./css/style.css";

import Entry from "./Entry";
import * as serviceWorker from "./serviceWorker";

//DETECT DEBUG MODE
if (window.location.hash === "#debug") {
  CONFIG.d_enableDebug = false;
  CONFIG.d_enableDebugUI = false;
  CONFIG.d_enableDebugVar = false;
  CONFIG.d_enableUI = false;
} else {
  CONFIG.d_enableDebug = false;
  CONFIG.d_enableDebugUI = false;
  CONFIG.d_enableDebugVar = false;
  CONFIG.d_enableUI = true;
}

ReactDOM.render(<Entry />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
