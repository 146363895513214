import React, { Component } from "react";

class Check extends Component {
	constructor(props) {
		super();

		this.state = {
			isEnabled: props.defaultState ? props.defaultState : false,
		};
	}

	componentDidMount() {
		setTimeout(() => {
			if (this.props.defaultState ? this.props.defaultState : this.state.isEnabled)
				this.onEnable();
			else if (!this.props.defaultState ? !this.props.defaultState : !this.state.isEnabled)
				this.onDisable();
		}, 10);
	}

	componentDidUpdate() {
		if (this.state.isEnabled) this.onEnable();
		else if (!this.state.isEnabled) this.onDisable();
	}

	onEnable() {
		if (this.props.onEnableFunc) this.props.onEnableFunc();
	}

	onDisable() {
		if (this.props.onDisableFunc) this.props.onDisableFunc();
	}

	render() {
		//props: text, enabledFunc: func, disabledFunc: func

		return (
			<div
				className="check-component clickable"
				onClick={() => this.setState({ isEnabled: this.state.isEnabled ? false : true })}
			>
				<div className={`check ${this.state.isEnabled ? "active" : ""}`} />
				<div className="check-text">{this.props.text}</div>
			</div>
		);
	}
}

export default Check;
