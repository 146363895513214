import React, { Component } from "react";
import * as THREE from "three";
import Camera from "./Camera";
import Ground from "./Ground";
import Building from "./Building";
import { G } from "../globals";
import { CONFIG } from "../config";
import Loop from "./Loop";
import U from "../utils";
import Lights from "./Lights";
import MarkerController from "./MarkerController";
import Interact from "./Interact";

class Viz extends Component {
	constructor() {
		super();
		this.ResetRender = this.ResetRender.bind(this);
	}

	componentDidMount() {
		G.scene = new THREE.Scene();
		this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.renderer.setClearColor(new THREE.Color("#ECF0EF"), 1);
		this.renderer.shadowMap.enabled = CONFIG.r_shadow;
		this.renderer.setPixelRatio(CONFIG.d_forceHires ? 2 : CONFIG.r_pixelRatio);
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.mainDiv.appendChild(this.renderer.domElement);

		// G.scene.fog = new THREE.FogExp2('white', 0.01);

		//quick dummy if debug is disabled
		if (!CONFIG.d_enableDebug) {
			G.debug.TrackVar = () => {};
			G.debug.TrackEntity = () => {};
		}

		G.forceDisableInteraction = false;

		G.cam = new Camera();

		G.mouse = new THREE.Vector2();

		new Lights();
		new Ground();
		new Building();
		new MarkerController();
		new Interact();

		//TWEAK RENDERER
		// this.renderer.gammaOutput = true;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.gammaFactor = 2;
		this.renderer.toneMapping = THREE.Uncharted2ToneMapping;
		this.renderer.toneMappingWhitePoint = 5;
		this.renderer.toneMappingExposure = 5;

		const mainLoop = new Loop(() => {
			this.renderer.render(G.scene, G.cam.camera);
		});
		mainLoop.start();

		//add renderer responsiveness
		window.addEventListener("resize", (e) => {
			this.ResetRender();
		});

		window.addEventListener("focus", () => {
			// console.log('FOCUS');
			this.ResetRender();
		});

		window.addEventListener("orientationchange", () => {
			this.ResetRender();
		});

		window.addEventListener("pageshow", () => {
			// console.log('PAGESHOW');
			this.ResetRender();
		});

		document.addEventListener("visibilitychange", () => {
			// console.log('VISIB CHANGE');
			this.ResetRender();
		});
	}

	ResetRender() {
		if (!G.cam || !this.renderer || !G.currentState) return;
		G.cam.camera.aspect = window.innerWidth / window.innerHeight;
		G.cam.camera.updateProjectionMatrix();
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		G.cam.camera.setFocalLength(
			G.currentState && G.currentState.camData && G.currentState.camData.camera.fov
				? G.currentState.camData.camera.fov
				: G.cam.camera.fov
		);
		this.renderer.setPixelRatio(CONFIG.d_forceHires ? 2 : CONFIG.r_pixelRatio);
	}

	render() {
		return (
			<div
				ref={(ref) => (this.mainDiv = ref)}
				className="render"
				style={{ backgroundColor: "#ffffff" }}
			/>
		);
	}
}

export default Viz;
